import Modal from "react-modal";
import InputMask from "react-input-mask";
import FormError from "../form.error";
import React from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {toast} from "react-toastify";

export default function AddBankAccountModal(props: any) {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
    } = useForm<any>();
    const onSubmitBankAccount: SubmitHandler<any> = (payload: any) => {
        const api = new ApiService();
        payload.iban = payload.iban.replaceAll(" ", "");
        api.start("post", env.api + "/member/api/create-member-account/", payload, true).then((res: any) => {
            if (res && res.status) {
                toast.success("Hesap başarıyla eklendi.");
                props.getListAccount();
                props.setShowAddBankAccountModal(false);
                reset({iban: "", name: ""});
            } else {
                toast.error(res.message);
            }
        });
    };
    return <>
        <Modal
            isOpen={props.showAddBankAccountModal}
            onRequestClose={() => {
                props.setShowAddBankAccountModal(false);
                // reset({iban: "", name: ""});
            }}
            ariaHideApp={false}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    width: '472px',
                    maxHeight: 'calc(100% - 40px)',
                    marginRight: '-50%',
                    maxWidth: ' calc( 100% - 40px )',
                    padding: '0px',
                    transform: 'translate(-50%, -50%)',
                    background: 'var(--bs-white)',
                    borderRadius: 'var(--bs-border-radius-xl)',
                    border: 0
                },
                overlay: {
                    zIndex: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(10px)',
                }
            }} contentLabel="">


            <div className="modal-header">
                <h3 className="text-lg mb-0 text-white text-bolder text-center w-100">Yeni Banka Hesabı Ekle</h3>
                <svg onClick={() => props.setShowAddBankAccountModal(false)} width="24" height="24" viewBox="0 0 24 24"
                     fill="white"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"></path>
                </svg>
            </div>
            {props.showAddBankAccountModal && <div className="modal-body py-4">
                <div className="info-box ">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M1.50171 8.5C1.50171 4.44992 4.78495 1.16667 8.83504 1.16667C12.8851 1.16667 16.1684 4.44992 16.1684 8.5C16.1684 12.5501 12.8851 15.8333 8.83504 15.8333C4.78495 15.8333 1.50171 12.5501 1.50171 8.5ZM8.11553 13.0425H9.57728V6.72436H8.11553V13.0425ZM9.46855 5.52839C9.64574 5.36732 9.73433 5.14987 9.73433 4.87604C9.73433 4.59416 9.64574 4.37269 9.46855 4.21161C9.29137 4.05054 9.08198 3.97 8.84037 3.97C8.5907 3.97 8.37728 4.05054 8.2001 4.21161C8.02292 4.37269 7.93433 4.59416 7.93433 4.87604C7.93433 5.14987 8.02292 5.36732 8.2001 5.52839C8.37728 5.68946 8.5907 5.77 8.84037 5.77C9.08198 5.77 9.29137 5.68946 9.46855 5.52839Z"
                              fill="#004742"></path>
                    </svg>
                    <span className="text-xxsmall ">Tanımlayacağınız banka hesabı kendinize ait olmalıdır.</span>
                </div>
                <form onSubmit={handleSubmit(onSubmitBankAccount)} autoComplete="off">
                    <div className="iban-input-wrapper">
                        <p className="text-small text-primary mb-2">IBAN</p>
                        {/*<input className="modal-iban-input" type="text" value="" name="iban"/>*/}
                        <InputMask
                            className={"modal-iban-input"}
                            mask="TR99 9999 9999 9999 9999 9999 99"
                            placeholder={"TRXX XXXX XXXX XXXX XXXX XXXX XX"}
                            {...register<any>("iban", {required: true})}
                        />
                        {errors.iban && <FormError error={"Lütfen geçerli bir IBAN bilgisi giriniz."}/>}
                    </div>
                    <div className="modal-input-wrapper">
                        <p className="text-small text-primary mb-2">Hesap Adı</p>
                        <input id="name"
                               className="modal-input"
                               {...register("name", {required: true})}
                               maxLength={150} type="text" name="name"/>
                        {errors.name && <FormError error={"Lütfen Hesap Adı giriniz."}/>}
                    </div>

                    <button className="button sm secondary filled mt-3 w-100">
                        <p className="text-small text-bold text-center">Kaydet</p>
                    </button>
                </form>
            </div>}

        </Modal>
    </>
}
