import {env} from "../constants/global.vars";
import {authSubject, auth, loginModalSubject} from "../store/auth.store";
import {Storage} from "./localstorege.service";
import {Member} from "../models/auth.model";
import {wssProvider} from "./websocket.service";
import {modalServiceSubject} from "./modal.service";
import {exceptionModal} from "./misc.functions";
import paths from "../paths";
import {ApiService} from "./http.service";
import React from "react";

export function restoreLogin() {
    let savedData: any = Storage.get("member");
    if (savedData) {
        auth.member = new Member(savedData);
        auth.token = Storage.get("token");
        auth.authentication = true;
    }
}

export async function userLogin(payload: any) {
    let api = new ApiService();
    return await api
        .start("post", env.api + "/member/api/login/", payload, false)
        .then((res: any) => {
            if (res && res.status) {
                auth.member = new Member(res.data.member);
                auth.token = res.data.token;
                auth.authentication = true;
                Storage.set("token", res.data.token);
                Storage.set("member", res.data.member);
                authSubject.next({action: "login"});
                wssProvider.user(auth.token);
            }
            return res;
        })
        .catch((e: any) => {
            return {status: false, error: e, message: e, data: null};
        });
}


export function userWsLogout() {
    auth.member = null;
    auth.token = null;
    auth.authentication = false;

    Storage.del("token");
    Storage.del("member");

    authSubject.next({action: "logout"});
    loginModalSubject.next(false);
}

export function userLogout() {

    modalServiceSubject.next({
        title: 'Oturumu Kapat',
        content: `Oturumu kapatmak istediğinize emin misiniz?`,
        case: 'info',
        confirm: {
            sure: {
                label: 'Oturumu Kapat',
                action: () => {
                    auth.member = null;
                    auth.token = null;
                    auth.authentication = false;

                    Storage.del("token");
                    Storage.del("member");

                    authSubject.next({action: "logout"});
                    loginModalSubject.next(false);
                }
            },
            cancel: {
                label: 'Vazgeç',
            }
        }
    })


}

export function sendValidationCode(payload: any) {
    let api = new ApiService();
    api
        .start("post", env.api + "/member/api/validation-member-sms/", payload, false)
        .then((res: any) => {
            console.log(res);
        })
        .catch((e: any) => {
            exceptionModal(e);
        });
}

export async function userValidation(payload: any, resend: any = false) {
    if (resend) {
        sendValidationCode(payload);
    }

    let mobile = payload.mobile;
    let s: any = null;
    let sureButton: any = null;
    let resendButton: any = null;
    let validationErrorMessage: any = null;
    const senderTimer = (s: any = null) => {
        let resendTimer: any = document.getElementById("id_resendTimer");
        if (resendTimer) {
            let secondLeft: number = Number(
                Number(resendTimer.innerHTML.split(":")[0]) * 60 + Number(resendTimer.innerHTML.split(":")[1]) - 1
            );
            if (secondLeft < 0) {
                clearInterval(s);
                if (resendButton) {
                    resendButton.style.display = "block";
                }
            } else {
                resendTimer.innerHTML = Math.floor(secondLeft / 60) + ":" + ("00" + (secondLeft % 60)).slice(-2);
            }
        }
    };

    modalServiceSubject.next({
        title: "Doğrulama Kodu",
        innerHtml: true,
        greenBg: true,
        confirm: {
            sure: {
                label: "Doğrula",
                autoClose: false,
                async: true,
                action: async () => {
                    validationErrorMessage.style.display = "none";
                    const inputElements: any = document.querySelectorAll("input.code-input");
                    let validateCodeValue = [...inputElements].map((inp: any) => inp.value).join("");
                    let api = new ApiService();
                    return await api
                        .start(
                            "post",
                            env.api + "/member/api/validation-sms/",
                            {
                                mobile: mobile,
                                type: "register_validate",
                                code: validateCodeValue,
                            },
                            false
                        )
                        .then((res: any) => {
                            if (res && res.status) {
                                auth.userValidation = true;
                                try {
                                    auth.member.mobile_validation = true;
                                } catch (e: any) {
                                    console.log(e);
                                }
                                return true;
                            } else {
                                if (validationErrorMessage) {
                                    validationErrorMessage.style.display = "block";
                                    validationErrorMessage.innerHTML = res.message;
                                }
                            }
                        })
                        .catch((e: any) => {
                            if (validationErrorMessage) {
                                validationErrorMessage.style.display = "block";
                                validationErrorMessage.innerHTML = e;
                            }
                        });
                },
            },
        },
        onClose: () => {
            clearInterval(s);
        },
        afterLoad: () => {
            s = setInterval(senderTimer, 1000);
            validationErrorMessage = document.getElementById("id_validationErrorMessage");
            if (validationErrorMessage) {
                validationErrorMessage.style.display = "none";
            }
            sureButton = document.getElementById("id_btnModelServiceSure");
            resendButton = document.getElementById("id_resendCode");


            if (sureButton) {
                sureButton.disabled = true;
            }
            if (resendButton) {
                resendButton.style.display = "none";
                resendButton.addEventListener("click", (e: any) => {
                    resendButton.style.display = "none";
                    let resendTimer: any = document.getElementById("id_resendTimer");
                    if (resendTimer) {
                        if (validationErrorMessage) {
                            validationErrorMessage.style.display = "none";
                        }

                        sendValidationCode(payload);
                        resendTimer.innerHTML = "3:00";
                        clearInterval(s);
                        s = setInterval(senderTimer, 1000);
                    }
                });
            }

            const inputElements: any = document.querySelectorAll("input.code-input");
            inputElements.forEach((ele: any, index: any) => {
                ele.addEventListener("keydown", (e: any) => {
                    // if the keycode is backspace & the current field is empty
                    // focus the input before the current. Then the event happens
                    // which will clear the "before" input box.
                    if (e.keyCode === 8 && e.target.value === "") inputElements[Math.max(0, index - 1)].focus();
                });
                ele.addEventListener("input", (e: any) => {
                    // take the first character of the input
                    // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
                    // but I'm willing to overlook insane security code practices.
                    const [first, ...rest] = e.target.value;
                    e.target.value = first ?? ""; // first will be undefined when backspace was entered, so set the input to ""
                    const lastInputBox = index === inputElements.length - 1;
                    const didInsertContent = first !== undefined;
                    if (didInsertContent && !lastInputBox) {
                        // continue to input the rest of the string
                        inputElements[index + 1].focus();
                        // inputElements[index+1].value = rest.join('')
                        inputElements[index + 1].dispatchEvent(new Event("input"));
                    }

                    let validateCodeValue = [...inputElements].map((inp: any) => inp.value).join("");
                    if (validateCodeValue.length === 6) {
                        sureButton.disabled = false;
                    } else {
                        sureButton.disabled = true;
                    }
                });
            });
        },
        content: `
     
        
        
             <div class="pt-4 mb-4 pb-1">
                <p class="text-md  text-center">
                    <span class="text-md text-primary text-bold">${mobile} </span>numaralı
                    telefona gelen 6 haneli <br/> doğrulama kodunu gir.</p>
            </div>
        </div>
        
        <div class="mb-3 d-flex w-100">
            <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
            <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
            <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
            <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
            <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
            <input type="tel" autocomplete="off" name='char' maxlength="1" class='code-input col-auto' required/>
            <div class="clearfix"></div>
        </div>
        <div id="id_validationErrorMessage" class="text-center text-danger alert alert-danger"></div>
        
         <div class="row mb-4">
                <div class=" col-12  d-flex justify-content-center align-items-center mb-1">
                    <p class="text-small">Kalan Süre</p>
                </div>
                <div class=" col-12  d-flex justify-content-center align-items-center">
                    <div id="emailHelp" class="form-text"><span id="id_resendTimer">3:00</span></div>
                </div>
            </div>
      <button style="bottom: 8px;display: none;" class="button tertiary filled" id="id_resendCode">Tekrar Gönder</button>
      <div>
      
     `,
    });
}

export async function sendEmailValidationLink(email: any) {
    let api = new ApiService();
    api
        .start(
            "post",
            env.api + "/member/api/validation-email/",
            {
                type: "register_send",
                email: email,
                return_address: `${window.location.protocol}//${window.location.host}${paths.verifyEmail.url}`,
            },
            true
        )
        .then((res: any) => {
            if (res && res.status) {
                modalServiceSubject.next({
                    title: '',
                    innerHtml: true,
                    width: '472px',
                    content: `   <div>
                                            <div class="mb-1 d-flex justify-content-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80"
                                                     viewBox="0 0 80 80" fill="none">
                                                    <path
                                                        d="M13.5 30L31.5438 38.8517C36.8777 41.4683 43.1223 41.4683 48.4562 38.8517L66.5 30"
                                                        stroke="#004742" strokeWidth="4" stroke-linecap="round"></path>
                                                    <rect x="6.5" y="14" width="67.2" height="53.6" rx="10.8"
                                                          stroke="#004742" strokeWidth="4"></rect>
                                                </svg>
                                            </div>
                                            <p class="text-md  text-bolder text-center mb-1">Posta kutunuzu kontrol
                                                edin</p>
                                            <p class="text-small text-center mb-1">Doğrulama linki mail adresinize
                                                gönderilmiştir.</p>

                                        </div>`,
                    confirm: {
                        sure: {
                            label: "Tamam",
                        },
                    },
                });
            } else {
                modalServiceSubject.next({
                    case: 'danger',
                    title: '',
                    width: '472px',
                    innerHtml: true,
                    content: `
                        <p class="text-xsmall text-center" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                <path
                                    d="M49.9993 30L29.9993 50M29.9993 30L49.9993 50M73.3327 40C73.3327 58.4095 58.4088 73.3334 39.9993 73.3334C21.5899 73.3334 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66669 39.9993 6.66669C58.4088 6.66669 73.3327 21.5905 73.3327 40Z"
                                    stroke="#E04C4C" strokeWidth="4" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </p>
                        <div style="height: 16px;"></div>
                        <p class="text-lg text-bolder text-center" >Hata Oluştu.</p>
                        <div style="height: 16px;"></div>
                        <p class="text-small text-center" >${res.message}</p>
                    `,
                    confirm: {
                        sure: {
                            label: "Tamam",
                        },
                    },
                });
            }
        });
}
