import "./hippodrome.css";
import {useEffect} from "react";
import {dateFormat, useForceUpdate} from "../../services/misc.functions";
import {availableHippodromes, courseColors, racesActionSubject} from "../../store/bulletin.store";
import {homeHippodrome, homeHippodromeSubject, path} from "../../store/misc.store";
import {Hippodrome} from "../../models/bulletin.models";
import paths from "../../paths";
import {useLocation, useNavigate} from "react-router-dom";
import {clearTjkBetSlipOptions, tjkBetSlipOptions} from "../../services/betslip.service";


export function HippodromeWidget() {

    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {

            if (!homeHippodrome?._id && !tjkBetSlipOptions.hippodrome) {
                changeSelectedHippodrome(availableHippodromes.find((el:any)=>el.wager_details.length > 0));
            }

            forceUpdate();
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });


    const changeSelectedHippodrome = (hippodrome: any) => {
        if (pathname === '/' || pathname.includes(paths.possibles.url)) {
            Object.assign(homeHippodrome, hippodrome);
            homeHippodromeSubject.next(hippodrome);
            tjkBetSlipOptions.hippodrome = hippodrome;
            forceUpdate();
        } else {
            clearTjkBetSlipOptions();
            navigate(`${paths.betting.url}/${(hippodrome.keyword + hippodrome._id).toLowerCase()}`);
        }
    }

    if (pathname === '/' && homeHippodrome?._id !== tjkBetSlipOptions?.hippodrome?._id) {
        changeSelectedHippodrome(availableHippodromes.find((el:any)=>el.wager_details.length > 0));
    }


    return (
        <div className={"hippodrome-wrapper"}>
            <div className="hippodrome-container">
                <div className={"container"}>
                    <div className="hippodrome-widgets">

                        {availableHippodromes.map((hippodrome: Hippodrome, keyHippodrome: number) => {


                            if (pathname.includes(paths.betting.url)) {
                                if (hippodrome.wager_details.length > 0) {
                                    return <div key={"hippodrome" + keyHippodrome} onClick={() => {
                                        changeSelectedHippodrome(hippodrome);
                                    }}
                                                className={`hippodrome-item  ${tjkBetSlipOptions?.hippodrome?._id === hippodrome._id ? 'selected-hippodrome' : ''}`}>
                                        <p className="text-small text-bolder text-start hipodrome-item-text mb-1">
                                            {new Date(hippodrome.date).getDate() !== new Date().getDate() &&
                                                <span className="text-xxsmall">{dateFormat(hippodrome.date).replace(`.${new Date().getFullYear()}`, '')} |</span>}
                                            {hippodrome.keyword}
                                        </p>
                                        <p className="text-xxsmall text-start mb-1">
                                            {hippodrome.nextRun ? <>
                                                <span
                                                    className="text-xxsmall">{hippodrome.nextRun.no}.</span> Koşu: {hippodrome.nextRun.time}
                                            </> : <>
                                                Koşular Tamamlanmıştır
                                            </>}
                                        </p>
                                        <p className="text-xxsmall text-start text-truncate">
                                        <span
                                            className="text-xxsmall">Hava:</span> {hippodrome.weather && hippodrome.weather?.temperature} °C
                                            | {hippodrome.weather?.forecast}</p>
                                        {tjkBetSlipOptions?.hippodrome?._id === hippodrome._id &&
                                            <div className="selected-indicator"></div>
                                        }
                                    </div>
                                }
                            } else {
                                if (new Date(hippodrome.date).getDate() === new Date().getDate()) {
                                    return <div key={"hippodrome" + keyHippodrome} onClick={() => {
                                        changeSelectedHippodrome(hippodrome);
                                    }}
                                                className={`hippodrome-item  ${tjkBetSlipOptions?.hippodrome?._id === hippodrome._id ? 'selected-hippodrome' : ''}`}>
                                        <p className="text-small text-bolder text-start mb-1">{hippodrome.keyword}</p>
                                        <p className="text-xxsmall text-start mb-1">
                                            {hippodrome.nextRun ? <> <span className="text-xxsmall">{hippodrome.nextRun.no}.</span> Koşu: {hippodrome.nextRun.time} </> : <>
                                                Koşular Tamamlanmıştır
                                            </>}
                                        </p>
                                        <p className="text-xxsmall text-start text-truncate">
                                            <span
                                                className="text-xxsmall">Hava:</span> {hippodrome.weather && hippodrome.weather?.temperature} °C
                                            | {hippodrome.weather?.forecast}</p>
                                        {tjkBetSlipOptions?.hippodrome?._id === hippodrome._id &&
                                            <div className="selected-indicator"></div>
                                        }
                                    </div>
                                }
                            }


                        })}
                    </div>
                </div>

            </div>
            {(path.root === '/' || path.root.includes('tjk-at-yarisi-muhtemeller')) &&
                <div className={"bg-white"}>
                    <div className={"container"}>
                        <div className={"hippodrome-info-container"}>
                            <div className={"hippodrome-info"}>
                                <div className={"hippodrome-info-item"}>
                                    {homeHippodrome.location}
                                </div>
                                <div className={"hippodrome-info-item text-truncate"}>
                                    Hava: {homeHippodrome.weather?.temperature} °C | {homeHippodrome.weather?.forecast}
                                </div>
                                <div className={"hippodrome-info-item"}>
                                    <div className="me-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                             viewBox="0 0 10 10"
                                             fill="none">
                                            <path
                                                d="M2.08334 0.416748C1.93908 0.416748 1.80509 0.491366 1.72912 0.614002C1.71007 0.645054 1.69114 0.676183 1.67239 0.707419C1.63711 0.766181 1.58703 0.85064 1.52707 0.954627C1.40735 1.16224 1.24725 1.44943 1.08665 1.76643C0.926642 2.08227 0.762808 2.43421 0.638048 2.77016C0.517304 3.09531 0.416672 3.4478 0.416672 3.75008C0.416672 4.67056 1.16286 5.41675 2.08334 5.41675C3.00381 5.41675 3.75001 4.67056 3.75001 3.75008C3.75001 3.4478 3.64937 3.09531 3.52863 2.77016C3.40387 2.43421 3.24004 2.08227 3.08003 1.76643C2.91943 1.44943 2.75933 1.16224 2.63961 0.954627C2.57964 0.85064 2.52956 0.766181 2.49429 0.707419C2.47553 0.676183 2.45661 0.645054 2.43756 0.614002C2.36159 0.491366 2.2276 0.416748 2.08334 0.416748Z"
                                                fill="white"></path>
                                            <path
                                                d="M3.75001 6.66675C3.75001 8.27758 5.05584 9.58342 6.66667 9.58342C8.2775 9.58342 9.58334 8.27758 9.58334 6.66675C9.58334 6.13979 9.40458 5.49267 9.16665 4.85197C8.9247 4.20045 8.60462 3.51201 8.28836 2.88776C7.97151 2.26234 7.65516 1.69482 7.41825 1.28398C7.2997 1.07838 7.20079 0.911581 7.13133 0.795884C7.0966 0.738026 7.06921 0.692919 7.0504 0.6621L7.02089 0.614002C6.94492 0.491366 6.81093 0.416748 6.66667 0.416748C6.52241 0.416748 6.38842 0.491366 6.31246 0.614002L6.28294 0.6621C6.26413 0.692919 6.23675 0.738026 6.20201 0.795884C6.13256 0.911581 6.03365 1.07838 5.91509 1.28398C5.67818 1.69482 5.36183 2.26234 5.04498 2.88776C4.72873 3.51201 4.40864 4.20045 4.16669 4.85197C3.92876 5.49267 3.75001 6.13979 3.75001 6.66675Z"
                                                fill="white"></path>
                                        </svg>
                                    </div>
                                    Nem: {homeHippodrome.weather?.humidity}
                                </div>
                                {homeHippodrome.nextRun && <div className={"hippodrome-info-item"}
                                                                style={{backgroundColor: courseColors[homeHippodrome.nextRun.course]}}>
                                    {homeHippodrome.nextRun.course} {homeHippodrome.weather.course_condition ? ":" : ""} {homeHippodrome.weather.course_condition}
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}


