import React, {useEffect, useState} from "react";
import {ApiService} from "../../../services/http.service";
import {agfUrlPrefix} from "../../../constants/global.vars";
import {Storage} from "../../../services/localstorege.service";
import {useForceUpdate} from "../../../services/misc.functions";
import {widgetStatus, widgetStatusSubject} from "../../../store/misc.store";
import {isMobile} from "../../../App";
import jokeyIcon from "../../../assets/img/jokey.png";


export function Agf(prop: any) {
    const forceUpdate = useForceUpdate();

    const [selectedTab, setSelectedTab] = useState<any>(0);
    const [agfData, setAgfData] = useState<any>(Storage.get("agfData"))
    const [hippodrome, setHippodrome] = useState<any>(prop.hippodrome);
    const [shownWager, setShownWager] = useState<any>(0);
    const [shownMobileRun, setShownMobileRun] = useState<any>(0);
    const [showDetail, setShowDetail] = useState(false);

    useEffect(() => {

        let widgetStatusSubject_subscription = widgetStatusSubject.subscribe((res: any) => {
            setShowDetail(widgetStatus.isOpen);
        })

        return () => {
            widgetStatusSubject_subscription.unsubscribe();
        }

    }, []);


    const getAgfData = () => {
        let api = new ApiService()
        api.start("get", agfUrlPrefix, null, false).then((res: any) => {
            if (res.status) {
                setAgfData(res.data);
            }
        })
    }

    useEffect(() => {
        setShownWager(0)
    }, [prop]);

    useEffect(() => {

        if (!agfData) {
            getAgfData();
        }
    }, []);


    if (!hippodrome || !agfData) {
        return <></>
    }


    let agfWagers: any = agfData.find((el: any) => el._date === hippodrome._date && el.keyword === hippodrome.keyword)


    if (!agfData || !agfWagers || (agfData.length > 0 && agfWagers && agfWagers.wagers.length === 0)) {
        return <>
            <div className="home-widget">
                <div className={`header ${showDetail ? 'isOpen' : ''}`}>
                    <div onClick={() => {
                        if (!isMobile) {
                            widgetStatus.isOpen = !widgetStatus.isOpen;
                            widgetStatusSubject.next(true);
                        } else {
                            setShowDetail((prevShowDetail) => !prevShowDetail);
                        }
                    }} className={"d-flex align-items-center"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM12.5303 6.53033C12.8232 6.23744 12.8232 5.76256 12.5303 5.46967C12.2374 5.17678 11.7626 5.17678 11.4697 5.46967L5.46967 11.4697C5.17678 11.7626 5.17678 12.2374 5.46967 12.5303C5.76256 12.8232 6.23744 12.8232 6.53033 12.5303L12.5303 6.53033ZM5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625C7.37132 5.625 7.875 6.12868 7.875 6.75C7.875 7.37132 7.37132 7.875 6.75 7.875C6.12868 7.875 5.625 7.37132 5.625 6.75ZM10.125 11.25C10.125 10.6287 10.6287 10.125 11.25 10.125C11.8713 10.125 12.375 10.6287 12.375 11.25C12.375 11.8713 11.8713 12.375 11.25 12.375C10.6287 12.375 10.125 11.8713 10.125 11.25Z"
                                  fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                        </svg>
                        <span className={"text-sm text-white ms-2"}>AGF</span>
                    </div>


                    <div onClick={() => {
                        if (!isMobile) {
                            widgetStatus.isOpen = !widgetStatus.isOpen;
                            widgetStatusSubject.next(true);
                        } else {
                            setShowDetail((prevShowDetail) => !prevShowDetail);
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                             className={` ${showDetail ? 'rotate-svg' : ''}`}>
                            <path d="M4 6L8 10L12 6" stroke={isMobile && !showDetail ? '#004742' : "white"}
                                  strokeWidth="3" strokeLinecap="round"
                                  strokeLinejoin="round"></path>
                        </svg>
                    </div>

                </div>
                {showDetail && <div className={"body"}>
                    <p className="p-4 text-center"><br/><br/>
                        Henüz muhtemel verileri açıklanmamış olabilir lütfen kısa bir süre sonra tekrar deneyiniz.</p>
                </div>}
            </div>

        </>
    }


    return <div className={"home-widget"}>
        <div className={`header ${showDetail ? 'isOpen' : ''}`} onClick={(e:any) => {

            if (e.target.id !== 'pill-tab') {
                if (!isMobile) {
                    widgetStatus.isOpen = !widgetStatus.isOpen;
                    widgetStatusSubject.next(true);
                } else {
                    setShowDetail((prevShowDetail) => !prevShowDetail);
                }
            }
        }}>
            <div className={"d-flex align-items-center"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM12.5303 6.53033C12.8232 6.23744 12.8232 5.76256 12.5303 5.46967C12.2374 5.17678 11.7626 5.17678 11.4697 5.46967L5.46967 11.4697C5.17678 11.7626 5.17678 12.2374 5.46967 12.5303C5.76256 12.8232 6.23744 12.8232 6.53033 12.5303L12.5303 6.53033ZM5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625C7.37132 5.625 7.875 6.12868 7.875 6.75C7.875 7.37132 7.37132 7.875 6.75 7.875C6.12868 7.875 5.625 7.37132 5.625 6.75ZM10.125 11.25C10.125 10.6287 10.6287 10.125 11.25 10.125C11.8713 10.125 12.375 10.6287 12.375 11.25C12.375 11.8713 11.8713 12.375 11.25 12.375C10.6287 12.375 10.125 11.8713 10.125 11.25Z"
                          fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                </svg>
                <span className={"text-sm text-white ms-2"}>AGF</span>
            </div>
            {showDetail && <div>
                {agfWagers.wagers.length === 1 ?
                    <div style={{marginLeft: "auto", marginRight: "10px"}}>
                        <div>
                    <span onClick={() => {
                        setSelectedTab(0);

                    }} className={`pill-tab all`}>{agfWagers.wagers[0].wager} Ganyan</span>

                        </div>
                    </div> :
                    <div style={{marginLeft: "auto", marginRight: "10px"}}>
                        <div>
                            {
                                agfWagers.wagers.map((wager: any, key: any) => {
                                    return <>
                                <span onClick={() => {
                                    setSelectedTab(key);
                                    setShownWager(key)
                                    setShownMobileRun((key * 5))
                                }}
                                      id={"pill-tab"}
                                      className={`pill-tab ${key === 0 ? 'left' : 'right'} ${selectedTab === key ? 'active' : ''}`}>{wager.wager} Ganyan</span>
                                    </>

                                })
                            }
                        </div>
                    </div>
                }
            </div>}

            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                     className={` ${showDetail ? 'rotate-svg' : ''}`}>
                    <path d="M4 6L8 10L12 6" stroke={isMobile && !showDetail ? '#004742' : "white"} strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>
                </svg>
            </div>

        </div>
        {showDetail && <div className={"body"}>
            <div className={"run-tabs"}>
                {agfWagers.wagers[shownWager].runs.map((run: any, keyRun: number) => {
                    return (
                        <div key={`runItem-${keyRun}`}
                             className={`run-tab-item ${shownMobileRun === keyRun + (shownWager * 5) ? 'active' : ''}`}
                             onClick={() => {
                                 setShownMobileRun(keyRun + (shownWager * 5))
                                 forceUpdate();
                             }}>
                            <p className={"text-small text-white text-bolder"}>{keyRun + 1}.</p>
                            <p className={"text-xsmall text-white"}>Ayak</p>

                        </div>)
                })}
            </div>

            <table className={"w-100 agf-page-table"}>
                <thead>
                <tr>
                    <th style={{width: '30px'}}>
                        <p className="text-xxsmall text-bold text-center">#</p>
                    </th>
                    <th>
                        <p className="text-xxsmall text-bold">At Adı</p>
                    </th>
                    <th>
                        <p className="text-xxsmall text-bold">Jokey</p>
                    </th>
                    <th>
                        <p className="text-xxsmall text-bold text-end">AGF</p>
                    </th>
                </tr>
                </thead>
                <tbody>
                {agfWagers.wagers[shownWager].runs.map((run: any, key: number) => {

                    if (shownMobileRun === (key + (shownWager * 5))) {
                        return run.horses.map((h: any, pkey: number) => {
                            return (<React.Fragment key={`agfpage-i-${pkey}`}>
                                <tr>
                                    <td className="agf-table-sort" rowSpan={h.stablemate != null ? h.stablemate.length +1 : 1}>
                                        <p className="text-small text-bold text-center">{pkey + 1}</p>
                                    </td>
                                    <td className="">
                                        <p className="text-xxsmall text-bold">
                                            <div className="d-flex align-items-center"><span>{h.no} - {h.name}</span>
                                            </div>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="text-xxsmall text-bold">
                                            <div className="d-flex align-items-center">
                                                <span
                                                    className="jockey-image-border">
                                                     <img
                                                         src={`https://medya-cdn.tjk.org/formaftp/${h.owner_code}.jpg`}
                                                         onError={({currentTarget}) => {
                                                             currentTarget.onerror = null;
                                                             currentTarget.src = jokeyIcon;
                                                         }} width={16} height={18} alt={h.jockey}
                                                         className="jockey-uniform float-start "/>
                                                </span>
                                                <span>{h.jockey}</span>
                                            </div>
                                        </p>

                                    </td>
                                    <td className="text-end"
                                        style={h.stablemate !== null ? {
                                            padding: "0px",
                                            overflow: "visible",
                                            position: "relative"
                                        } : {}}>
                                    {h.stablemate !== null && <>
                                            <div className="d-flex justify-content-center align-items-center"
                                                 style={{
                                                     writingMode: "vertical-lr",
                                                     textOrientation: "mixed",
                                                     backgroundColor: "rgb(232, 230, 208)",
                                                     padding: "5px",
                                                     position: "absolute",
                                                     height: `${(h.stablemate.length +1)*50}px`,
                                                     top: "0px",
                                                     width: '25px',
                                                     zIndex: "2"
                                                 }}>
                                                <p className="text-xxsmall text-bold">% {h.stablemate_agf_ratio}</p>
                                            </div>

                                            <div style={{width: "100%", height: "100%", gap: "0px"}}>
                                                <div
                                                    className=" d-flex gap-x-4 align-items-center justify-content-between"
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        padding: "6.6%",
                                                        marginLeft: "auto"
                                                    }}>
                                                    <div style={{marginLeft: "auto"}}>
                                                    <span
                                                        className="text-xxsmall text-bold  pl-4 text-truncate d-flex">% 47.2</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {h.stablemate === null &&
                                            <p className="text-xxsmall text-bold text-end">% {h.agf_ratio}</p>}
                                    </td>

                                </tr>
                                {h.stablemate != null && <>
                                    {h.stablemate.map((s: any, k: number) => {
                                        return <tr>
                                            <td className="">
                                                <p className="text-xxsmall text-bold">
                                                    <div className="d-flex align-items-center">
                                                        <span>  {s.no} - {s.name}</span>
                                                        {!s.run_status && <span className="mx-2 text-xsmall">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                        height="16" viewBox="0 0 16 16" fill="none"><g
                                                        clipPath="url(#clip0_4878_930)"><path
                                                        d="M5.21362 10.7066C3.97829 8.84342 4.85022 7.38859 4.85022 7.38859C4.64738 7.32734 4 7.76075 4 7.76075C4.36397 6.39575 6.36725 5.23667 6.36725 5.23667C6.14499 5.15325 5.5176 5.31834 5.5176 5.31834C7.15691 3.70659 9.16019 4.30625 9.16019 4.30625C9.22076 3.95392 10.2127 3.33325 10.2127 3.33325C10.111 3.43592 10.0698 3.99534 10.0698 3.99534C10.1721 3.80867 10.4967 3.62259 10.4967 3.62259C10.3144 4.05659 10.4967 4.98759 10.4967 4.98759C11.0223 5.526 11.6092 7.38859 11.6092 7.38859L11.832 7.71934C12.116 8.1335 12.0114 8.78509 11.7309 8.93967C10.6789 9.52067 10.5767 8.46425 10.5767 8.46425C9.70701 8.361 9.05906 7.36759 9.05906 7.36759C7.886 7.98767 8.53909 9.73884 9.04591 10.4108C9.69672 11.2724 9.21962 12.6666 9.21962 12.6666C9.21962 12.6666 6.43296 12.5488 5.21362 10.7066Z"
                                                        fill="#353535"></path><path
                                                        d="M3.28634 3.28659L12.713 12.7133M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                                                        stroke="#E04C4C" strokeWidth="1.33333" strokeLinecap="round"
                                                        strokeLinejoin="round"></path></g><defs><clipPath id="clip0_4878_930"><rect
                                                        width="16" height="16" fill="white"></rect></clipPath></defs></svg>
                                                </span>}
                                                    </div>
                                                </p>
                                            </td>
                                            <td>
                                                <p className="text-xxsmall text-bold">
                                                    <div className="d-flex align-items-center">
                                                <span
                                                    className="jockey-image-border">
                                                      <img
                                                          src={`https://medya-cdn.tjk.org/formaftp/${s.owner_code}.jpg`}
                                                          onError={({currentTarget}) => {
                                                              currentTarget.onerror = null;
                                                              currentTarget.src = jokeyIcon;
                                                          }} width={16} height={18} alt={s.jockey}
                                                          className="jockey-uniform float-start "/>
                                                </span>
                                                        <span>{s.jockey}</span>
                                                    </div>
                                                </p>

                                            </td>
                                            <td style={h.stablemate !== null ? {
                                                padding: "5px",
                                                overflow: "visible",
                                                position: "relative"
                                            } : {}}>
                                            <p className="text-xxsmall  text-bold text-end">% {s.agf_ratio}</p>

                                            </td>
                                        </tr>

                                    })}</>
                                }
                            </React.Fragment>)
                        })

                    }
                })}
                </tbody>
            </table>
        </div>}
    </div>
}
