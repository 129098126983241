import {
    BrowserRouter as Router, Link,
    Navigate,
    Route,
    Routes, useLocation, useSearchParams,
} from "react-router-dom";
import paths from "./paths";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";
import {Home} from "./pages/home/home";
import {Breadcrumbs, Header} from "./shared/header/header";
import {path} from "./store/misc.store";
import {Footer, MobileFooter} from "./shared/footer/footer";
import {Login} from "./pages/login/login";
import {Register} from "./pages/register/register";
import {Betting, BettingRoot} from "./pages/betting/betting";
import {MemberTickets} from "./pages/member/member.tickets";
import {Results} from "./pages/results/results";
import {Stream} from "./pages/stream/stream";
import {Program} from "./pages/program/program";
import {PossiblesPage} from "./pages/possibles/possibles";
import {Comments} from "./pages/comments/comments";
import Campaign from "./pages/campaigns/campaign";
import CampaignDetail from "./pages/campaigns/campaign-detail";
import {auth} from "./store/auth.store";

import {AgfPage} from "./pages/agf/agf";
import {Agreement} from "./pages/agreement/agreement";
import {Flatpage} from "./pages/flatpage/flatpage";
import {MemberWrapper} from "./pages/member/member.wrapper";
import Deposit from "./pages/deposit/deposit";
import Withdraw from "./pages/withdraw/withdraw";
import {BankAccounts} from "./pages/member/member.bank.accounts";
import MemberTransactions from "./pages/member/member.transactions";
import {MemberInfo} from "./pages/member/member.info";
import {MemberNotificationInfo} from "./pages/member/member.notification.info";
import {SupportFAQ} from "./pages/support/support.faq";
import {SupportFAQDetail} from "./pages/support/support.faq.detail";
import {isMobile} from "./App";
import notFoundImg from "./assets/img/not-found.svg"
import logo from "./assets/img/logo.png"
import {modalServiceSubject} from "./services/modal.service";
import {FlatPageWrapper} from "./pages/flatpage/flatpage.wrapper";
import {Support} from "./pages/support/support";
import {SupportTicketList} from "./pages/support/support.ticket.list";

export function PageNotFound() {
    return <>
        <div className="container ">
            <div className="not-found-wrapper">
                <div className="not-found-status-wrapper">
                    <img alt="Koşucu Resmi" loading="lazy" width="392"
                         height="221" decoding="async" data-nimg="1"
                         className="not-found-status-image"
                         src={notFoundImg}/>
                    <span className="text-xsmall text-bold text-center not-found-status-code">404</span>
                </div>
                <span className="text-xsmall text-center not-found-text">Aradığınız sayfa bulunamadı.</span>
                <a className="text-small text-bold text-center button secondary filled xs d-flex justify-content-center align-items-center not-found-back-home"
                   title="Ana Sayfaya Dön" href="/">Ana Sayfaya Dön</a>
            </div>
        </div>
    </>;

}

export function ComingSoon() {
    return <>
        <div className=" " style={{backgroundColor:'#004742',height:'100vh'}}>
            <div className="not-found-wrapper">
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img alt="Koşucu Resmi" loading="lazy"
                          decoding="async"
                         width={'50%'}
                         className=""
                         src={logo}/>
                    <span className="text-xsmall text-bold text-center not-found-status-code mb-5" style={{fontSize:'25px',color:'#FFFFFF'}}>Bakımdayız</span>
                </div>
                <span className="text-xsmall text-center not-found-text" style={{fontSize:'18px',color:'#FFFFFF'}}>Kısa bir süre içinde tekrar sizlerleyiz.</span>
            </div>
        </div>
    </>;

}

let metaTags: any = {};
Object.entries(paths).forEach(([key, value]: any) => {
    metaTags[value.url] = value;
});
const URLChangeHandler = () => {
    const {pathname} = useLocation();
    let currentPath = String(pathname);
    const [params] = useSearchParams();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);

        path.root = currentPath.split("/")[1] || "/";
        path.full = currentPath;
        try {
            var keyPath = currentPath.split("/")[1] || "/"
            keyPath = keyPath !== '/' ? '/' + keyPath : keyPath;
            path.breadcrumbTitle = metaTags[keyPath].breadcrumbTitle;
        } catch (e: any) {
            console.log(e);
        }


        try {
            let navItems: any = document.getElementsByClassName("navItem");
            for (let nav of navItems) {
                nav.classList.remove("active");
            }
            navItems = document.getElementsByClassName(path.root === "/" ? "anasayfa" : path.root);
            for (let nav of navItems) {
                nav.classList.add("active");
            }
        } catch (e) {
        }

        try {
            // @ts-ignore
            let metaTag: any = metaTags["/" + currentPath.split("/")[1]];

            document.title = metaTag.title;

            // @ts-ignore
            document.getElementsByTagName("meta").description.content = metaTag.description;
            // @ts-ignore
            document.getElementsByTagName("meta").keywords.content = metaTag.keywords;
        } catch (e) {
            console.log(e);
        }


    }, [currentPath]);

    return null;
};


const AppRouters = () => {

    return (
        <>

        <Router>
                <URLChangeHandler/>
                <div>
                    <Header/>
                    <Breadcrumbs/>
                    <Routes>
                        <Route path={paths.home.url} element={<Home/>}/>
                        <Route path={paths.verifyEmail.url} element={<Home/>}/>

                        <Route path={paths.betting.url} element={<BettingRoot/>}/>
                        <Route path={`${paths.betting.url}/:hippodrome`} element={<Betting/>}/>
                        <Route path={`${paths.betting.url}/:hippodrome/:subpage`} element={<Betting/>}/>

                        <Route path={paths.register.url} element={<Register/>}/>
                        <Route path={paths.login.url} element={<Login/>}/>
                        <Route path={`${paths.login.url}/:nextPage`} element={<Login/>}/>
                        <Route path={paths.results.url} element={<Results/>}/>
                        <Route path={`${paths.results.url}/:hippodrome/:run`} element={<Results/>}/>
                        <Route path={paths.possibles.url} element={<PossiblesPage/>}/>
                        <Route path={`${paths.possibles.url}/:hippodrome/:run`} element={<PossiblesPage/>}/>
                        <Route path={paths.agf.url} element={<AgfPage/>}/>
                        <Route path={`${paths.agf.url}/:hippodrome/:run`} element={<AgfPage/>}/>
                        {/*<Route path={paths.tipsterCoupons.url} element={<Coupons/>}/>*/}
                        {/*<Route path={`${paths.tipsterCoupons.url}/:tab`} element={<Coupons/>}/>*/}
                        <Route path={paths.tipsterComments.url} element={<Comments/>}/>
                        {/*<Route path={paths.announcement.url} element={<Announcements/>}/>*/}
                        <Route path={paths.campaigns.url} element={<Campaign/>}/>
                        <Route path={`${paths.campaigns.url}/:id`} element={<CampaignDetail/>}/>
                        <Route path={paths.program.url} element={<Program/>}/>
                        <Route path={paths.stream.url} element={<Stream/>}/>
                        <Route path={`${paths.stream.url}/:tab`} element={<Stream/>}/>
                        {/*<Route path={`${paths.reportedJokeys.url}`} element={<JockeysStats/>}/>*/}
                        {/*<Route path={`${paths.reportedJokeys.url}/:tab`} element={<JockeysStats/>}/>*/}
                        {/*<Route path={`${paths.horseStats.url}`} element={<HorseStats/>}/>*/}
                        {/*<Route path={`${paths.horseStats.url}/:tab`} element={<HorseStats/>}/>*/}


                        <Route path={paths.support.url} element={
                            <MemberWrapper>
                                <SupportTicketList/>
                            </MemberWrapper>
                        }>
                            <Route path={":id"} element={
                                <MemberWrapper>
                                    <SupportTicketList/>
                                </MemberWrapper>
                            }/>

                        </Route>



                        <Route path={paths.agreement.url} element={<FlatPageWrapper> <Agreement/></FlatPageWrapper>}>
                            <Route path={':slug'} element={
                                <FlatPageWrapper> <Agreement/></FlatPageWrapper>}/>
                        </Route>

                        <Route path={`${paths.flatpage.url}/:slug`} element={
                            <FlatPageWrapper>
                                <Flatpage/>
                            </FlatPageWrapper>
                        }/>


                        {/*<Route*/}
                        {/*    path={`${paths.notifications.url}`}*/}
                        {/*    element={*/}
                        {/*        <ProtectedRoute>*/}
                        {/*            <Notifications/>*/}
                        {/*        </ProtectedRoute>*/}
                        {/*    }*/}
                        {/*/>*/}
                        <Route
                            path={`${paths.memberInfo.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <MemberInfo/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${paths.supportFAQ.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <SupportFAQ/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${paths.supportFAQ.url}/:id`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <SupportFAQDetail/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${paths.notificationInfo.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <MemberNotificationInfo/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />

                        <Route path={`${paths.memberTickets.url}`}
                               element={<ProtectedRoute><MemberTickets/></ProtectedRoute>}/>
                        <Route
                            path={`${paths.memberSavedTickets.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberTickets/>
                                </ProtectedRoute>
                            }
                        />
                        {/*<Route*/}
                        {/*    path={`${paths.changePassword.url}`}*/}
                        {/*    element={*/}
                        {/*        <ProtectedRoute>*/}
                        {/*            <MemberWrapper>*/}
                        {/*                <ChangePassword/>*/}
                        {/*            </MemberWrapper>*/}
                        {/*        </ProtectedRoute>*/}
                        {/*    }*/}
                        {/*/>*/}
                        <Route
                            path={`${paths.memberBankAccounts.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <BankAccounts/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${paths.memberTransactions.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <MemberTransactions/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${paths.deposit.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <Deposit/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${paths.withdraw.url}`}
                            element={
                                <ProtectedRoute>
                                    <MemberWrapper>
                                        <Withdraw/>
                                    </MemberWrapper>
                                </ProtectedRoute>
                            }
                        />

                        {/*<Route*/}
                        {/*    path={`${paths.cookieSettings.url}`}*/}
                        {/*    element={*/}

                        {/*        <CookieSettings/>*/}


                        {/*    }*/}
                        {/*/>*/}

                        <Route path="*" element={<PageNotFound/>}/>
                    </Routes>
                </div>
                <Footer/>
                {isMobile && <MobileFooter/>}
                <ToastContainer/>
            </Router>
            <div id="backdrop-blur" className="backdrop-blur"></div>
        </>
    )
}

export function ProtectedRoute({children}: any) {
    if (!auth.member || !auth.token) {
        setTimeout(() => {
            modalServiceSubject.next({
                case: "danger",
                title: "Lütfen Giriş Yapınız",
                content: "Bu sayfayı görüntülemek için giriş yapmanız gerekmektedir.",

                confirm: {
                    sure: {
                        label: "Tamam",
                    },
                },
            });
        }, 100);

        return <Navigate to={paths.login.url}/>;
    }
    return children;
}

export default AppRouters;
