import DatePicker, {registerLocale} from "react-datepicker";
import React, {useEffect, useState} from "react";
import {tr} from 'date-fns/locale/tr';
import "react-datepicker/dist/react-datepicker.css";
import {resultsUrlPrefix} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {courseColors, DailyRaces, racesActionSubject} from "../../store/bulletin.store";
import iconClose from "../../assets/feather/x-circle.svg";
import Modal from "react-modal";
import {CurrencyFormat, ServerDate, useForceUpdate} from "../../services/misc.functions";
import _ from "lodash";
import {useParams} from "react-router-dom";
import {GetStablemateIcon} from "../betting/components/betting-datatable";
import jokeyIcon from "../../assets/img/jokey.png";
import {isMobile} from "../../App";


registerLocale('tr', tr);


export function Results() {

    const params = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const [resultsData, setResultsData] = useState<any>([]);

    const [hippodrome, setHippodrome] = useState<any>(null);
    const [shownRun, setShownRun] = useState<any>(null);
    const [showRunInfo, setShowRunInfo] = useState<any>(false);
    const [showTrainingVideo, setShowTrainingVideo] = useState<any>(false);
    const [mobileRunModal, setMobileRunModal] = useState<any>(false);
    const [type, setType] = useState<any>('');

    const forceUpdate = useForceUpdate();


    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {
                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });

    useEffect(() => {
        let api = new ApiService();
        api.start('get', `${resultsUrlPrefix}/${String(startDate.getDate()).padStart(2, '0')}${String(startDate.getMonth() + 1).padStart(2, '0')}${startDate.getFullYear()}/`, null, true).then(
            (result: any) => {

                console.log(result)

                if (result.status) {
                    var orderedResponseData =_.orderBy(result.data, ['opening'], ['asc']);

                    let hip = null;
                    if (params.hippodrome) {
                        hip = orderedResponseData.find((h: any) => h.keyword.toLowerCase() === params.hippodrome)
                        if (hip) {
                            setHippodrome(hip);
                        } else {
                            hip = orderedResponseData[0]
                            setHippodrome(orderedResponseData[0]);
                        }
                    } else {
                        hip = orderedResponseData[0]
                        setHippodrome(orderedResponseData[0]);
                    }

                    setResultsData(orderedResponseData);

                    if (orderedResponseData && orderedResponseData.length > 0 && orderedResponseData[0].runs) {
                        if (params.run && hip) {
                            let rn = hip.runs.find((r: any) => Number(r.no) === Number(params.run))
                            if (rn) {
                                setShownRun(rn)
                            } else {
                                setShownRun(hip.runs[0]);
                            }
                        } else {
                            setShownRun(orderedResponseData[0].runs[0]);
                        }

                    } else {
                        setShownRun(null);
                    }

                }

            }
        )
    }, [startDate]);


    const changeHippodrome = (hippodrome: any) => {
        setHippodrome(hippodrome);
        if (hippodrome.runs) {
            setShownRun(hippodrome.runs[0]);
        } else {
            setShownRun(null);
        }
    }

    if (hippodrome && hippodrome.runs.length === 0 && DailyRaces.length > 0) {
        let dailyRace = DailyRaces.find((d: any) => d.keyword === hippodrome.keyword)
        if (dailyRace) {
            hippodrome.runs = dailyRace.runs;
            hippodrome.continuous = true;
            setShownRun(hippodrome.runs[0]);
        }
    }


    let today = ServerDate()
    if (hippodrome && hippodrome._date === `${today.getDate()}${String(today.getMonth() + 1).padStart(2, "0")}${today.getFullYear()}` && DailyRaces.length > 0) {
        let dailyRace: any = DailyRaces.find((d: any) => d.keyword === hippodrome.keyword)
        if (dailyRace && hippodrome.runs.length !== dailyRace.runs.length) {
            let finishedRuns = hippodrome.runs.map((r: any) => Number(r.no))
            let continuousRuns = dailyRace.runs.filter((r: any) => {
                if (!finishedRuns.includes(Number(r.no))) {
                    r.continuous = true;
                    return r
                }
            })
            hippodrome.runs = _.orderBy(hippodrome.runs.concat(continuousRuns), [(e: any) => Number(e.no)])
        }
    }


    return (<>

        <div className="page-title-wrapper sticky-page-title">
            <div className="container p-0 pt-3 pb-4">
                <div className="">
                    <div>
                        <div className="date-picker-wrapper ">
                            <div className="date-picker-btn">
                                <div>
                                    <div className="container ">
                                        <div className="d-flex align-items-center">

                                            <DatePicker className={"result-date-input"} dateFormat={"dd/MM/yyyy"}
                                                        showIcon={true}
                                                        disabledKeyboardNavigation={true}
                                                        onKeyDown={(e)=>{
                                                            e.preventDefault();
                                                            return false;
                                                        }}
                                                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                                   height="18"
                                                                   viewBox="0 0 18 18" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V2.25122C13.1066 2.25367 13.4183 2.26104 13.6889 2.28315C14.1104 2.31759 14.498 2.39151 14.862 2.57698C15.4265 2.8646 15.8854 3.32354 16.173 3.88803C16.3585 4.25203 16.4324 4.63956 16.4669 5.06113C16.5 5.46702 16.5 5.96529 16.5 6.56901V12.931C16.5 13.5347 16.5 14.033 16.4669 14.4389C16.4324 14.8604 16.3585 15.248 16.173 15.612C15.8854 16.1765 15.4265 16.6354 14.862 16.923C14.498 17.1085 14.1104 17.1824 13.6889 17.2169C13.283 17.25 12.7847 17.25 12.181 17.25H5.81901C5.21529 17.25 4.71702 17.25 4.31113 17.2169C3.88956 17.1824 3.50203 17.1085 3.13803 16.923C2.57354 16.6354 2.1146 16.1765 1.82698 15.612C1.64151 15.248 1.56759 14.8604 1.53315 14.4389C1.49998 14.033 1.49999 13.5347 1.5 12.931V6.56903C1.49999 5.9653 1.49998 5.46702 1.53315 5.06113C1.56759 4.63956 1.64151 4.25203 1.82698 3.88803C2.1146 3.32354 2.57354 2.8646 3.13803 2.57698C3.50203 2.39151 3.88956 2.31759 4.31113 2.28315C4.58174 2.26104 4.8934 2.25367 5.25 2.25122V1.5C5.25 1.08579 5.58579 0.75 6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V2.25H11.25V1.5C11.25 1.08579 11.5858 0.75 12 0.75ZM5.25 3.75132V4.5C5.25 4.91421 5.58579 5.25 6 5.25C6.41421 5.25 6.75 4.91421 6.75 4.5V3.75H11.25V4.5C11.25 4.91421 11.5858 5.25 12 5.25C12.4142 5.25 12.75 4.91421 12.75 4.5V3.75132C13.0841 3.75367 13.3465 3.76017 13.5667 3.77816C13.8955 3.80503 14.0637 3.85372 14.181 3.91349C14.4632 4.0573 14.6927 4.28677 14.8365 4.56902C14.8963 4.68631 14.945 4.85447 14.9718 5.18328C14.9994 5.52085 15 5.95757 15 6.6V6.75H3V6.6C3 5.95757 3.00058 5.52085 3.02816 5.18328C3.05503 4.85447 3.10372 4.68631 3.16349 4.56902C3.3073 4.28677 3.53677 4.0573 3.81902 3.91349C3.93632 3.85372 4.10447 3.80503 4.43328 3.77816C4.65347 3.76017 4.91585 3.75367 5.25 3.75132Z"
                                                                  fill="#FFE700"></path>
                                                        </svg>}
                                                        selected={startDate} locale={'tr'}
                                                        minDate={new Date("29-10-2018")}
                                                        maxDate={new Date()}
                                                        onChange={(date: any) => setStartDate(date)}/>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="hipodrome-tab-container">
                <div className="container">
                    <div>
                        <div>
                            <div className="tab-items-wrapper">
                                {resultsData.map((data: any, key: any) => {
                                    return <div key={`available-hippodrome-${key}`}
                                                className={`result-tab ${data.keyword === hippodrome.keyword ? 'result-selected' : ''} `}
                                                onClick={() => {
                                                    changeHippodrome(data);
                                                }}>
                                        <div
                                            className={`tab-item ${data.keyword === hippodrome.keyword ? 'selected' : ''} `}>
                                            <p className={`text-small text-truncate text-bolder ${data.keyword === hippodrome.keyword ? 'text-primary' : 'text-white'}`}>{data.location.split(' ')[0]}</p>
                                            {data.keyword === hippodrome.keyword &&
                                                <div className="tab-active-indicator"></div>}
                                        </div>
                                    </div>
                                })}


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={`container ${isMobile ? 'p-0' : ''}`}>
            <div className="result-wrapper">
                <div>
                    <div className="betting-run-tabs result-mobile-run-tabs">
                        <div className="run-betting-tabs">

                            {hippodrome && hippodrome.runs && hippodrome.runs.map((run: any, keyRun: number) => {
                                let columnNo = keyRun + 1;
                                return <div key={`runItem-${keyRun}`}
                                            className={`run-betting-item run-result-item  flex-column justify-content-start align-items-start ${shownRun === run ? 'active' : ''}`}
                                            onClick={() => {
                                                setShownRun(run);
                                                forceUpdate();

                                            }}>
                                    <p className="text-small text-bolder lh-xxxsmall">{run.no}.</p>

                                    <p className="text-xxsmall lh-xxxsmall">Koşu</p>
                                </div>
                            })}

                        </div>
                    </div>
                    {shownRun && <div className="betting-run-info">
                        <div className={"row align-items-center"}>
                            <div className={"col-12 d-flex align-items-center p-0"}>
                                {!isMobile && <div className={"race-badge me-2"}>
                                    {shownRun.no}. Koşu
                                </div>}
                                <div className="d-flex align-items-center me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none"
                                         className="me-2">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M0.666626 8.00002C0.666626 3.94993 3.94987 0.666687 7.99996 0.666687C12.05 0.666687 15.3333 3.94993 15.3333 8.00002C15.3333 12.0501 12.05 15.3334 7.99996 15.3334C3.94987 15.3334 0.666626 12.0501 0.666626 8.00002ZM8.66663 4.00002C8.66663 3.63183 8.36815 3.33335 7.99996 3.33335C7.63177 3.33335 7.33329 3.63183 7.33329 4.00002V8.00002C7.33329 8.25254 7.47596 8.48338 7.70182 8.59631L10.3685 9.92964C10.6978 10.0943 11.0983 9.96082 11.2629 9.6315C11.4276 9.30218 11.2941 8.90173 10.9648 8.73707L8.66663 7.588V4.00002Z"
                                              fill="#353535"></path>
                                    </svg>
                                    <span className="text-xxsmall text-bold">{shownRun.time}</span>
                                </div>
                                <div className="d-flex align-items-center me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                         fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M7.99996 0.666687C3.94987 0.666687 0.666626 3.94993 0.666626 8.00002C0.666626 12.0501 3.94987 15.3334 7.99996 15.3334C12.05 15.3334 15.3333 12.0501 15.3333 8.00002C15.3333 3.94993 12.05 0.666687 7.99996 0.666687ZM7.99996 4.66669C7.63177 4.66669 7.33329 4.96516 7.33329 5.33335C7.33329 5.70154 7.63177 6.00002 7.99996 6.00002H8.00663C8.37482 6.00002 8.67329 5.70154 8.67329 5.33335C8.67329 4.96516 8.37482 4.66669 8.00663 4.66669H7.99996ZM8.66663 8.00002C8.66663 7.63183 8.36815 7.33335 7.99996 7.33335C7.63177 7.33335 7.33329 7.63183 7.33329 8.00002V10.6667C7.33329 11.0349 7.63177 11.3334 7.99996 11.3334C8.36815 11.3334 8.66663 11.0349 8.66663 10.6667V8.00002Z"
                                              fill="#353535"></path>
                                    </svg>
                                </div>
                                <div className={"d-flex"}>
                                    <p className="course text-xxsmall text-white text-bold"
                                       style={{backgroundColor: courseColors[shownRun.course]}}>{shownRun.distance}m
                                        - {shownRun.course}</p>
                                    <div className="d-flex align-items-center">
                                        <p className="text-xxsmall text-bold ms-1"> {shownRun.type_detail ? <>
                                            <span>{shownRun.type_detail}</span></> : null} {shownRun.short ? `/ ${shownRun.short}` : ''} | {shownRun.group} |
                                            İkramiye: <span
                                                className={"text-bolder"}>{shownRun.prize_1} {shownRun.currency}</span>
                                        </p>
                                    </div>
                                </div>
                                <span></span>
                            </div>
                        </div>
                    </div>}
                    <div className="page-result">
                        {isMobile ? <div className={"result-mobile-table"}>
                            <table>
                                <colgroup>
                                    <col/>
                                    <col className="result-horse-col"/>
                                    <col className="result-jockey-col"/>
                                    <col className="result-start-col"/>
                                    <col className="result-detail-col"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th>
                                        <p className="text-xxxsmall text-bold text-center lh-xxxs">#</p>
                                    </th>
                                    <th>
                                        <p className="text-xxxsmall text-bold lh-xxxs"
                                           style={{maxWidth: "100%"}}>Atın İsmi/Yaş/Kilo</p>
                                    </th>
                                    <th>
                                        <p className="text-xxxsmall text-bold lh-xxxs">Jokey</p>
                                    </th>
                                    <th colSpan={2}>
                                        <p className="text-xxxsmall text-bold lh-xxxs">Start / Derece</p>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {shownRun && shownRun.horses && shownRun.horses.map((horse: any, keyHorse: number) => {
                                    return (<React.Fragment key={`DataTable-horses-${keyHorse}`}>
                                        <tr>
                                            <td>
                                                <p className="text-small text-bold text-center"> {horse.result}
                                                    {horse.result === null && horse.degree === null ? <span
                                                        className="text-danger">-</span> : <></>}</p>
                                            </td>
                                            <td className="result-mobile-horse-box">
                                                <p className="text-xsmall text-bold w-100 text-truncate lh-xs">({horse.no}) {horse.name}</p>
                                                <p className="text-xxxsmall mt-1 mb-1 lh-xxxs">{horse.age}<span
                                                    className="ml-2">{horse.weight}kg</span>
                                                </p>
                                                <p className="text-xxxsmall text-error  w-100  lh-xxxs">{horse.jewelry}</p>
                                            </td>
                                            <td className="result-mobile-trainer-box">
                                                <div className="d-flex justify-content-center align-items-center">
                                                  <span className="jockey-image-border">
                                                 <img src={`https://medya-cdn.tjk.org/formaftp/${horse.owner_code}.jpg`}
                                                      onError={({currentTarget}) => {
                                                          currentTarget.onerror = null;
                                                          currentTarget.src = jokeyIcon;
                                                      }} width={16} height={18} alt={horse.jokey.short_name}
                                                      className="jockey-uniform float-start "/>
                                                </span>
                                                    <span className="text-xsmall text-bold text-truncate">M.KAYA</span>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center mt-1">
                                                    {horse.AGF1 ? <div style={{position: "relative", minWidth: "87px"}}>
                                                            <div
                                                                style={{
                                                                    height: "16px",
                                                                    width: `${horse.AGF1}%`,
                                                                    backgroundColor: "rgb(232, 230, 208)",
                                                                    borderRadius: "0px"
                                                                }}></div>
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "0px",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderRadius: "0px",
                                                                    border: "1px solid rgb(217, 217, 217)"
                                                                }}>
                                                                <p className="text-xxxsmall text-center lh-xxxsmall">{horse.AGF1} ({horse.AGF_rank1})</p>
                                                            </div>
                                                        </div> :
                                                        <p className="text-xxxsmall text-center lh-xxxsmall">-</p>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <p className="text-xxsmall text-bold  mb-1  lh-xxs">{horse.start}</p>
                                                        <p className="text-xxxsmall text-truncate  lh-xxxs">{horse.degree}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={`${horse.isOpen ? 'result-is-open' : ''}`} onClick={() => {
                                                horse.isOpen = horse.isOpen ? !horse.isOpen : true;
                                                forceUpdate();
                                            }}>
                                                <div className="d-flex justify-content-center">

                                                    {
                                                        horse.isOpen ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15"
                                                                 height="14" viewBox="0 0 15 14" fill="none">
                                                                <path d="M11 8.75L7.5 5.25L4 8.75" stroke="#004742"
                                                                      strokeWidth="2.33" strokeLinecap="round"
                                                                      strokeLinejoin="round"></path>
                                                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                          height="16"
                                                                          viewBox="0 0 16 16" fill="none">
                                                                <path d="M4 6L8 10L12 6" stroke="#004742" strokeWidth="3"
                                                                      strokeLinecap="round" strokeLinejoin="round"></path>
                                                            </svg>
                                                    }


                                                </div>
                                            </td>
                                        </tr>
                                        {horse.isOpen && <tr>
                                            <td colSpan={5} className="result-mobil-detail">
                                                <div className="result-mobil-detail-container">
                                                    <div className="result-mobil-detail-vertical-divider">
                                                        <div className="result-mobil-detail-item">
                                                            <p className="text-xxsmall">Ganyan</p>
                                                            <p className="text-xxsmall text-bold">{horse.ganyan}</p>
                                                        </div>
                                                        <div className="result-mobil-detail-item">
                                                            <div>
                                                                <p className="text-xxsmall mb-1">Fark</p>
                                                                <p className="text-xxsmall text-bold">{horse.difference || "-"}</p>
                                                            </div>


                                                        </div>
                                                        <div className="result-mobil-detail-item">
                                                            <p className="text-xxsmall ">Hp</p>
                                                            <p className="text-xxsmall text-bold">{horse.handicap}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{paddingLeft: "12px"}}>
                                                        <div className="result-mobil-detail-item">
                                                            <p className="text-xxsmall">St</p>
                                                            <p className="text-xxsmall text-bold">{horse.start}</p>
                                                        </div>
                                                        <div className="result-mobil-detail-item">
                                                            <p className="text-xxsmall">G. Çık.</p>
                                                            <p className="text-xxsmall text-bold  d-flex align-items-center">{horse.late_start || "-"}</p>
                                                        </div>
                                                        <div className="result-mobil-detail-item"></div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>}
                                    </React.Fragment>)
                                })}


                                </tbody>
                            </table>
                        </div> : <>
                            {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true ?
                                <table className={"w-100"}>
                                    <thead>
                                    <tr>
                                        <th style={{width: "30px"}}>
                                            <p className="text-xxxsmall text-white tex-tbold text-center">#</p>
                                        </th>
                                        <th style={{padding: "11px 12px"}}>
                                            <p className="text-xxxsmall text-white text-bold">Atın İsmi</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Yaş</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Kilo</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Jokey</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Start</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Derece</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Ganyan</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Fark</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">G. Çık.</p>
                                        </th>
                                        <th>
                                            <p className="text-xxxsmall text-white text-bold text-center">Hp</p>
                                        </th>
                                        <th style={{width: "80px"}}>
                                            <p className="text-xxxsmall text-white text-bold text-center">AGF</p>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {shownRun && shownRun.horses && shownRun.horses.map((horse: any, keyHorse: number) => {
                                        return (<React.Fragment key={`DataTable-horses-${keyHorse}`}>

                                            <tr>
                                                <td style={{backgroundColor: Number(horse.result) === 1 ? "rgb(255, 231, 0)" : "rgb(233, 217, 7)"}}>
                                                    <p className="text-small text-bold text-center"> {horse.result}
                                                        {horse.result === null && horse.degree === null ? <span
                                                            className="text-danger">-</span> : <></>}</p>
                                                </td>
                                                <td className="result-horse-name">
                                                    <div className={"d-flex"}>
                                                <span
                                                    className="text-xxxsmall lh-xxxsmall text-truncate text-bold mb-1">({horse.no}) {horse.name}</span>
                                                    </div>
                                                    <p className="text-xxxsmall text-danger w-100 lh-xxxsmall">{horse.jewelry}</p>
                                                    <GetStablemateIcon
                                                        horse={horse}/>
                                                </td>
                                                <td className="result-age">
                                                    <p className="text-xxxsmall text-center lh-xxxsmall">{horse.age}</p>
                                                </td>
                                                <td className="result-weight">
                                                    <p className="text-xxxsmall text-center">{horse.weight}</p>
                                                </td>
                                                <td className="result-jockey">
                                                    <div className="d-flex align-items-center">
                                                <span className="jockey-image-border">
                                                 <img src={`https://medya-cdn.tjk.org/formaftp/${horse.owner_code}.jpg`}
                                                      onError={({currentTarget}) => {
                                                          currentTarget.onerror = null;
                                                          currentTarget.src = jokeyIcon;
                                                      }} width={16} height={18} alt={horse.jokey.short_name}
                                                      className="jockey-uniform float-start "/>
                                                </span>
                                                        <span
                                                            className="text-xxxsmall lh-xxxsmall text-truncate text-bold w-100">
                                                    {horse.jockey != null ? horse.jockey.name : horse.jokey}
                                                </span>
                                                    </div>
                                                </td>
                                                <td className="result-start">
                                                    <p className="text-xxxsmall text-center lh-xxxsmall">{horse.start ? horse.start : '-'}</p>
                                                </td>
                                                <td className="result-degree">
                                                    <p className="text-xxxsmall text-center lh-xxxsmall">{horse.degree}</p>
                                                </td>
                                                <td className="result-ganyan">
                                                    <p className="text-xxxsmall text-center lh-xxxsmall">{horse.ganyan}</p>
                                                </td>
                                                <td className="result-diffrance">
                                                    <p className="text-xxxsmall text-center lh-xxxsmall">{horse.difference || "-"}</p>
                                                </td>
                                                <td className="result-late-start">
                                                    <p className="text-xxxsmall text-center lh-xxxsmall">{horse.late_start || "-"}</p>
                                                </td>
                                                <td className="result-hp">
                                                    <p className="text-xxxsmall text-center lh-xxxsmall">{horse.handicap}</p>
                                                </td>
                                                <td className="result-agf">
                                                    {horse.AGF1 ? <div style={{position: "relative", minWidth: "80px"}}>
                                                            <div
                                                                style={{
                                                                    height: "48px",
                                                                    width: `${horse.AGF1}%`,
                                                                    backgroundColor: "rgb(232, 230, 208)",
                                                                    borderRadius: "0px"
                                                                }}></div>
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "0px",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    borderRadius: "0px"
                                                                }}>
                                                                <p className="text-xxxsmall text-center lh-xxxsmall">{horse.AGF1} ({horse.AGF_rank1})</p>
                                                            </div>
                                                        </div> :
                                                        <p className="text-xxxsmall text-center lh-xxxsmall">-</p>}
                                                </td>
                                            </tr>
                                        </React.Fragment>)
                                    })}

                                    </tbody>
                                </table> :
                                <div className={"d-flex justify-content-center align-items-center mt-5"}>
                                    <p>Bu Yarış henüz sonuçlanmamış.</p>
                                </div>
                            }
                        </>}
                    </div>
                    {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true &&
                        <div className="result-info-wrapper">
                            <div>
                                <div className="result-info-card-header">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                         fill="none">
                                        <path
                                            d="M12.375 1.5C10.511 1.5 9 3.01104 9 4.875C9 6.73896 10.511 8.25 12.375 8.25C14.239 8.25 15.75 6.73896 15.75 4.875C15.75 3.01104 14.239 1.5 12.375 1.5Z"
                                            fill="#FFE700"></path>
                                        <path
                                            d="M9.02385 1.33444C8.08541 2.22299 7.5 3.48063 7.5 4.875C7.5 5.77282 7.74271 6.61395 8.16609 7.33637C7.83818 7.4426 7.48829 7.5 7.125 7.5C5.26104 7.5 3.75 5.98896 3.75 4.125C3.75 2.26104 5.26104 0.75 7.125 0.75C7.82912 0.75 8.48288 0.965624 9.02385 1.33444Z"
                                            fill="#FFE700"></path>
                                        <path
                                            d="M13.2817 11.92C13.2817 12.5135 12.8891 13.0154 12.348 13.1832L12.6959 13.167C13.0762 13.167 13.4459 13.0474 13.7497 12.8253L15.8714 11.2774C16.4009 10.8913 17.1421 10.9426 17.6102 11.3979C18.1301 11.9036 18.1301 12.7228 17.6102 13.2277L15.7897 14.9985C15.2997 15.4752 14.6745 15.8007 13.9948 15.9331L11.4217 16.4337C10.9115 16.5328 10.3855 16.5208 9.88054 16.3978L7.55339 15.8323C7.27501 15.764 6.98961 15.7298 6.70245 15.7298C6.39758 15.7298 6.24514 15.7298 6.12284 15.6861C5.911 15.6105 5.74429 15.4438 5.66866 15.232C5.625 15.1096 5.625 14.9572 5.625 14.6523V12.0805C5.625 11.8661 5.625 11.7589 5.65253 11.6602C5.67691 11.5727 5.71699 11.4903 5.7708 11.4171C5.83156 11.3345 5.91591 11.2684 6.08461 11.1361L6.9738 10.439C6.9738 10.439 8.44737 9.74999 9.26143 9.74999C9.59865 9.74999 9.93411 9.79103 10.2617 9.87224L12.2744 10.3726C12.8663 10.5193 13.2817 11.0485 13.2817 11.6554V11.92Z"
                                            fill="#FFE700"></path>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.60515 9.74999C2.61176 9.74999 2.61838 9.74999 2.625 9.74999C2.63163 9.74999 2.63825 9.74999 2.64486 9.74999C2.80239 9.74998 2.95586 9.74996 3.08642 9.75887C3.2292 9.76861 3.3985 9.79147 3.57403 9.86417C3.94157 10.0164 4.23358 10.3084 4.38582 10.676C4.45853 10.8515 4.48138 11.0208 4.49113 11.1636C4.50003 11.2941 4.50002 11.4476 4.5 11.6051C4.5 11.6117 4.5 11.6184 4.5 11.625L4.5 15.3949C4.50002 15.5524 4.50003 15.7059 4.49113 15.8364C4.48138 15.9792 4.45853 16.1485 4.38582 16.324C4.23358 16.6916 3.94157 16.9836 3.57403 17.1358C3.3985 17.2085 3.2292 17.2314 3.08642 17.2411C2.95586 17.25 2.80239 17.25 2.64486 17.25H2.60514C2.44761 17.25 2.29414 17.25 2.16358 17.2411C2.0208 17.2314 1.85151 17.2085 1.67598 17.1358C1.30844 16.9836 1.01642 16.6916 0.864183 16.324C0.791475 16.1485 0.76862 15.9792 0.758878 15.8364C0.74997 15.7058 0.749986 15.5524 0.750001 15.3948L0.750003 11.625C0.750003 11.6184 0.750002 11.6117 0.750001 11.6051C0.749986 11.4476 0.74997 11.2941 0.758879 11.1636C0.76862 11.0208 0.791476 10.8515 0.864183 10.676C1.01642 10.3084 1.30844 10.0164 1.67598 9.86417C1.85151 9.79146 2.0208 9.76861 2.16358 9.75887C2.29415 9.74996 2.44762 9.74998 2.60515 9.74999Z"
                                              fill="#FFE700"></path>
                                    </svg>
                                    <p className="text-small text-white text-bolder ms-2">Bu Koşunun İkramiyeleri</p>
                                </div>
                                <div className="result-prize-wrapper">
                                    {shownRun?.bets ?
                                        <table className="result-prize-table">
                                            <thead>
                                            <tr>
                                                <th>
                                                    <p className="text-xsmall text-bold">Oyun</p>
                                                </th>
                                                <th>
                                                    <p className="text-xsmall text-bold">Kazanan Atlar</p>
                                                </th>
                                                <th>
                                                    <p className="text-xsmall text-bold">İkramiye</p>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {shownRun.bets.map((bet: any, betIndex: number) => {
                                                return (<tr key={"bets-i-"+betIndex}>
                                                    <td>
                                                        <p className="text-xsmall">{bet.bet}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xsmall text-truncate">{bet.result}</p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xsmall text-bolder">{Number(bet.amount) === 0 ? 'Bilen çıkmamıştır devretmiştir.' : `${bet.amount}₺`}</p>
                                                    </td>
                                                </tr>)
                                            })}

                                            </tbody>
                                        </table> : <div className="info-not-found">
                                            <p className="text-xsmall ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65"
                                                     viewBox="0 0 64 65" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M32 3.16675C15.7996 3.16675 2.66663 16.2997 2.66663 32.5001C2.66663 48.7004 15.7996 61.8334 32 61.8334C48.2003 61.8334 61.3333 48.7004 61.3333 32.5001C61.3333 16.2997 48.2003 3.16675 32 3.16675Z"
                                                          fill="#E8E6D0"></path>
                                                    <path
                                                        d="M32.0007 19.1667C30.5279 19.1667 29.334 20.3607 29.334 21.8334C29.334 23.3062 30.5279 24.5001 32.0007 24.5001H32.0273C33.5001 24.5001 34.694 23.3062 34.694 21.8334C34.694 20.3607 33.5001 19.1667 32.0273 19.1667H32.0007Z"
                                                        fill="#004742"></path>
                                                    <path
                                                        d="M34.6673 32.5001C34.6673 31.0273 33.4734 29.8334 32.0007 29.8334C30.5279 29.8334 29.334 31.0273 29.334 32.5001V43.1667C29.334 44.6395 30.5279 45.8334 32.0007 45.8334C33.4734 45.8334 34.6673 44.6395 34.6673 43.1667V32.5001Z"
                                                        fill="#004742"></path>
                                                </svg>
                                            </p>
                                            <p className="text-small text-primary text-bold mt-4">İlgili fotofiniş
                                                bulunamamıştır.</p>
                                        </div>}
                                </div>
                            </div>
                            <div>
                                <div className="result-info-card-header">
                                    <svg width="16" height="16" viewBox="0 0 28 29" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect y="0.5" width="16" height="16" rx="14" fill="#FFE700"></rect>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M13.0031 9.90115C13.0091 9.90514 13.0151 9.90914 13.0211 9.91315L18.2955 13.4294C18.4481 13.5311 18.5896 13.6254 18.6983 13.7131C18.8117 13.8045 18.9454 13.9314 19.0223 14.1169C19.124 14.3622 19.124 14.6378 19.0223 14.8831C18.9454 15.0686 18.8117 15.1955 18.6983 15.2869C18.5896 15.3746 18.4481 15.4689 18.2955 15.5706L13.0031 19.0988C12.8166 19.2232 12.6489 19.3351 12.5065 19.4122C12.3641 19.4894 12.1686 19.5776 11.9404 19.564C11.6485 19.5465 11.3789 19.4022 11.2025 19.169C11.0646 18.9867 11.0295 18.7751 11.0147 18.6138C11 18.4526 11 18.251 11 18.0268L11 10.9948C11 10.9876 11 10.9804 11 10.9732C11 10.749 11 10.5474 11.0147 10.3862C11.0295 10.2249 11.0646 10.0133 11.2025 9.83095C11.3789 9.59776 11.6485 9.45346 11.9404 9.43603C12.1686 9.42241 12.3641 9.51062 12.5065 9.5878C12.6489 9.66494 12.8166 9.77678 13.0031 9.90115Z"
                                              fill="#353535"></path>
                                    </svg>
                                    <p className="text-small text-white text-bolder ms-2">Koşu Videosu</p>
                                </div>
                                {shownRun && shownRun.video ? <div className="result-info-card-body"
                                                                   style={{
                                                                       position: "relative",
                                                                       width: "100%",
                                                                       height: "0px",
                                                                       paddingBottom: "56.25%",
                                                                       borderRadius: "0px 0px 10px 10px",
                                                                       background: "black"
                                                                   }}>
                                        <video
                                            height="210"
                                            controls
                                            src={shownRun && shownRun.video ? shownRun.video.toString().replace('http://', 'https://') : null}
                                            style={{
                                                position: "absolute",
                                                width: "100%",
                                                top: "0px",
                                                left: "0px",
                                                borderRadius: "0px 0px 10px 10px"
                                            }}>
                                        </video>

                                    </div> :
                                    <div className="info-not-found">
                                        <p className="text-xsmall ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65"
                                                 viewBox="0 0 64 65" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M32 3.16675C15.7996 3.16675 2.66663 16.2997 2.66663 32.5001C2.66663 48.7004 15.7996 61.8334 32 61.8334C48.2003 61.8334 61.3333 48.7004 61.3333 32.5001C61.3333 16.2997 48.2003 3.16675 32 3.16675Z"
                                                      fill="#E8E6D0"></path>
                                                <path
                                                    d="M32.0007 19.1667C30.5279 19.1667 29.334 20.3607 29.334 21.8334C29.334 23.3062 30.5279 24.5001 32.0007 24.5001H32.0273C33.5001 24.5001 34.694 23.3062 34.694 21.8334C34.694 20.3607 33.5001 19.1667 32.0273 19.1667H32.0007Z"
                                                    fill="#004742"></path>
                                                <path
                                                    d="M34.6673 32.5001C34.6673 31.0273 33.4734 29.8334 32.0007 29.8334C30.5279 29.8334 29.334 31.0273 29.334 32.5001V43.1667C29.334 44.6395 30.5279 45.8334 32.0007 45.8334C33.4734 45.8334 34.6673 44.6395 34.6673 43.1667V32.5001Z"
                                                    fill="#004742"></path>
                                            </svg>
                                        </p>
                                        <p className="text-small text-primary text-bold mt-4">İlgili koşu videosu
                                            bulunamamıştır.</p>
                                    </div>}
                            </div>
                            <div>
                                <div className="result-info-card-header">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                         fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M7.1683 0.750003C7.08565 0.749983 7.01375 0.749965 6.94513 0.754121C6.0775 0.806667 5.31775 1.35427 4.99353 2.16076C4.96789 2.22454 4.94517 2.29276 4.91905 2.37117L4.9135 2.38783C4.8787 2.49221 4.87116 2.5138 4.86475 2.52976C4.75667 2.79858 4.50342 2.98112 4.21421 2.99863C4.19725 2.99966 4.1718 3.00001 4.0542 3.00001L4.01231 3C3.77616 2.99995 3.60266 2.99992 3.45422 3.01462C2.03128 3.15561 0.905612 4.28127 0.764628 5.70422C0.749925 5.85261 0.749959 6.01915 0.750005 6.24402L0.75001 12.181C0.75 12.7847 0.749993 13.283 0.783156 13.6889C0.817599 14.1104 0.891523 14.498 1.07699 14.862C1.36461 15.4265 1.82355 15.8854 2.38804 16.173C2.75204 16.3585 3.13957 16.4324 3.56114 16.4669C3.96703 16.5 4.4653 16.5 5.06902 16.5H12.931C13.5347 16.5 14.033 16.5 14.4389 16.4669C14.8605 16.4324 15.248 16.3585 15.612 16.173C16.1765 15.8854 16.6354 15.4265 16.923 14.862C17.1085 14.498 17.1824 14.1104 17.2169 13.6889C17.25 13.283 17.25 12.7847 17.25 12.181L17.25 6.24401C17.2501 6.01916 17.2501 5.85261 17.2354 5.70422C17.0944 4.28127 15.9687 3.15561 14.5458 3.01462C14.3974 2.99992 14.2239 2.99995 13.9877 3L13.9458 3.00001C13.8282 3.00001 13.8028 2.99966 13.7858 2.99863C13.4966 2.98112 13.2433 2.79858 13.1353 2.52976C13.1289 2.5138 13.1213 2.49221 13.0865 2.38783L13.081 2.37117C13.0549 2.29278 13.0321 2.22452 13.0065 2.16076C12.6823 1.35427 11.9225 0.806667 11.0549 0.754121C10.9863 0.749965 10.9144 0.749983 10.8317 0.750003H7.1683ZM12 9.375C12 11.0319 10.6569 12.375 9 12.375C7.34315 12.375 6 11.0319 6 9.375C6 7.71815 7.34315 6.375 9 6.375C10.6569 6.375 12 7.71815 12 9.375Z"
                                              fill="#FFE700"></path>
                                    </svg>

                                    <p className="text-small text-white text-bolder ms-2">Fotofiniş</p>
                                </div>
                                <div className="result-info-card-body">
                                    {shownRun && shownRun.photo_finish ?
                                        <a href={shownRun.photo_finish.toString().replace('http://', 'https://')}
                                           rel="noreferrer" target="_blank">
                                            <img alt={"fotofiniş"}
                                                 src={shownRun.photo_finish.toString().replace('http://', 'https://')}
                                                 style={{
                                                     width: '100%',
                                                     minHeight: "210px"
                                                 }}/>
                                        </a> :
                                        <div className="info-not-found">
                                            <p className="text-xsmall ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65"
                                                     viewBox="0 0 64 65" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M32 3.16675C15.7996 3.16675 2.66663 16.2997 2.66663 32.5001C2.66663 48.7004 15.7996 61.8334 32 61.8334C48.2003 61.8334 61.3333 48.7004 61.3333 32.5001C61.3333 16.2997 48.2003 3.16675 32 3.16675Z"
                                                          fill="#E8E6D0"></path>
                                                    <path
                                                        d="M32.0007 19.1667C30.5279 19.1667 29.334 20.3607 29.334 21.8334C29.334 23.3062 30.5279 24.5001 32.0007 24.5001H32.0273C33.5001 24.5001 34.694 23.3062 34.694 21.8334C34.694 20.3607 33.5001 19.1667 32.0273 19.1667H32.0007Z"
                                                        fill="#004742"></path>
                                                    <path
                                                        d="M34.6673 32.5001C34.6673 31.0273 33.4734 29.8334 32.0007 29.8334C30.5279 29.8334 29.334 31.0273 29.334 32.5001V43.1667C29.334 44.6395 30.5279 45.8334 32.0007 45.8334C33.4734 45.8334 34.6673 44.6395 34.6673 43.1667V32.5001Z"
                                                        fill="#004742"></path>
                                                </svg>
                                            </p>
                                            <p className="text-small text-primary text-bold mt-4">İlgili fotofiniş
                                                bulunamamıştır.</p>
                                        </div>}
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div>

        <Modal
            isOpen={showRunInfo !== false}
            onRequestClose={() => {
                setShowRunInfo(false);
            }}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    width: '940px',
                    maxHeight: 'calc(100% - 40px)',
                    marginRight: '-50%',
                    maxWidth: ' calc( 100% - 40px )',
                    padding: '15px',
                    transform: 'translate(-50%, -50%)',
                    background: 'var(--bs-white)',
                    borderRadius: 'var(--bs-border-radius-xl)',
                    border: 0
                },
                overlay: {
                    zIndex: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(10px)',
                }
            }} contentLabel="">
            <img alt={"kapat"} src={iconClose} className="modal-close" onClick={() => {
                setShowRunInfo(false)
            }}/>
            <br/>
            {shownRun && <div className={'row'}>
                <div className="col-12 col-lg-7">
                    <h3><span>{shownRun.no}. Koşu</span> - <span>{shownRun.time}</span></h3>
                    {shownRun.gender_detail ? <><span>{shownRun.gender_detail}, </span></> : null}
                    <span className="course">{shownRun.course}, </span>
                    <span>{shownRun.distance}m</span>
                    <span>{shownRun.group}</span>
                    {shownRun.gender ? <><span>{shownRun.gender}, </span></> : null}
                    {shownRun.type_detail ? <><span>{shownRun.type_detail}, </span></> : null}

                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th className="text-right">İkramiyeler</th>
                            <th className="text-right">Primler</th>
                        </tr>
                        </thead>
                        <tbody>
                        {shownRun.prices && shownRun.prices.map((prices: any, key: any) => {
                            return (
                                <tr key={`iprices${key}`}>
                                    <td>{key + 1}</td>
                                    <td className="text-right">{CurrencyFormat(prices)}{shownRun.currency}</td>
                                    <td className="text-right">{CurrencyFormat(shownRun.bonuses[key])} {shownRun.currency}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </div>
                <div className="col p-0">
                    <video controls style={{width: '100%'}}>
                        <source
                            src={shownRun && shownRun.video ? shownRun.video.toString().replace('http://', 'https://') : null}
                            type="video/mp4"/>
                    </video>

                    {shownRun.photo_finish ? <>
                        <h3>Fotofiniş</h3>
                        <a href={shownRun.photo_finish.toString().replace('http://', 'https://')} rel="noreferrer"
                           target="_blank"><img
                            alt={"fotofiniş"}
                            src={shownRun.photo_finish.toString().replace('http://', 'https://')}
                            style={{width: '100%'}}/></a>
                    </> : <></>}

                </div>
            </div>}
        </Modal>


        <Modal
            isOpen={showTrainingVideo !== false}
            onRequestClose={() => {
                setShowTrainingVideo(false);
            }}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    width: '940px',
                    maxHeight: 'calc(100% - 40px)',
                    marginRight: '-50%',
                    maxWidth: ' calc( 100% - 40px )',
                    padding: '15px',
                    transform: 'translate(-50%, -50%)',
                    background: 'var(--bs-white)',
                    borderRadius: 'var(--bs-border-radius-xl)',
                    border: 0
                },
                overlay: {
                    zIndex: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(10px)',
                }
            }} contentLabel="">
            <img alt={"kapat"} src={iconClose} className="modal-close" onClick={() => {
                setShowTrainingVideo(false)
            }}/>
            <div className={"mt-2"}>
                <h2>{hippodrome?.location} {showTrainingVideo.no}. koşu detayı - {showTrainingVideo.time}</h2>
                <p>{showTrainingVideo.info}</p>
            </div>
            <video controls autoPlay style={{width: '100%'}}>
                <source
                    src={showTrainingVideo && showTrainingVideo.video ? showTrainingVideo.video.toString().replace('http://', 'https://') : null}
                    type="video/mp4"/>
            </video>
        </Modal>


        <Modal
            isOpen={mobileRunModal !== false}
            onRequestClose={() => {
                setMobileRunModal(false);
            }}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    width: '940px',
                    maxHeight: 'calc(100% - 40px)',
                    marginRight: '-50%',
                    maxWidth: ' calc( 100% - 40px )',
                    padding: '15px',
                    transform: 'translate(-50%, -50%)',
                    background: 'var(--bs-white)',
                    borderRadius: 'var(--bs-border-radius-xl)',
                    border: 0
                },
                overlay: {
                    zIndex: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(10px)',
                }
            }} contentLabel="">

            <div>
                <img alt={"kapat"} src={iconClose} className="modal-close" onClick={() => {
                    setMobileRunModal(false)
                }}/>
                <br/>
                <h2>{hippodrome?.location} {mobileRunModal.no}. koşu detayı - {mobileRunModal.time}</h2>
                <p>{mobileRunModal.info}</p>
                {
                    type === 'fotofiniş' &&
                    <>
                        {shownRun?.photo_finish ?
                            <a href={shownRun.photo_finish.toString().replace('http://', 'https://')} rel="noreferrer"
                               target="_blank"><img
                                alt={"fotofiniş"}
                                src={shownRun.photo_finish.toString().replace('http://', 'https://')}
                                style={{width: '100%'}}/></a>
                            : <></>}
                    </>
                }
                {
                    type === 'ikramiyeler' &&
                    <>
                        {hippodrome && hippodrome.continuous !== true && shownRun && shownRun.continuous !== true &&


                            <div className="row m-0">
                                <div className="col-12">
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                        {shownRun.bets.filter((x: any) => x.bet.includes('GANYAN')).map((bet: any, betIndex: number) => {
                                            return (<tr key={`betsIndex-${betIndex}`}>
                                                <td><b>{bet.bet}</b> {bet.result}</td>
                                                <td className="text-end fw-semibold">{bet.amount}</td>
                                            </tr>)
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12">
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                        {shownRun.bets.filter((x: any) => !x.bet.includes('GANYAN')).map((bet: any, betIndex2: number) => {
                                            return (<tr key={`betsIndex-${betIndex2}`}>
                                                <td><b>{bet.bet}</b> {bet.result}</td>
                                                <td className="text-end fw-semibold">{bet.amount}</td>
                                            </tr>)
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        }
                    </>
                }
            </div>

        </Modal>


    </>)

}
