import "./member.css";
import {auth, authSubject} from "../../store/auth.store";

import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {wssProvider} from "../../services/websocket.service";

import {useEffect} from "react";

import {useForceUpdate} from "../../services/misc.functions";

export function MemberNotificationInfo() {
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        let auth_subscriber = authSubject.subscribe((res: any) => {
            forceUpdate();
        })
        return () => {
            auth_subscriber.unsubscribe();
        }
    }, [])


    const updateNotificationSettings = (payload: any, obj: any) => {
        // for (const [k, v] of Object.entries(payload)) {
        //   auth.member[k] = v;
        // }
        // forceUpdate();
        obj.target.disabled = true;
        const api = new ApiService();
        api
            .start(
                "post",
                env.api + "/member/api/update-account-info/",
                payload,
                true
            )
            .then((res: any) => {
                wssProvider.checkauth();
                forceUpdate();
            })
            .finally(() => {
                obj.target.disabled = false;
            });
    };


    return (<>
            <div className="row ">
                <div className="column">
                    <p className="text-md text-bold mb-3">İletişim Ayarları</p>
                    <p className="text-xsmall mb-3"
                    >İletişim bilgilerimin reklam, promosyon vb. Ticari
                        elektronik ileti gönderilmek üzere işlenmesini, bu amaçla kullanılmasını, saklanmasını ve ticari
                        elektronik ileti gönderilmesini ve bu gönderimin sağlanması için Türkiye’de ve Avrupa
                        Birliği’nde
                        yerleşik hizmet alınan üçüncü kişilerle paylaşılmasını <span
                            className="text-xsmall text-primary tex-tbold text-decoration-underline ">Elektronik Ticari İleti kuralları çerçevesinde</span> kabul
                        ediyorum.</p>
                    <div className="settings-wrapper">
                        <div>
                            <p className="text-small text-bold mb-3">Kampanya Bildirim
                                Ayarları</p>
                            <p className="text-xsmall mb-3">En geç 3 (üç) iş günü içerisinde yapmış olduğunuz
                                değişiklikler uygulamaya alınacaktır.</p>
                        </div>
                        <div className="setting-row">
                            <p className="text-small">E-Posta</p>
                            <div className="d-flex">
                                <label className="toggle-switch">
                                    <input
                                        onChange={(e: any) => {
                                            updateNotificationSettings(
                                                {mail_notification: !auth.member.mail_notification},
                                                e
                                            );
                                        }}
                                        className="toggle-switch-input" id="eposta" type="checkbox"
                                        checked={auth.member.mail_notification}
                                        name="eposta"/>
                                    <div className="toggle-switch-area"></div>
                                    <span className="toggle-switch-label"></span>
                                </label>
                            </div>
                        </div>
                        <div className="setting-row">
                            <p className="text-small">SMS</p>
                            <div className="d-flex">
                                <label className="toggle-switch">
                                    <input
                                        checked={auth.member.sms_notification}
                                        onChange={(e: any) => {
                                            updateNotificationSettings(
                                                {sms_notification: !auth.member.sms_notification},
                                                e
                                            );
                                        }}
                                        className="toggle-switch-input" id="sms" type="checkbox"
                                        name="sms"/>
                                    <div className="toggle-switch-area"></div>
                                    <span className="toggle-switch-label"></span>
                                </label>
                            </div>
                        </div>
                        <div className="setting-row">
                            <p className="text-small">Müşteri Hizmetleri</p>
                            <div className="d-flex ">
                                <label className="toggle-switch">
                                    <input
                                        checked={auth.member.call_notification}
                                        onChange={(e: any) => {
                                            updateNotificationSettings(
                                                {call_notification: !auth.member.call_notification},
                                                e
                                            );
                                        }}
                                        className="toggle-switch-input" id="call" type="checkbox" name="call"/>
                                    <div className="toggle-switch-area"></div>
                                    <span className="toggle-switch-label"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
