import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {useEffect, useState} from "react";
import {arrayMoveImmutable} from "array-move";
import {
  addToTJKBetSlip,
  betSlipActionSubject,
  removeFromBetSlip,
  tjkBetSlipOptions,
  tjkBetslipStore
} from "../../services/betslip.service";
import iconTrash from "../../assets/feather/trash-2.svg";
import {DailyRaces} from "../../store/bulletin.store";

let races:any = [];
let horses:any = [];
let horsesMap:any = {};
let run:any = {};

const DragHandle = SortableHandle(({children}:any)=> {
  return children;
})

const SortableItem = SortableElement(({value}:any) => <li>
  <DragHandle>
    <div className={"slip-item"}>
      <span className="text-xxsmall  horse-no">{value.no}</span>
      <span className="text-xxsmall  horse-name">{value.name}</span>
      <div className="slip-item-sort">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
          <path d="M4.08325 8.75004L6.99992 11.6667L9.91659 8.75004M4.08325 5.25004L6.99992 2.33337L9.91659 5.25004"
                stroke="var(--primary-color)" stroke-width="1.66" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </div>
      <button onClick={() => {
        removeFromBetSlip(1, run, value);
      }} className="button xs tertiary outlined delete ">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 16" fill="none">
          <path
              d="M3.83329 5.33325V11.9999C3.83329 13.4727 5.0272 14.6666 6.49996 14.6666H10.5C11.9727 14.6666 13.1666 13.4727 13.1666 11.9999V5.33325M9.83329 7.33325V11.3333M7.16663 7.33325L7.16663 11.3333M11.1666 3.33325L10.2291 1.92698C9.98183 1.55605 9.56552 1.33325 9.11971 1.33325H7.88021C7.4344 1.33325 7.01809 1.55605 6.7708 1.92698L5.83329 3.33325M11.1666 3.33325H5.83329M11.1666 3.33325H14.5M5.83329 3.33325H2.49996"
              stroke="var(--primary-color)" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </button>
    </div>


  </DragHandle>

</li>);

const SortableList = SortableContainer(({items}: any) => {
  return (
      <ul className="sortable-horses">
        {items.map((value: any, index: any) => (
            // @ts-ignore
            <SortableItem key={`item-${index}`} index={index} value={horsesMap[Number(value.horse)]}/>
        ))}
      </ul>
  );
});


export function SortMyHorse(props: any) {
  const [itemOrder, setItemOrder] = useState<any>([]);

  useEffect(() => {
    let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
      if (tjkBetSlipOptions.bettingType) {
        setItemOrder([...tjkBetslipStore.filter((el: any) => el.bettingType === tjkBetSlipOptions.bettingType.id
            && el.bettingSubType === tjkBetSlipOptions.bettingSubType.id
            && el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id))]);
      }
    });
    return () => {
      betSlipActionSubject_subscription.unsubscribe();
    }
  }, []);

  if(DailyRaces.length > 0 && races.length === 0) {
    races = DailyRaces.find((h: any) => Number(h.id) === Number(tjkBetSlipOptions.hippodrome.id)).runs;
    horses = races.find((r: any) => Number(r.no) === Number(tjkBetSlipOptions.bettingSubType.id)).horses;
    for (let horse of horses) {
      horsesMap[Number(horse.no)] = horse;
    }
  }



  useEffect(() => {

    races = DailyRaces.find((h: any) => Number(h.id) === Number(tjkBetSlipOptions.hippodrome.id)).runs;
    run = races.find((r: any) => Number(r.no) === Number(tjkBetSlipOptions.bettingSubType.id));
    horses = run.horses;
    for (let horse of horses) {
      horsesMap[Number(horse.no)] = horse;
    }

    setItemOrder([...tjkBetslipStore.filter((el:any)=>el.bettingType === tjkBetSlipOptions.bettingType.id
      && el.bettingSubType === tjkBetSlipOptions.bettingSubType.id
      && el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id))]);
  }, [tjkBetSlipOptions.bettingSubType, tjkBetSlipOptions.bettingType, tjkBetSlipOptions.hippodrome]);

  const onSortEnd = (e: any) => {
    const {oldIndex, newIndex} = e;
    let newArray:any = arrayMoveImmutable(itemOrder, oldIndex, newIndex)
    for (let item of newArray) {
      let exIndex = tjkBetslipStore.findIndex(
        (el: any) => el.bettingType === tjkBetSlipOptions.bettingType.id &&
          el.bettingSubType === tjkBetSlipOptions.bettingSubType.id &&
          el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
          Number(el.horse) === Number(item.horse)
      )
      // console.log(exIndex, tjkBetslipStore[exIndex] , newArray.indexOf(item))
      Object.assign(tjkBetslipStore, arrayMoveImmutable(tjkBetslipStore, exIndex, newArray.indexOf(item)))
    }
    setItemOrder(newArray);

    props.parentUpdate()
  };

  const addFromSelect = (e:any)=>{
    addToTJKBetSlip(
      1,
      run,
      run.horses.find((h: any) => Number(h.no) === Number(e)),
    )

    let i = tjkBetslipStore.findIndex(
      (el: any) => el.bettingType === tjkBetSlipOptions.bettingType.id &&
        el.bettingSubType === tjkBetSlipOptions.bettingSubType.id &&
        el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
        Number(el.horse) === Number(e)
    )

    if (i !== -1) {
      itemOrder.splice(i, 1);
    } else {
      itemOrder.push(tjkBetslipStore[i]);
    }
  }

  // @ts-ignore
  return (<><SortableList items={itemOrder} useDragHandle={true} helperClass={"sortable-horse-helper"}  onSortEnd={onSortEnd} /></>)
}

function HorseSelectDropDown(prop:any){

  const items = prop.items;
  const addFromSelect = prop.addFromSelect;

  return (<select className="w-100 mb-2" defaultValue={"-1"} onChange={(e: any) => {
    if (e.target.value !== '-1') {
      addFromSelect(e.target.value);
      e.target.value = '-1';
    }
  }}>
    <option value='-1'>At Seçiniz</option>
    {horses.filter((h: any) => h.run_status === true && ![...items.map((i:any)=>String(i.horse))].includes(String(h.no))).map((horse: any, key: number) => {
      return (<option key={`horse-select-${key}`}
                      value={horse.no}>{horse.no} - {horse.name}</option>)
    })}
  </select>)
}
