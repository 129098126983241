import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {env, passwordModalStyles} from "../../constants/global.vars";
import Modal from "react-modal"
import {ApiService} from "../../services/http.service";
import {modalServiceSubject} from "../../services/modal.service";
import paths from "../../paths";
import InputMask from "react-input-mask";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import EyeIcon from "../../assets/feather/eye.svg";
import {exceptionModal} from "../../services/misc.functions";
import {useNavigate} from "react-router-dom";
import FormError from "../../shared/form.error";

let s: any;
let resendButton: any;
export default function ForgetPassword(props: any) {
    const navigate = useNavigate();
    const [step, setStep] = useState<any>(1);
    const [code, setCode] = useState<any>('');
    const [showSelection, setShowSelection] = useState<any>(null);
    const [selectedType, setSelectedType] = useState<any>('');
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [userId, setUserId] = useState<any>(null)
    const passwordModal = props.passwordModal;
    const closePasswordModal = props.closePasswordModal;
    let api = new ApiService();

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<any>()

    const onSubmit: SubmitHandler<any> = data => {
        api
            .start(
                "post",
                env.api + "/member/api/member-contact-information/",
                data,
                false
            )
            .then((res: any) => {
                if (res.status) {
                    setShowSelection(res.data);
                    setStep(2)
                } else {
                    modalServiceSubject.next({
                        case: "danger",
                        title: "Hata!",
                        content: `${res.message}`,
                        timeout: 5000,
                        confirm: {
                            sure: {
                                class: "btn btn-danger",
                                label: "Kapat",

                            },
                        },
                    });
                }
                // console.log(res)
            })
            .catch((e: any) => {
                // console.log(e)
            });

    }

    const senderTimer = (s: any = null) => {
        let resendTimer: any = document.getElementById("id_resendTimer");
        if (resendTimer) {
            let secondLeft: number = Number(
                Number(resendTimer.innerHTML.split(":")[0]) * 60 +
                Number(resendTimer.innerHTML.split(":")[1]) -
                1
            );
            if (secondLeft < 0) {
                clearInterval(s);
                resendButton = document.getElementById("id_resendCode");
                if (resendButton) {
                    resendButton.classList.remove("disabled");
                    resendButton.disabled = false;
                }
            } else {
                resendTimer.innerHTML =
                    Math.floor(secondLeft / 60) +
                    ":" +
                    ("00" + (secondLeft % 60)).slice(-2);
            }
        }
    };

    const callChangePasswordApi = (type: any = null, data: any) => {

        var payload = {
            return_address: `${window.location.protocol}//${window.location.host}${paths.resetPassword.url}`,
            user_id: showSelection.user_id,
            messenger_type: type ?? selectedType,
        };

        api.start('post', env.api + '/member/api/password-reminder/', payload, false)
            .then((res: any) => {
                if (res.status) {
                    setStep(3);
                    s = setInterval(senderTimer, 1000);
                    if (data.resend) {
                        if (resendButton) {
                            resendButton.classList.add("disabled");
                            resendButton.disabled = true;
                        }
                        let resendTimer: any =
                            document.getElementById("id_resendTimer");
                        if (resendTimer) {
                            resendTimer.innerHTML = "2:00";
                        }
                    }
                } else {
                    modalServiceSubject.next({title: 'Hata!', content: `${res.message}`, case: 'danger', timeout: 5000})
                }
                console.log(res)
            })
            .catch((e: any) => {
                exceptionModal(e);
            })
    }

    const onSubmitCode: SubmitHandler<any> = (data) => {
        data.code = data.code.replaceAll(' ', '');

        api
            .start(
                "post",
                env.api + "/member/api/password-code-validate/",
                data,
                false
            )
            .then((res: any) => {
                if (res.status) {
                    setCode(data.code);
                    setStep(4);

                } else {
                    modalServiceSubject.next({
                        case: "danger",
                        title: "Hata!",
                        content: `${res.message}`,
                        timeout: 5000,
                        confirm: {
                            cancel: {
                                class: "btn btn-danger",
                                label: "Kapat",
                            },
                        },
                    });
                }

            })
            .catch((e: any) => {
                exceptionModal(e);
            });

    };

    const onSubmitPassword: SubmitHandler<any> = (data) => {
        data.code = code.replaceAll(' ', '');
        api
            .start(
                "post",
                env.api + "/member/api/password-renew-with-code/",
                data,
                false
            )
            .then((res: any) => {
                if (res.status) {
                    modalServiceSubject.next({
                        case: "success",
                        title: "Şifre Başarıyla Değiştirildi",
                        content: `Şifreniz başarıyla değiştirildi. Hemen giriş yaparak kaldığınız yerden devam edebilirsiniz.
`,
                        timeout: 5000,
                        confirm: {
                            sure: {
                                label: "Giriş Yap",
                                action: () => {
                                    navigate(paths.login.url);
                                }
                            },
                        },
                    });
                    closePasswordModal();
                } else {
                    setErrorMessage(res.message);
                }

            })
            .catch((e: any) => {
                exceptionModal(e);
            });

    };

    const isPasswordVisible = () => {
        let password: any = document.getElementById("id_password");
        let eyeIcon: any = document.getElementById("eye-icon");

        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        } else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };

    const isPasswordVisible2 = () => {
        let password: any = document.getElementById("id_password2");
        let eyeIcon: any = document.getElementById("eye-icon2");

        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        } else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };

    return (
        <>
            <Modal
                isOpen={passwordModal}
                onRequestClose={() => {
                    try {
                        clearInterval(s);
                    } catch (e) {
                    }
                    setStep(1);
                    reset({email: "", code: "", password: "", password2: ""});
                    closePasswordModal();
                }}
                style={passwordModalStyles}
                contentLabel="">


                {step === 1 &&
                    <div className="forget-password-modal">
                        <div className={"modal-header"}>

                            <div onClick={() => {
                                navigate(paths.login.url);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path d="M15 18L9 12L15 6" stroke="white" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                            </div>
                            <h5 className="w-100 text-center text-bolder align-self-center mb-0 text-white">Şifremi
                                Unuttum</h5>
                            <div onClick={() => {
                                navigate(paths.home.url);
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="white"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                        <div className={"modal-body my-4"}>
                            <p className="text-md text-center">Üyelik bilgilerinizi unuttuysanız, <br/> T.C. kimlik
                                numaranızı
                                girin.</p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row">
                                    <div className="w-100">
                                        <label className={"text-primary text-small mb-1 fw-medium"}>
                                            T.C. Kimlik Numarası
                                        </label>
                                        <input
                                            placeholder=""
                                            {...register("tc_id", {required: true})}
                                        />
                                        {errors.email && errors.email.type === "required" &&
                                            <FormError error={"TC No gereklidir."}/>}
                                        {errors.email && errors.email.type === "minLength" &&
                                            <FormError error={"TC No hatalı"}/>}
                                    </div>
                                </div>
                                <button type={"submit"} className="button sm secondary filled mt-3 w-100">
                                    <p className="text-small fw-medium text-center">Devam</p>
                                </button>
                            </form>
                        </div>

                    </div>}
                {step === 2 &&
                    <div className="forget-password-modal">
                        <div className={"modal-header"}>

                            <div onClick={() => {
                                setStep(1);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path d="M15 18L9 12L15 6" stroke="white" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                            </div>
                            <h5 className="w-100 text-center text-bolder align-self-center mb-0 text-white">Şifremi
                                Unuttum</h5>
                            <div onClick={() => {
                                navigate(paths.home.url);
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="white"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                        <div className={"modal-body my-4"}>
                            <div className="row ">
                                <div className="col p-0">
                                    <div className="pt-4 mb-4">
                                        <p className="text-md text-center">Şifre değiştirme yöntemini seç.</p>
                                    </div>
                                    <div className="mb-4">
                                        {showSelection.mobile && (
                                            <div
                                                className="d-flex forgot-password-options"
                                                onClick={() => {
                                                    setSelectedType('sms');
                                                    callChangePasswordApi('sms', {'mobile': userId, "resend": true});
                                                }}
                                            >
                                                <input id="phoneNumber" type="radio" name="forgotPassword"/>
                                                <label>
                                                    <div className="forgot-password-label ms-2">
                                                        <div>Cep Telefonu ile Şifre Yenile</div>
                                                        <div
                                                            className="forgot-password-info mt-1">{showSelection.mobile}</div>
                                                    </div>
                                                </label>
                                            </div>
                                        )}
                                        {/*active-selection*/}
                                        {showSelection.email && (
                                            <div
                                                className="d-flex forgot-password-options "

                                                onClick={() => {
                                                    setSelectedType('mail');
                                                    callChangePasswordApi('mail', {'email': userId, "resend": true})
                                                }}
                                            >
                                                <input id="email" type="radio" name="forgotPassword"/>
                                                <label>
                                                    <div className="forgot-password-label ms-2">
                                                        <div>E-posta ile Şifre Yenile</div>
                                                        <div
                                                            className="forgot-password-info mt-1">{showSelection.email}</div>
                                                    </div>
                                                </label>
                                            </div>
                                        )}


                                    </div>
                                    <button className="button sm secondary filled mt-3 w-100 ">
                                        <p className="text sm default bold center  none   lh-normal">Devam</p>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {step === 3 &&
                    <div className="forget-password-modal">
                        <div className={"modal-header"}>

                            <div onClick={() => {
                                setStep(2);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path d="M15 18L9 12L15 6" stroke="white" strokeWidth="2" strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                            </div>
                            <h5 className="w-100 text-center text-bolder align-self-center mb-0 text-white">Doğrulama
                                Kodu</h5>
                            <div onClick={() => {
                                navigate(paths.home.url);
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="white"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                        <div className={"modal-body py-4"}>
                            <form onSubmit={handleSubmit(onSubmitCode)}>
                                <div className="pt-4 mb-4 pb-1">

                                    {showSelection.mobile && <p className="text-md text-center">
                                        <span className="text-md text-primary text-bold ">{showSelection.mobile} </span>numaralı
                                        telefona gelen 6 haneli <br/> doğrulama kodunu gir.
                                    </p>}

                                    {showSelection.email && <p className="text-md text-center">
                                        <span
                                            className="text-md text-primary text-bold ">{showSelection.email} </span> adresinize
                                        gelen 6 haneli <br/> doğrulama kodunu gir.
                                    </p>}
                                </div>
                                <InputMask
                                    mask="999 999"
                                    className="form-control password-code-input mb-4"
                                    placeholder={"_ _ _ _ _ _"}
                                    {...register<any>("code", {required: true})}
                                />
                                <div className="row mb-4" data-testid="row">
                                    <div className="col-12  d-flex justify-content-center mb-1 p-0">
                                        <p className="text-sm ">Kalan Süre</p>
                                    </div>
                                    <div className="col-12  d-flex justify-content-center p-0">
                                        <p className="text-xl text-primary text-bold">
                                            <span id="id_resendTimer">3:00</span>
                                        </p>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-between align-items-center mt-2"}>
                                    <div style={{flex: 1}}>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                clearInterval(s);
                                                callChangePasswordApi(selectedType, {'email': userId, "resend": true});
                                            }}

                                            className="button xs secondary filled w-100 text-bolder disabled"
                                            id="id_resendCode">
                                            Tekrar Gönder
                                        </button>
                                    </div>


                                </div>
                                <div className={"d-flex justify-content-between align-items-center mt-2"}>
                                    <div style={{flex: 1}} className={"text-end"}>
                                        <button type={"submit"}
                                                className="button xs secondary filled w-100 text-bolder">Devam
                                        </button>
                                    </div>


                                </div>

                            </form>

                        </div>

                    </div>
                }
                {step === 4 &&
                    <div className="forget-password-modal">
                        <div className={"modal-header"}>

                            <h5 className="w-100 text-center text-bolder align-self-center mb-0 text-white">Şifre
                                Değiştir</h5>
                            <div onClick={() => {
                                navigate(paths.home.url);
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="white"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                        <div className={"modal-body my-4"}>
                            <form onSubmit={handleSubmit(onSubmitPassword)}>
                                <div className="text-field-wrapper">
                                    <p className="text-small text-primary mb-2">Yeni
                                        Şifre</p>
                                    <input id="is_new_password" className="text-field-input"
                                           type="password" {...register<any>('password', {required: true})}/>
                                    <div className="cursor-pointer eye-icon float-right mr-2"
                                         onClick={() => isPasswordVisible()}>
                                        <img alt={"eye"} id="eye-icon2" className="eye-icon" height={16} width={16}
                                             src={EyeOffIcon}/>
                                    </div>
                                </div>
                                <div className="text-field-wrapper">
                                    <p className="text-small text-primary mb-2">Yeni
                                        Şifre (Tekrar)</p>
                                    <input id="is_new_password2" className="text-field-input"
                                           type="password" {...register<any>('password2', {required: true})}/>
                                    <div className="cursor-pointer eye-icon float-right mr-2"
                                         onClick={() => isPasswordVisible2()}>
                                        <img alt={"eye"} id="eye-icon2" className="eye-icon" height={16} width={16}
                                             src={EyeOffIcon}/>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <span className="text-xsmall me-2">Şifre kuralları:</span>
                                    <span className="text-xsmall password-validator-item">rakam olmalı</span>
                                    <span className="text-xsmall password-validator-item">harf olmalı</span>
                                    <span className="text-xsmall password-validator-item">en az 8 karakter</span>
                                    <span className="text-xsmall password-validator-item">TCKN olmamalı</span>
                                    <span className="text-xsmall password-validator-item">doğum yılı olmamalı</span>
                                    <span className="text-xsmall password-validator-item">isim-soyisim olmamalı</span>
                                    <span
                                        className="text-xsmall password-validator-item">3 kez tekrar etmeyen sayı</span>
                                </div>
                                {errorMessage && <div className="position-relative">
                                    <p className="my-2 text-danger">
                                        {errorMessage}
                                    </p>
                                </div>}
                                <button className="button sm secondary filled mt-3 w-100 ">
                                    <p className="text-small text-bold text-center">Güncelle</p>
                                </button>
                            </form>
                        </div>

                    </div>
                }
            </Modal>
        </>
    )
}
