import "./withdraw.css";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {env} from "../../constants/global.vars";
import {auth, authSubject} from "../../store/auth.store";
import {SubmitHandler, useForm} from "react-hook-form";
import {ApiService} from "../../services/http.service";
import {dateFormat, preventNegativeValues, useForceUpdate} from "../../services/misc.functions";
import _ from "lodash";
import FormError from "../../shared/form.error";
import AddBankAccountModal from "../../shared/bankAccount/add.bank.account.modal";

export default function Withdraw() {

    const [accountList, setAccountList] = useState<any>(null);
    const [selectedAccount, setSelectedAccount] = useState<any>(null);
    const [withdrawList, setWithdrawList] = useState<any>([]);
    const [openBankAccounts, setOpenBankAccounts] = useState<any>(false);
    const [showAddBankAccountModal, setShowAddBankAccountModal] = useState<any>(false);

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        let auth_subscriber = authSubject.subscribe(() => {
            forceUpdate();
        });
        return () => {
            auth_subscriber.unsubscribe();
        };
    }, []);

    useEffect(() => {
        getWithdrawHistory();
        getListAccount();
    }, []);

    const getWithdrawHistory = () => {
        const api = new ApiService();
        api.start("get", env.api + "/accounting/api/list-withdraw/", null, true).then((res: any) => {
            // console.log(res)
            if (res && res.data) {
                var wdList = [];
                for (const datum of res.data) {
                    if (datum.status === 0) {
                        wdList.push(datum);
                    }
                }
                setWithdrawList(wdList);
            }
        });
    };

    const getListAccount = () => {
        const api = new ApiService();
        api
            .start("get", env.api + "/member/api/list-account/", null, true)
            .then((res: any) => {
                if (res && res.status) {
                    setAccountList(_.orderBy(res.data, ["id"], ["desc"]));
                    setSelectedAccount(res.data[0]);
                } else {
                    setAccountList([]);
                }
            })
            .catch((e: any) => {
                // exceptionModal(e);
                setAccountList([]);
            }).finally(() => {

            if (accountList && accountList.length > 0) {
                setSelectedAccount(accountList[0]);
            }
        });
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
    } = useForm<any>();


    const onSubmit: SubmitHandler<any> = (data: any) => {
        const api = new ApiService();

        if (!auth.member || !auth.member?.id) {
            return toast.error("Lütfen giriş yapınız.");
        }

        if (data.amount === 0) {
            return toast.error("Çekim tutarı 0 olamaz.");
        }

        if (Number(auth.member.balance_debit_available) < Number(data.amount)) {
            return toast.error("Yetersiz Bakiye. Lütfen çekilebilir bakiyenizi kontrol ediniz.");
        }

        let id_submit_withdraw: any = document.getElementById("id_submit_withdraw");
        if (id_submit_withdraw) {
            id_submit_withdraw.disabled = true;
        }
        if (selectedAccount != null) {
            data.account_id = selectedAccount.id;
        } else {
            return toast.error("Lütfen hesap seçiniz.");
        }

        api
            .start("post", env.api + "/accounting/api/create-withdraw-request/", data, true)
            .then((res: any) => {
                if (res && res.status) {
                    getWithdrawHistory();
                    toast.success(res.message);
                    reset({account_id: -1, amount: "", sure: false});
                } else {
                    toast.error(res.message);
                }
            })
            .finally(() => {
                id_submit_withdraw.disabled = false;
            });
    };


    return (
        <div className="row ">
            <div className="col p-0">
                <p className="text-md text-bold">Para Çek</p>
                <div className="mt-4 withdraw-page-wrapper">
                    <div className="d-flex withdraw-title">
                        <p className="text-md text-white ">Çekilebilir Tutar: </p>
                        <p className="text-md text-white text-bold mw-1">{auth.member.balance_debit}₺</p>
                    </div>

                    {accountList && accountList.length === 0 ?
                        <div className="withdraw-add-bank-account-wrapper">
                            <div className="withdraw-bank-icon">
                                <svg width="70" height="70" viewBox="0 0 70 70" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="70" height="70" rx="35" fill="#E8E6D0"></rect>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M22.5 46.25C22.5 45.5596 23.0596 45 23.75 45H46.25C46.9404 45 47.5 45.5596 47.5 46.25C47.5 46.9404 46.9404 47.5 46.25 47.5H23.75C23.0596 47.5 22.5 46.9404 22.5 46.25Z"
                                          fill="#004742"></path>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M27.5 31.25C28.1904 31.25 28.75 31.8096 28.75 32.5V42.5C28.75 43.1904 28.1904 43.75 27.5 43.75C26.8096 43.75 26.25 43.1904 26.25 42.5V32.5C26.25 31.8096 26.8096 31.25 27.5 31.25Z"
                                          fill="#004742"></path>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M32.5 31.25C33.1904 31.25 33.75 31.8096 33.75 32.5V42.5C33.75 43.1904 33.1904 43.75 32.5 43.75C31.8096 43.75 31.25 43.1904 31.25 42.5V32.5C31.25 31.8096 31.8096 31.25 32.5 31.25Z"
                                          fill="#004742"></path>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M37.5 31.25C38.1904 31.25 38.75 31.8096 38.75 32.5V42.5C38.75 43.1904 38.1904 43.75 37.5 43.75C36.8096 43.75 36.25 43.1904 36.25 42.5V32.5C36.25 31.8096 36.8096 31.25 37.5 31.25Z"
                                          fill="#004742"></path>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M42.5 31.25C43.1904 31.25 43.75 31.8096 43.75 32.5V42.5C43.75 43.1904 43.1904 43.75 42.5 43.75C41.8096 43.75 41.25 43.1904 41.25 42.5V32.5C41.25 31.8096 41.8096 31.25 42.5 31.25Z"
                                          fill="#004742"></path>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M34.5845 21.4086C34.858 21.3465 35.142 21.3465 35.4155 21.4086C35.7403 21.4824 36.0202 21.6613 36.1552 21.7476C36.1692 21.7565 36.1817 21.7645 36.1925 21.7713L45.6625 27.69C46.1354 27.9855 46.3553 28.5586 46.2016 29.0946C46.0478 29.6306 45.5576 30 45 30H25C24.4424 30 23.9522 29.6306 23.7984 29.0946C23.6447 28.5586 23.8646 27.9855 24.3375 27.69L33.8075 21.7713C33.8183 21.7645 33.8308 21.7565 33.8448 21.7476C33.9798 21.6613 34.2597 21.4824 34.5845 21.4086Z"
                                          fill="#004742"></path>
                                </svg>
                            </div>
                            <p className="text-md text-bold text-center mb-3">Kayıtlı banka hesabınız yok</p>
                            <p className="text-small text-center mb-4"> Hesabınızı kaydederek bakiyenizdeki
                                belirlediğiniz
                                miktar parayı banka hesabınıza aktarabilirsiniz.</p>
                            <button className="button xs secondary filled add-new-bank-account-btn" onClick={() => {
                                setShowAddBankAccountModal(true)
                            }}>
                                <div className="add-new-bank-account-btn-icon">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M7.99996 0.666656C3.94987 0.666656 0.666626 3.9499 0.666626 7.99999C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 7.99999C15.3333 3.9499 12.05 0.666656 7.99996 0.666656ZM7.99996 4.66666C8.36815 4.66666 8.66663 4.96513 8.66663 5.33332V7.33332H10.6666C11.0348 7.33332 11.3333 7.6318 11.3333 7.99999C11.3333 8.36818 11.0348 8.66666 10.6666 8.66666H8.66663V10.6667C8.66663 11.0348 8.36815 11.3333 7.99996 11.3333C7.63177 11.3333 7.33329 11.0348 7.33329 10.6667V8.66666H5.33329C4.9651 8.66666 4.66663 8.36818 4.66663 7.99999C4.66663 7.6318 4.9651 7.33332 5.33329 7.33332H7.33329V5.33332C7.33329 4.96513 7.63177 4.66666 7.99996 4.66666Z"
                                              fill="#353535"></path>
                                    </svg>
                                    <p className="text-xsmall  text-bold"> Banka Hesabı Ekle</p></div>
                            </button>
                        </div> :
                        <div className="withdraw-form-wrapper">
                            <div className="info-box mt-0">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M1.50171 8.5C1.50171 4.44992 4.78495 1.16667 8.83504 1.16667C12.8851 1.16667 16.1684 4.44992 16.1684 8.5C16.1684 12.5501 12.8851 15.8333 8.83504 15.8333C4.78495 15.8333 1.50171 12.5501 1.50171 8.5ZM8.11553 13.0425H9.57728V6.72436H8.11553V13.0425ZM9.46855 5.52839C9.64574 5.36732 9.73433 5.14987 9.73433 4.87604C9.73433 4.59416 9.64574 4.37269 9.46855 4.21161C9.29137 4.05054 9.08198 3.97 8.84037 3.97C8.5907 3.97 8.37728 4.05054 8.2001 4.21161C8.02292 4.37269 7.93433 4.59416 7.93433 4.87604C7.93433 5.14987 8.02292 5.36732 8.2001 5.52839C8.37728 5.68946 8.5907 5.77 8.84037 5.77C9.08198 5.77 9.29137 5.68946 9.46855 5.52839Z"
                                          fill="#004742"></path>
                                </svg>
                                <span
                                    className="text-xxsmall">Tüm bankalara en az 20 TL para çekim talebi verebilirsiniz.</span>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="withdraw-form">
                                    <div className="withdraw-dropdown">
                                        <p className="text-small text-primary  mb-2">Hesap Adı</p>
                                        <button className="dropdown-toggle" onClick={() => {
                                            setOpenBankAccounts(!openBankAccounts);
                                        }} type={'button'}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                cursor: "pointer"
                                            }}>
                                                <div>
                                                    <p className="text-xsmall">{selectedAccount?.name} </p>
                                                </div>
                                                <div className="dropdown-icon">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 6L8 10L12 6" stroke="#000" strokeWidth="1.5"
                                                              strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </button>
                                        {openBankAccounts && <div className="withdraw-dropdown-menu">
                                            {
                                                accountList.map((account: any, index: any) => {
                                                    return <button type={"button"}
                                                                   className="withdraw-dropdown-menu-item"
                                                                   onClick={() => {
                                                                       setSelectedAccount(account);
                                                                       setOpenBankAccounts(false);
                                                                   }}>
                                                        <div>
                                                            <p className="text-small text-start mb-1 withdraw-long-text">{account.name}</p>
                                                            <p className="text-xsmall  text-start withdraw-iban-text none">{account.iban}</p>
                                                        </div>
                                                        <div className="withdraw-icon">
                                                            {selectedAccount?.id === account.id &&
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21"
                                                                     height="20"
                                                                     viewBox="0 0 21 20" fill="none">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                          d="M10.6272 0.833344C5.56463 0.833344 1.46057 4.9374 1.46057 10C1.46057 15.0626 5.56463 19.1667 10.6272 19.1667C15.6898 19.1667 19.7939 15.0626 19.7939 10C19.7939 4.9374 15.6898 0.833344 10.6272 0.833344ZM14.9665 8.08926C15.2919 7.76383 15.2919 7.23619 14.9665 6.91075C14.6411 6.58532 14.1134 6.58532 13.788 6.91075L9.37724 11.3215L7.46649 9.41075C7.14106 9.08532 6.61342 9.08532 6.28798 9.41075C5.96254 9.73619 5.96254 10.2638 6.28798 10.5893L8.78798 13.0893C9.11342 13.4147 9.64106 13.4147 9.96649 13.0893L14.9665 8.08926Z"
                                                                          fill="#129B3F"></path>
                                                                </svg>}
                                                        </div>
                                                    </button>
                                                })
                                            }


                                            <button type={"button"}
                                                    className="button xs secondary filled add-bank-account-btn "
                                                    onClick={() => {
                                                        setShowAddBankAccountModal(true);
                                                        setOpenBankAccounts(false);
                                                    }}>
                                                <div className="withdraw-icon-btn">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M7.99996 0.666656C3.94987 0.666656 0.666626 3.9499 0.666626 7.99999C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 7.99999C15.3333 3.9499 12.05 0.666656 7.99996 0.666656ZM7.99996 4.66666C8.36815 4.66666 8.66663 4.96513 8.66663 5.33332V7.33332H10.6666C11.0348 7.33332 11.3333 7.6318 11.3333 7.99999C11.3333 8.36818 11.0348 8.66666 10.6666 8.66666H8.66663V10.6667C8.66663 11.0348 8.36815 11.3333 7.99996 11.3333C7.63177 11.3333 7.33329 11.0348 7.33329 10.6667V8.66666H5.33329C4.9651 8.66666 4.66663 8.36818 4.66663 7.99999C4.66663 7.6318 4.9651 7.33332 5.33329 7.33332H7.33329V5.33332C7.33329 4.96513 7.63177 4.66666 7.99996 4.66666Z"
                                                              fill="#353535"></path>
                                                    </svg>
                                                    <p className="text-xsmall text-bold"> Yeni Hesap Ekle</p>
                                                </div>
                                            </button>
                                        </div>}
                                    </div>
                                    <div className="currency-field-wrapper">
                                        <p className="text-small text-primary mb-2">Çekilecek Tutar</p>
                                        <div>
                                            <p className="text-small currency-tl">₺</p>
                                            <input className="currency-field-input" type="number"
                                                   pattern="\d*"
                                                   disabled={auth.member?.balance_debit_available === 0}
                                                   max={auth.member?.balance_debit_available || 1000000}
                                                   placeholder={""}
                                                   onKeyDown={preventNegativeValues}
                                                   {...register<any>("amount", {required: true, min: 20})}
                                                   maxLength={10}
                                                   min={20}
                                                   step={0.01}/>
                                            {errors.amount &&
                                                <FormError error={"Lütfen geçerli bir tutar giriniz. Min:20₺"}/>}
                                        </div>
                                    </div>
                                </div>
                                <button id={"id_submit_withdraw"} type={"submit"}
                                        className="button sm secondary filled mt-3 w-100">
                                    <p className="text-small text-bold text-center">Para Çek</p>
                                </button>
                            </form>
                        </div>}


                </div>
                <div>
                    <div>
                        <p className="text-md text-bold  mb-3 mt-3">Para Çekim Talepleri</p>
                        {
                            withdrawList && withdrawList.length > 0 ? <div className="mb-4">
                                {
                                    withdrawList.map((item: any, index: any) => {
                                        return <>
                                            {(index === 0 || item.create_date.split('T')[0] !== withdrawList[index - 1].create_date.split('T')[0]) &&
                                                <div className="row mb-2">
                                                    <p className="text-small text-bold">{dateFormat(item.create_date)}</p>
                                                </div>
                                            }
                                            <div className="withdraw-item">
                                                <div className="row ">
                                                    <div className={"col-2"}>
                                                        <p className="text-xsmall text-center withdraw-badge">Beklemede</p>
                                                    </div>

                                                </div>
                                                <div className="row ">
                                                    <div className="col-6">
                                                        <p className="text-xxsmall  mt-2 mb-2 withdraw-list-text">Banka</p>
                                                        <p className="text-xsmall fw-medium">{item.account.id}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="text-xxsmall  mt-2 mb-2  withdraw-list-text">Tutar</p>
                                                        <p className="text-xsmall fw-medium">{item.amount}₺</p>
                                                    </div>
                                                    {/*<div className="col-4 cancel-withdraw">*/}
                                                    {/*    <button className="button primary filled  ">*/}
                                                    {/*        <p className="text-xsmall text-white fw-medium">İptal Et</p>*/}
                                                    {/*    </button>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </>
                                    })
                                }

                            </div> : <div className="not-found-list">
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65"
                                     fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M32 3.16675C15.7996 3.16675 2.66663 16.2997 2.66663 32.5001C2.66663 48.7004 15.7996 61.8334 32 61.8334C48.2003 61.8334 61.3333 48.7004 61.3333 32.5001C61.3333 16.2997 48.2003 3.16675 32 3.16675Z"
                                          fill="#E8E6D0"></path>
                                    <path
                                        d="M32.0007 19.1667C30.5279 19.1667 29.334 20.3607 29.334 21.8334C29.334 23.3062 30.5279 24.5001 32.0007 24.5001H32.0273C33.5001 24.5001 34.694 23.3062 34.694 21.8334C34.694 20.3607 33.5001 19.1667 32.0273 19.1667H32.0007Z"
                                        fill="#004742"></path>
                                    <path
                                        d="M34.6673 32.5001C34.6673 31.0273 33.4734 29.8334 32.0007 29.8334C30.5279 29.8334 29.334 31.0273 29.334 32.5001V43.1667C29.334 44.6395 30.5279 45.8334 32.0007 45.8334C33.4734 45.8334 34.6673 44.6395 34.6673 43.1667V32.5001Z"
                                        fill="#004742"></path>
                                </svg>
                                <p className="text-small text-primary text-bold text-center">Para
                                    çekim işleminiz bulunmamaktadır.</p>
                            </div>
                        }


                    </div>
                </div>
            </div>

            <AddBankAccountModal showAddBankAccountModal={showAddBankAccountModal}
                                 setShowAddBankAccountModal={setShowAddBankAccountModal}
                                 getListAccount={getListAccount}/>

        </div>
    );
}

//
// <div className="modal_modalWrapper__aQC8O modal-wrapper" style="max-width: 472px;">
//     <div className="modal_modalHeader__WOZTX modal-header"><h3
//         className="text lg white ultra-bold center w-100 none   lh-normal" ></h3>
//         <svg width="24" height="24" viewBox="0 0 24 24" fill="#353535" xmlns="http://www.w3.org/2000/svg">
//             <path d="M18 6L6 18M6 6L18 18" stroke="#353535" strokeWidth="2" stroke-linecap="round"
//                   stroke-linejoin="round"></path>
//         </svg>
//     </div>
//     <div className="modal_modalBody__h3CJH modal-body ">
//         <div>
//             <div className="flex justify-center mb-2">
//                 <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
//                     <path
//                         d="M39.9993 53.3333V40M39.9993 26.6666H40.0327M73.3327 40C73.3327 58.4095 58.4088 73.3333 39.9993 73.3333C21.5899 73.3333 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66663 39.9993 6.66663C58.4088 6.66663 73.3327 21.5905 73.3327 40Z"
//                         stroke="rgba(255, 135, 0, 1)" strokeWidth="4" stroke-linecap="round"
//                         stroke-linejoin="round"></path>
//                 </svg>
//             </div>
//             <p className="text lg default ultra-bold center mb-2 none   lh-normal" >Para Çekme
//                 Talep İptal</p><p className="text sm default normal center  none   lh-normal"
//                                   >Para çekme talebiniz iptal edilecektir, emin misiniz?</p>
//             <button className="button sm secondary filled mt-3 w-100 " data-testid="custom-button"><p
//                 className="text sm default bold center  none   lh-normal" >Evet</p></button>
//             <button className="button sm tertiary outlined mt-3 w-100 " data-testid="custom-button"><p
//                 className="text sm default bold center  none   lh-normal" >Vazgeç</p></button>
//         </div>
//     </div>
// </div>


// <div className="modal_modalWrapper__aQC8O modal-wrapper" style="max-width: 472px;">
//     <div className="modal_modalHeader__WOZTX modal-header"><h3
//         className="text lg white ultra-bold center w-100 none   lh-normal" ></h3>
//         <svg width="24" height="24" viewBox="0 0 24 24" fill="#353535" xmlns="http://www.w3.org/2000/svg">
//             <path d="M18 6L6 18M6 6L18 18" stroke="#353535" strokeWidth="2" stroke-linecap="round"
//                   stroke-linejoin="round"></path>
//         </svg>
//     </div>
//     <div className="modal_modalBody__h3CJH modal-body ">
//         <p className="text xs default normal center  none   lh-normal" >
//             <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
//                 <path
//                     d="M49.9993 30L29.9993 50M29.9993 30L49.9993 50M73.3327 40C73.3327 58.4095 58.4088 73.3334 39.9993 73.3334C21.5899 73.3334 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66669 39.9993 6.66669C58.4088 6.66669 73.3327 21.5905 73.3327 40Z"
//                     stroke="#E04C4C" strokeWidth="4" stroke-linecap="round" stroke-linejoin="round"></path>
//             </svg>
//         </p>
//         <div style="height: 16px;"></div>
//         <p className="text lg default ultra-bold center  none   lh-normal" >Hata Oluştu.</p>
//         <div style="height: 16px;"></div>
//         <p className="text sm default normal center  none   lh-xs" >Para çekme işlemi iptal
//             edilemez.</p>
//         <div style="height: 24px;"></div>
//         <button className="button xs secondary filled w-100 " data-testid="custom-button">Tamam</button>
//     </div>
// </div>


//
// .modal_modalOverlay__Xo9D5 {
//     background: rgba(0,0,0,.75);
//     width: 100%;
//     margin: 0;
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     height: 100vh;
//     z-index: 99
// }
//
// .modal_displayNone__0__GD {
//     display: none
// }
//
// .modal_modalWrapper__aQC8O {
//     background-color: #fff;
//     margin: 0;
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     border-radius: 12px;
//     z-index: 100;
//     width: 100%
// }
//
// .modal_modalWrapper__aQC8O .modal_modalHeader__WOZTX {
//     display: flex;
//     justify-content: space-between;
//     padding: 20px 18px;
//     border-radius: 10px 10px 0 0
// }
//
// .modal_modalWrapper__aQC8O .modal_modalHeader__WOZTX svg {
//     cursor: pointer
// }
//
// .modal_modalWrapper__aQC8O .modal_greenHeader__I8W7O {
//     background-color: var(--primary-color);
//     margin-top: -1px
// }
//
// .modal_modalWrapper__aQC8O .modal_modalBody__h3CJH {
//     padding: 0 24px 24px;
//     overflow: auto;
//     max-height: calc(100vh - 160px);
//     overflow: scroll;
//     width: 100%
// }
//
// .modal_modalWrapper__aQC8O .modal_modalFooter__sDf1s {
//     padding: 0 24px
// }
