import "./member.css";
import React, {useEffect, useState} from "react";
import {CurrencyFormat, dateFormat, dateTimeFormat, ServerDate} from "../../services/misc.functions";
import _ from "lodash";
import {env, transTypes} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {auth} from "../../store/auth.store";
import DatePicker from "react-datepicker";

export default function MemberTransactions() {
    const today = ServerDate();

    const [loading, setLoading] = useState(true);
    const [dataList, setDataList] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [transType, setTransType] = useState<any>(null);

    // const [filter, setFilter] = useState<any>({
    //     start_date: today.toISOString().slice(0, 10),
    //     end_date: today.toISOString().slice(0, 10),
    //     trans_type: null,
    // });
    const [openTypeFilter, setOpenTypeFilter] = useState<any>(false);


    useEffect(() => {
        loadData();
    }, []);


    const loadData = async (force: any = null) => {
        const api = new ApiService();

        setLoading(true);
        console.log(force);
        console.log(page);
        if (page === 0 || force === 0) {
            console.log('here');
            await setDataList([]);
            await setPage(0);
            console.log(page);
        }

        let payload: any = {
            metadata: {
                page: force != null ? force : page,
            },
            start_date: startDate ? startDate.toISOString().slice(0, 10) : '',
            end_date: endDate ? endDate.toISOString().slice(0, 10) : '',
            trans_type: transType,
        };

        return await api
            .start("post", env.api + "/member/api/list-transaction/", payload, true)
            .then((res: any) => {
                setLoading(false);
                setPage(page + 1);

                if (res && res.status) {
                    setDataList([...dataList, ...res.data]);
                }

                if (res && res.metadata) {
                    let id_datalist_loadMore: any = document.getElementById("id_datalist_loadMore");

                    // if (res.metadata.total_page <= page + 1) {
                    //     id_datalist_loadMore.style.display = "none";
                    // } else {
                    //     id_datalist_loadMore.style.display = "block";
                    // }
                }
            });
    };


    const loadMore = (e: any) => {
        e.target.disabled = true;
        loadData().finally(() => {
            e.target.disabled = false;
        });
    };

    let lastBalanceTRY: number = Number(auth.member.balance_debit);
    let lastBalancePoint: number = Number(auth.member.balance_point);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start != null && end != null) {
            let payload: any = {
                metadata: {
                    page: 0,
                },
                start_date: start ? start.toISOString().slice(0, 10) : '',
                end_date: end ? end.toISOString().slice(0, 10) : '',
                trans_type: transType,
            };
            const api = new ApiService();
             api
                .start("post", env.api + "/member/api/list-transaction/", payload, true)
                .then((res: any) => {
                    setLoading(false);
                    setPage(page + 1);

                    if (res && res.status) {
                        setDataList(res.data);
                    }

                    if (res && res.metadata) {
                        let id_datalist_loadMore: any = document.getElementById("id_datalist_loadMore");

                        // if (res.metadata.total_page <= page + 1) {
                        //     id_datalist_loadMore.style.display = "none";
                        // } else {
                        //     id_datalist_loadMore.style.display = "block";
                        // }
                    }
                });
        }
    };

    return (
        <div className="row ">
            <div className="column p-0">
                <div className="transaction-filter-wrapper">
                    <div className="transactions-balance">
                        <div className="transactions-balance-debit">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="16" fill="#FFE700"></rect>
                                <path
                                    d="M14.9298 8.53896V23.5L13.1181 23.6169V8.53896H14.9298ZM19.0207 16.9545H20.8324C20.8324 18.4935 20.5864 19.7622 20.0946 20.7606C19.6075 21.7541 18.9184 22.4943 18.0272 22.9813C17.1408 23.4635 16.0986 23.7045 14.9006 23.7045C14.5938 23.7045 14.2699 23.6972 13.929 23.6826C13.5929 23.668 13.3226 23.6461 13.1181 23.6169L14.9006 22.0097C15.6603 22.0097 16.3519 21.8563 16.9752 21.5495C17.5986 21.2427 18.0954 20.7216 18.4655 19.9862C18.8356 19.2508 19.0207 18.2403 19.0207 16.9545ZM18.0564 9.67857V11.4903L10.9265 14.763V12.9513L18.0564 9.67857ZM18.0564 13.0974V14.9091L10.9265 18.1818V16.3701L18.0564 13.0974Z"
                                    fill="#353535"></path>
                            </svg>
                            <div className="me-2 ms-2">
                                <p className="text-xsmall text-white me-2">Bakiye</p>
                                <p className="text-md text-white text-bold me-2">{auth.member.balance_debit}₺</p>
                            </div>
                        </div>
                        <div className="transactions-balance-point">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="16" fill="#FFE700"></rect>
                                <path
                                    d="M8.06658 21V10.8182H14.9274V12.593H10.2193V15.0192H14.5744V16.794H10.2193V19.2251H14.9473V21H8.06658ZM16.6388 21V10.8182H20.6559C21.4281 10.8182 22.0861 10.9657 22.6296 11.2607C23.1732 11.5523 23.5875 11.9583 23.8725 12.4787C24.1609 12.9957 24.305 13.5923 24.305 14.2685C24.305 14.9446 24.1592 15.5412 23.8675 16.0582C23.5759 16.5753 23.1533 16.978 22.5998 17.2663C22.0496 17.5547 21.3834 17.6989 20.6012 17.6989H18.0408V15.9737H20.2532C20.6675 15.9737 21.0089 15.9025 21.2773 15.7599C21.5491 15.6141 21.7513 15.4136 21.8839 15.1584C22.0198 14.8999 22.0877 14.6032 22.0877 14.2685C22.0877 13.9304 22.0198 13.6354 21.8839 13.3835C21.7513 13.1283 21.5491 12.9311 21.2773 12.7919C21.0056 12.6494 20.6609 12.5781 20.2433 12.5781H18.7915V21H16.6388Z"
                                    fill="#353535"></path>
                            </svg>
                            <div className="ms-2">
                                <p className="text-xsmall text-white">Puan Bakiye</p>
                                <p className="text-md text-white text-bold">{auth.member.balance_point}₺</p></div>
                        </div>
                    </div>
                    <div className="transaction-filter">
                        <div className="transaction-filter-dropdown">
                            <button className="transaction-filter-dropdown-toggle" onClick={() => {
                                setOpenTypeFilter(true)
                            }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    cursor: "pointer",
                                    width: "100%"
                                }}>
                                    <div className="">
                                        <p className="text-small text-white transaction-filter-dropdown-title">İşlem
                                            Tipi</p>
                                        <p className="text-small text-white transaction-filter-dropdown-value">{transType ? transTypes[transType] : 'Tümü'}</p>
                                    </div>
                                    <div className="transaction-dropdown-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 6L8 10L12 6" stroke="#fff" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                </div>
                            </button>
                            {
                                openTypeFilter && <>
                                    <div className="transaction-dropdown-outer" onClick={() => {
                                        setOpenTypeFilter(false);
                                        loadData(0);
                                    }}></div>
                                    <div className="transaction-dropdown-menu">
                                        <button onClick={() => {
                                            setTransType(null);
                                            setOpenTypeFilter(false);
                                            loadData(0);
                                        }} className="transaction-dropdown-menu-item">
                                            <div>
                                                <p className="text-xsmall">Tümü</p>
                                            </div>
                                        </button>
                                        <button onClick={() => {
                                            setTransType(3);
                                            setOpenTypeFilter(false);
                                            loadData(0);
                                        }} className="transaction-dropdown-menu-item">
                                            <div>
                                                <p className="text-xsmall">Para Çekme</p>
                                            </div>
                                        </button>
                                        <button onClick={() => {
                                            setTransType(1);
                                            setOpenTypeFilter(false);
                                            loadData(0);
                                        }} className="transaction-dropdown-menu-item">
                                            <div>
                                                <p className="text-xsmall">Para Yatırma</p>
                                            </div>
                                        </button>
                                        <button onClick={() => {
                                            setTransType(4);
                                            setOpenTypeFilter(false);
                                            loadData(0);
                                        }} className="transaction-dropdown-menu-item">
                                            <div>
                                                <p className="text-xsmall">TJK kupon oynama</p>
                                            </div>
                                        </button>
                                        <button onClick={() => {

                                            setTransType(46);
                                            setOpenTypeFilter(false);
                                            loadData(0);
                                        }} className="transaction-dropdown-menu-item">
                                            <div>
                                                <p className="text-xsmall">TJK Kupon Kazanç</p>
                                            </div>
                                        </button>
                                        <button onClick={() => {
                                            //
                                            setTransType(46);
                                            setOpenTypeFilter(false);
                                            loadData(0);
                                        }} className="transaction-dropdown-menu-item">
                                            <div>
                                                <p className="text-xsmall">TJK Kupon İptal</p>
                                            </div>
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="transaction-date-filter">
                            <div className=" ">
                                <div className="date-picker-button">
                                    <div className="transaction-filter-dropdown-toggle">
                                        <div className="">

                                            {/*<DatePicker*/}
                                            {/*    id={startDateId}*/}
                                            {/*    selected={startDate}*/}
                                            {/*    selectsRange*/}
                                            {/*    startDate={startDate}*/}
                                            {/*    endDate={endDate}*/}
                                            {/*    placeholderText={placeholder}*/}
                                            {/*    dateFormat={displayFormat}*/}
                                            {/*    onChange={onChange}*/}
                                            {/*    locale={selectLocale(locale)}*/}
                                            {/*    customInput={<CustomInput />}*/}
                                            {/*/>*/}
                                            {/*<DatePicker className={"date-input"}*/}
                                            {/*            dateFormat={"dd/MM/yyyy"}*/}
                                            {/*            // selected={filter.start_date}*/}
                                            {/*            locale={'tr'}*/}
                                            {/*            endDate={filter.end_date}*/}
                                            {/*            selectsRange*/}
                                            {/*            inline*/}
                                            {/*            customInput={<div><p*/}
                                            {/*                className="text-small text-white transaction-filter-dropdown-title">Tarih*/}
                                            {/*                Aralığı</p>*/}
                                            {/*                <p className="text-small text-white transaction-filter-dropdown-value">01.07.2024*/}
                                            {/*                    - 08.07.2024</p></div>}*/}
                                            {/*            onChange={(date: any) => {*/}
                                            {/*                console.log(date);*/}
                                            {/*            }}/>*/}
                                            <DatePicker
                                                selected={startDate}
                                                onChange={onChange}
                                                locale={'tr'}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                customInput={<div>
                                                    <p
                                                        className="text-small text-white transaction-filter-dropdown-title">Tarih
                                                        Aralığı</p>
                                                    <p className="text-small text-white transaction-filter-dropdown-value">{startDate ? dateFormat(startDate.toISOString()) : '-'}
                                                        - {endDate ? dateFormat(endDate.toISOString()) : '-'}</p>
                                                </div>}

                                            />
                                        </div>
                                        <div className="transaction-dropdown-icon">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 6L8 10L12 6" stroke="#fff" strokeWidth="1.5"
                                                      strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    dataList.length > 0 ?

                        <div>
                            <table className="transaction-table">
                                <thead>
                                <tr>
                                    <th>İşlem Tipi</th>
                                    <th className={"text-center"}>Tutar</th>
                                    <th>Kalan Bakiye</th>
                                </tr>
                                </thead>
                                <tbody>


                                {_.orderBy(dataList, ["id"], ["desc"]).map((item: any, key: number) => {
                                    if (key === 0) {
                                        lastBalanceTRY = Number(auth.member.balance_debit);
                                        lastBalancePoint = Number(auth.member.balance_point);
                                    } else {
                                        if (dataList[key - 1].io_type === 0) {
                                            lastBalanceTRY += parseFloat(dataList[key - 1].amount);
                                        } else {
                                            lastBalanceTRY -= parseFloat(dataList[key - 1].amount);
                                        }
                                    }
                                    return (
                                        <React.Fragment key={`transaction-${key}`}>
                                            <tr>
                                                <td>
                                                    <p className="text-xsmall text-bold">{item.trans_type_display}</p>
                                                    <p className="text-xxsmall ">{dateTimeFormat(item.transaction_date)}</p>
                                                </td>
                                                <td>
                                                    <p className={`text-xsmall total-amount ${item.io_type === 1 ? 'success' : 'red'}`}>
                                                        {CurrencyFormat(item.amount)} {item.unit === 1 ? "EP" : "TL"}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="text-xsmall text-bold"> {CurrencyFormat(lastBalanceTRY)}₺</p>
                                                    <p className="text-xsmall text-bold"> {CurrencyFormat(lastBalancePoint)}₺</p>

                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}


                                </tbody>
                            </table>
                            {/*<div className="Transactions_pagination__LJjSB">
                        <ul role="navigation" aria-label="Pagination">
                            <li className="Transactions_prev__lvabF Transactions_disabledPageButtons__Jh_Nq"><a
                                className=" " tabIndex="-1" role="button" aria-disabled="true"
                                aria-label="Previous page">
                                <div className="flex">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 6H9.5M9.5 6L6 2.5M9.5 6L6 9.5" stroke="#353535"
                                              strokeWidth="1.66" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    <p> Geri</p></div>
                            </a></li>
                            <li className="Transactions_page__e8m_c selected"><a rel="canonical" role="button"
                                                                                 className="Transactions_selected__hJV7c"
                                                                                 tabIndex="-1"
                                                                                 aria-label="Page 1 is your current page"
                                                                                 aria-current="page">1</a></li>
                            <li className="Transactions_next__tDN7B Transactions_disabledPageButtons__Jh_Nq"><a
                                className=" " tabIndex="-1" role="button" aria-disabled="true" aria-label="Next page"
                                rel="next">
                                <div className="flex"><p> İleri</p>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.5 6H9.5M9.5 6L6 2.5M9.5 6L6 9.5" stroke="#353535"
                                              strokeWidth="1.66" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </div>
                            </a></li>
                        </ul>
                    </div>*/}
                        </div>
                        :
                        <div style={{marginBottom: "150px", marginTop: "150px"}}>
                            <div className="d-flex mt-5" style={{justifyContent: "center"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"
                                     fill="none">
                                    <path
                                        d="M39.9993 53.3333V40M39.9993 26.6666H40.0327M73.3327 40C73.3327 58.4095 58.4088 73.3333 39.9993 73.3333C21.5899 73.3333 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66663 39.9993 6.66663C58.4088 6.66663 73.3327 21.5905 73.3327 40Z"
                                        stroke="#929191" strokeWidth="4" strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                </svg>
                            </div>
                            <p className="text-xl  text-bolder text-center mb-1">Veri Bulunamadı</p>
                            <p className="text-small text-center mb-5">Belirlenen tarih aralığından hesap hareketi
                                bulunamamıştır.</p>
                        </div>
                }
            </div>
        </div>
    );
}
