import React, {useEffect, useState} from "react";
import {exceptionModal} from "../../services/misc.functions";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {toast} from "react-toastify";
import AddBankAccountModal from "../../shared/bankAccount/add.bank.account.modal";
import {modalServiceSubject} from "../../services/modal.service";

export function BankAccounts() {
    const [accountList, setAccountList] = useState<any>([]);
    const [selectedAccount, setSelectedAccount] = useState<any>(null);
    const [showAddBankAccountModal, setShowAddBankAccountModal] = useState<any>(false);

    useEffect(() => {
        getListAccount();
    }, []);

    const getListAccount = () => {
        const api = new ApiService();
        api
            .start("get", env.api + "/member/api/list-account/", null, true)
            .then((res: any) => {
                // console.log(res)
                if (res && res.status) {
                    setAccountList(res.data);
                    setSelectedAccount(res.data[0]?.id);
                } else {
                    setAccountList([]);
                }
            })
            .catch((e: any) => {
                exceptionModal(e);
                setAccountList([]);
            });
    };

    const deleteAccount = (accountID: any) => {
        modalServiceSubject.next({
            title: '',
            width: '472px',
            innerHtml: true,
            content: `
                <div>
                    <div class="mb-3 deleteBankIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 16" fill="none">
                            <path
                                d="M3.83329 5.33325V11.9999C3.83329 13.4727 5.0272 14.6666 6.49996 14.6666H10.5C11.9727 14.6666 13.1666 13.4727 13.1666 11.9999V5.33325M9.83329 7.33325V11.3333M7.16663 7.33325L7.16663 11.3333M11.1666 3.33325L10.2291 1.92698C9.98183 1.55605 9.56552 1.33325 9.11971 1.33325H7.88021C7.4344 1.33325 7.01809 1.55605 6.7708 1.92698L5.83329 3.33325M11.1666 3.33325H5.83329M11.1666 3.33325H14.5M5.83329 3.33325H2.49996"
                                stroke="#E04C4C" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                    <p class="text-lg text-bold text-center mb-3">Hesabı Sil</p>
                    <p class="text-md text-center mb-4">Banka hesap bilginizi silmek istediğinize emin misiniz?</p>
                    
                </div>
            `,
            confirm: {
                cancel: {
                    label: 'Kapat',
                },
                sure: {
                    label: 'Tamam',
                    action: () => {
                        const api = new ApiService();
                        api
                            .start(
                                "post",
                                env.api + "/member/api/create-member-account/",
                                {
                                    id: accountID,
                                    delete: true,
                                },
                                true
                            )
                            .then((res: any) => {
                                // console.log(res)
                                if (res && res.status) {
                                    toast.success("Hesap başarıyla silindi.");
                                    accountList.splice(
                                        accountList.findIndex((account: any) => account.id === accountID),
                                        1
                                    );
                                    setAccountList([...accountList]);
                                }
                            });
                    }
                }
            }
        })


    };

    return (
        <div className="row ">
            <div className="col p-0">
                <p className="text-md text-bold mb-3">Banka Hesaplarım</p>
                {accountList && accountList.length === 0 ?
                    <div className="withdraw-add-bank-account-wrapper">
                        <div className="withdraw-bank-icon">
                            <svg width="70" height="70" viewBox="0 0 70 70" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="70" height="70" rx="35" fill="#E8E6D0"></rect>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M22.5 46.25C22.5 45.5596 23.0596 45 23.75 45H46.25C46.9404 45 47.5 45.5596 47.5 46.25C47.5 46.9404 46.9404 47.5 46.25 47.5H23.75C23.0596 47.5 22.5 46.9404 22.5 46.25Z"
                                      fill="#004742"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M27.5 31.25C28.1904 31.25 28.75 31.8096 28.75 32.5V42.5C28.75 43.1904 28.1904 43.75 27.5 43.75C26.8096 43.75 26.25 43.1904 26.25 42.5V32.5C26.25 31.8096 26.8096 31.25 27.5 31.25Z"
                                      fill="#004742"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M32.5 31.25C33.1904 31.25 33.75 31.8096 33.75 32.5V42.5C33.75 43.1904 33.1904 43.75 32.5 43.75C31.8096 43.75 31.25 43.1904 31.25 42.5V32.5C31.25 31.8096 31.8096 31.25 32.5 31.25Z"
                                      fill="#004742"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M37.5 31.25C38.1904 31.25 38.75 31.8096 38.75 32.5V42.5C38.75 43.1904 38.1904 43.75 37.5 43.75C36.8096 43.75 36.25 43.1904 36.25 42.5V32.5C36.25 31.8096 36.8096 31.25 37.5 31.25Z"
                                      fill="#004742"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M42.5 31.25C43.1904 31.25 43.75 31.8096 43.75 32.5V42.5C43.75 43.1904 43.1904 43.75 42.5 43.75C41.8096 43.75 41.25 43.1904 41.25 42.5V32.5C41.25 31.8096 41.8096 31.25 42.5 31.25Z"
                                      fill="#004742"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M34.5845 21.4086C34.858 21.3465 35.142 21.3465 35.4155 21.4086C35.7403 21.4824 36.0202 21.6613 36.1552 21.7476C36.1692 21.7565 36.1817 21.7645 36.1925 21.7713L45.6625 27.69C46.1354 27.9855 46.3553 28.5586 46.2016 29.0946C46.0478 29.6306 45.5576 30 45 30H25C24.4424 30 23.9522 29.6306 23.7984 29.0946C23.6447 28.5586 23.8646 27.9855 24.3375 27.69L33.8075 21.7713C33.8183 21.7645 33.8308 21.7565 33.8448 21.7476C33.9798 21.6613 34.2597 21.4824 34.5845 21.4086Z"
                                      fill="#004742"></path>
                            </svg>
                        </div>
                        <p className="text-md text-bold text-center mb-3">Kayıtlı banka hesabınız yok</p>
                        <p className="text-small text-center mb-4"> Hesabınızı kaydederek bakiyenizdeki
                            belirlediğiniz
                            miktar parayı banka hesabınıza aktarabilirsiniz.</p>
                        <button className="button xs secondary filled add-new-bank-account-btn" onClick={() => {
                            setShowAddBankAccountModal(true)
                        }}>
                            <div className="add-new-bank-account-btn-icon">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M7.99996 0.666656C3.94987 0.666656 0.666626 3.9499 0.666626 7.99999C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 7.99999C15.3333 3.9499 12.05 0.666656 7.99996 0.666656ZM7.99996 4.66666C8.36815 4.66666 8.66663 4.96513 8.66663 5.33332V7.33332H10.6666C11.0348 7.33332 11.3333 7.6318 11.3333 7.99999C11.3333 8.36818 11.0348 8.66666 10.6666 8.66666H8.66663V10.6667C8.66663 11.0348 8.36815 11.3333 7.99996 11.3333C7.63177 11.3333 7.33329 11.0348 7.33329 10.6667V8.66666H5.33329C4.9651 8.66666 4.66663 8.36818 4.66663 7.99999C4.66663 7.6318 4.9651 7.33332 5.33329 7.33332H7.33329V5.33332C7.33329 4.96513 7.63177 4.66666 7.99996 4.66666Z"
                                          fill="#353535"></path>
                                </svg>
                                <p className="text-xsmall  text-bold"> Banka Hesabı Ekle</p></div>
                        </button>
                    </div> :
                    <>
                        {accountList?.map((account: any, index: any) => {
                            return <div className="row bank-account-list" key={"accountList-"+index}>
                                <div className="col-2 list-bank-code">
                                    <img alt=""
                                         loading="lazy"
                                         width="90"
                                         height="25"
                                         decoding="async"
                                         src={env.media + account.bank_logo}
                                    />
                                </div>
                                <div className="col-8 list-iban">
                                    <p className="text-small mb-2 me-4 bank-account-long-text">{account.bank_name}</p>
                                    <p className="text-xsmall me-4 iban-color">{account.iban}</p>
                                </div>
                                <div className=" col-2 list-bank-code">
                                    <div className="edit-bank">
                                        {index === 0 &&
                                            <p className="text-xxsmall text-primary default-bank">Varsayılan</p>}
                                        <button onClick={() => {
                                            deleteAccount(account.id);
                                        }} className="button xs tertiary filled ms-2 ">
                                            <div className="d-flex" style={{margin: "auto"}}>
                                                <p className="text-xsmall text-primary me-2"> Sil</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                                                     viewBox="0 0 16 17"
                                                     fill="none">
                                                    <path
                                                        d="M2 14.5H14M9.18961 4.04114C9.18961 4.04114 9.18961 5.13089 10.2794 6.22064C11.3691 7.31039 12.4589 7.31039 12.4589 7.31039M4.87975 12.492L7.16823 12.1651C7.49833 12.118 7.80424 11.965 8.04003 11.7292L13.5486 6.22064C14.1505 5.61879 14.1505 4.64299 13.5486 4.04114L12.4589 2.95139C11.857 2.34954 10.8812 2.34954 10.2794 2.95139L4.77078 8.45997C4.53499 8.69576 4.38203 9.00167 4.33488 9.33177L4.00795 11.6202C3.9353 12.1288 4.3712 12.5647 4.87975 12.492Z"
                                                        stroke="#004742" strokeLinecap="round">
                                                    </path>
                                                </svg>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        })}
                        <div className="row ">
                            <button onClick={() => setShowAddBankAccountModal(true)}
                                    className="button xs secondary filled add-bank-account-btn ">
                                <div className="bank-accounts-icon-btn">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M7.99996 0.666656C3.94987 0.666656 0.666626 3.9499 0.666626 7.99999C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 7.99999C15.3333 3.9499 12.05 0.666656 7.99996 0.666656ZM7.99996 4.66666C8.36815 4.66666 8.66663 4.96513 8.66663 5.33332V7.33332H10.6666C11.0348 7.33332 11.3333 7.6318 11.3333 7.99999C11.3333 8.36818 11.0348 8.66666 10.6666 8.66666H8.66663V10.6667C8.66663 11.0348 8.36815 11.3333 7.99996 11.3333C7.63177 11.3333 7.33329 11.0348 7.33329 10.6667V8.66666H5.33329C4.9651 8.66666 4.66663 8.36818 4.66663 7.99999C4.66663 7.6318 4.9651 7.33332 5.33329 7.33332H7.33329V5.33332C7.33329 4.96513 7.63177 4.66666 7.99996 4.66666Z"
                                              fill="#353535">
                                        </path>
                                    </svg>
                                    <p className="text-xsmall text-bold"> Banka Hesabı Ekle</p>
                                </div>
                            </button>
                        </div>
                    </>}
            </div>


            <AddBankAccountModal showAddBankAccountModal={showAddBankAccountModal}
                                 setShowAddBankAccountModal={setShowAddBankAccountModal}
                                 getListAccount={getListAccount}/>


        </div>
    );
}
