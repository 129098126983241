import {useNavigate} from "react-router-dom";
import paths from "../../../paths";
import {userLogout} from "../../../services/auth.service";
import {useEffect, useState} from "react";

export function FlatPageMenu() {
    const navigate = useNavigate();
    const pathname = window.location.pathname;

    return (
        <div className="member-menu-wrapper">
            <p
                className="text-md text-bold mb-2 ps-2">Hakkımızda</p>
            <button
                onClick={() => {
                    navigate(paths.flatpage.url + '/hakkimizda');
                }}
                className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/hakkimizda' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Hakkımızda</p>
            </button>
            <button
                onClick={() => {
                    navigate(paths.flatpage.url + '/kullanim-sartlari');
                }}
                className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/kullanim-sartlari' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Kullanım Şartları</p>
            </button>

            <button onClick={() => {
                navigate(paths.flatpage.url + '/kvkk');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/kvkk' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">KVKK Politikası</p>
            </button>

            <button onClick={() => {
                navigate(paths.flatpage.url + '/saklama-ve-imha-politikasi');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/saklama-ve-imha-politikasi' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Saklama ve İmha Politikası</p>
            </button>
            <div style={{height: "32px"}}>
            </div>
            <p className="text-md text-bold mb-2 ps-2">Aydınlatma Metni</p>
            <button onClick={() => {
                navigate(paths.agreement.url + '/aydinlatma');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.agreement.url + '/aydinlatma' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Aydınlatma Metni</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/uye-aydinlatma-metni');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/uye-aydinlatma-metni' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Üye</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/sanal-ziyaretci');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/sanal-ziyaretci' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Sanal Ziyaretçi</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/is-ortaklari');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/is-ortaklari' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">İş Ortakları</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/calisan-ve-stajyer-adayi');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/calisan-ve-stajyer-adayi' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Çalışan, Çalışan Adayı, Stajyer, Stajyer Adayı</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/sirket-yetkilisi');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/sirket-yetkilisi' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Şirket Yetkilisi - Yöneticisi</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/sirket-hissedari');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/sirket-hissedari' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Şirket Hissedarı</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/fiziki-ziyaretci');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/fiziki-ziyaretci' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Fiziki Ziyaretçi</p>
            </button>
            <div style={{height: "32px"}}>
            </div>
            <p className="text-md text-bold mb-2 ps-2">Diğer</p>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/aydinlatma');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/aydinlatma' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Açık Rıza Metni</p>
            </button>

            <button onClick={() => {
                navigate(paths.flatpage.url + '/kisisel-veri-basvurulari');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/kisisel-veri-basvurulari' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Kişisel Veri Başvuruları</p>
            </button>
            <button onClick={() => {
                navigate(paths.agreement.url + '/ticari-elektronik-ileti-aydinlatma-metni');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.agreement.url + '/ticari-elektronik-ileti-aydinlatma-metni' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Ticari Elektronik İleti Aydınlatma Metni</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/cerez-politikasi');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/cerez-politikasi' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Çerez Politikası</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/sorumlu-bahis');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/sorumlu-bahis' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Sorumlu Bahis</p>
            </button>
            <button onClick={() => {
                navigate(paths.flatpage.url + '/iletisim');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.flatpage.url + '/iletisim' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">İletişim</p>
            </button>

        </div>
    );
}

export function FlatPageMobileMenu() {
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const mobileMenuGroup: any = [
        {
            "name": "Hakkımızda",
            "path": paths.flatpage.url + '/hakkimizda'
        },
        {
            "name": "Kullanım Şartları",
            "path": paths.flatpage.url + '/kullanim-sartlari'
        },
        {
            "name": "KVKK Politikası",
            "path": paths.flatpage.url + '/kvkk'
        },
        {
            "name": "Saklama ve İmha Politikası",
            "path": paths.flatpage.url + '/saklama-ve-imha-politikasi'
        },
        {
            "name": "Aydınlatma Metni",
            "path": paths.agreement.url + '/aydinlatma'
        },
        {
            "name": "Üye",
            "path": paths.flatpage.url + '/uye-aydinlatma-metni'
        },
        {
            "name": "Sanal Ziyaretçi",
            "path": paths.flatpage.url + '/sanal-ziyaretci'
        },
        {
            "name": "İş Ortakları",
            "path": paths.flatpage.url + '/is-ortaklari'
        },
        {
            "name": "Çalışan, Çalışan Adayı, Stajyer, Stajyer Adayı",
            "path": paths.flatpage.url + '/calisan-ve-stajyer-adayi'
        },
        {
            "name": "Şirket Yetkilisi - Yöneticisi",
            "path": paths.flatpage.url + '/sirket-yetkilisi'
        },
        {
            "name": "Şirket Hissedarı",
            "path": paths.flatpage.url + '/sirket-hissedari'
        },
        {
            "name": "Fiziki Ziyaretçi",
            "path": paths.flatpage.url + '/fiziki-ziyaretci'
        },
        {
            "name": "Açık Rıza Metni",
            "path": paths.flatpage.url + '/aydinlatma'
        },
        {
            "name": "Kişisel Veri Başvuruları",
            "path": paths.flatpage.url + '/kisisel-veri-basvurulari'
        },
        {
            "name": "Ticari Elektronik İleti Aydınlatma Metni",
            "path": paths.agreement.url + '/ticari-elektronik-ileti-aydinlatma-metni'
        },
        {
            "name": "Çerez Politikası",
            "path": paths.flatpage.url + '/cerez-politikasi'
        },
        {
            "name": "Sorumlu Bahis",
            "path": paths.flatpage.url + '/sorumlu-bahis'
        },
        {
            "name": "İletişim",
            "path": paths.flatpage.url + '/iletisim'
        }
    ];


    useEffect(() => {
        console.log(mobileMenuGroup.filter((el: any) => el.path === pathname)[0]);
        setSelectedItem(mobileMenuGroup.filter((el: any) => el.path === pathname)[0]);
    }, []);


    return <div className="member-mobile-menu-wrapper">
        <div className="container">
            <p className="text-lg text-white text-bold  pt-3 pb-3">{selectedItem?.name}</p>

        </div>
        {selectedItem && <div className="member-mobile-menu-inner-wrapper">
            {
                mobileMenuGroup.map((item: any, index: any) => {
                    return <div key={"mobile-user-menu" + index}
                                className={`member-mobile-menu-options ${item.path === pathname ? 'member-mobile-menu-active' : ''}`}
                                onClick={() => {
                                    navigate(item.path);
                                    setSelectedItem(item);
                                }}>
                        {item.name}
                    </div>
                })
            }


        </div>}
    </div>
}

