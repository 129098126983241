import React, {useEffect, useState} from "react";
import {homeHippodrome, homeHippodromeSubject, todayResultsSubject} from "../../../store/misc.store";
import {useForceUpdate} from "../../../services/misc.functions";
import {availableBettingTypes, bettingTypeShortNames, courseColors} from "../../../store/bulletin.store";
import {useNavigate} from "react-router-dom";
import paths from "../../../paths";
import {bulletinProvider} from "../../../services/bulletin.service";
import {isMobile} from "../../../App";

export function Races() {
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();
    const [showDetail, setShowDetail] = useState(!isMobile);

    useEffect(() => {

        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            forceUpdate();
        })

        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }

    }, []);

    useEffect(() => {
        let todayResultsSubject_subscription = todayResultsSubject.subscribe((res: any) => {
            forceUpdate();
        })
        return () => {
            todayResultsSubject_subscription.unsubscribe();
        }
    }, []);

    return <div className={"home-widget"}>
        <div onClick={() => {
            if (isMobile) {
                setShowDetail((prevShowDetail) => !prevShowDetail);
            }

        }} className={`header ${showDetail ? 'isOpen' : ''}`}>
            <div className={"d-flex align-items-center"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M3.3842 1.125H9.40726C9.59655 1.12498 9.7809 1.12496 9.93701 1.13771C10.1085 1.15172 10.3108 1.18477 10.5143 1.28849C10.7966 1.4323 11.026 1.66177 11.1698 1.94402C11.2736 2.14759 11.3066 2.34989 11.3206 2.52133C11.3334 2.67745 11.3334 2.86178 11.3333 3.05107V4.29163L15.1296 4.29163C15.2758 4.29159 15.4323 4.29155 15.5635 4.30359C15.6989 4.31601 15.9297 4.34885 16.1444 4.50323C16.4012 4.68785 16.5684 4.97201 16.6051 5.28613C16.6358 5.54879 16.5524 5.76651 16.4975 5.89094C16.4443 6.01148 16.3682 6.14825 16.2972 6.27602L15.2237 8.2083L16.2972 10.1406C16.3682 10.2684 16.4443 10.4051 16.4975 10.5257C16.5524 10.6501 16.6358 10.8678 16.6051 11.1305C16.5684 11.4446 16.4012 11.7288 16.1444 11.9134C15.9297 12.0678 15.6989 12.1006 15.5635 12.113C15.4323 12.125 15.2758 12.125 15.1296 12.125L9.38443 12.125C9.19513 12.125 9.01079 12.125 8.85466 12.1123C8.68322 12.0983 8.48092 12.0652 8.27735 11.9615C7.99511 11.8177 7.76564 11.5882 7.62183 11.306C7.5181 11.1024 7.48505 10.9001 7.47105 10.7286C7.45829 10.5725 7.45831 10.3882 7.45833 10.1989L7.45834 8.95834L4.41892 8.95834L6.16511 15.9431C6.26557 16.3449 6.02125 16.7521 5.6194 16.8526C5.21756 16.953 4.81036 16.7087 4.70989 16.3069L1.51343 3.52107C1.45568 3.29021 1.40102 3.07169 1.37173 2.88634C1.34043 2.68827 1.32211 2.44206 1.40245 2.18209C1.51088 1.83126 1.74423 1.53239 2.05828 1.34211C2.29101 1.2011 2.53431 1.15916 2.73406 1.14149C2.92097 1.12495 3.14623 1.12498 3.3842 1.125Z"
                          fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                </svg>
                <span className={"text-sm text-white ms-2"}>Günün Koşuları</span>
            </div>
            <div></div>
            <div>
                {isMobile && <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                         className={` ${showDetail ? 'rotate-svg' : ''}`}>
                        <path d="M4 6L8 10L12 6" stroke={isMobile && !showDetail ? '#004742' : "white"} strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"></path>
                    </svg>
                </>}
            </div>

        </div>
        {showDetail && <div className={"body p-2"}>
            {homeHippodrome?.runs?.map((run: any, runsIndex: number) => {
                return (<React.Fragment key={`runs-i-${runsIndex}`}>
                    <div className={"daily-race-container"}>
                        <div className="row d-flex align-items-center ">
                            <div className="col-12 col-md-1 me-1 mb-xs-1 p-0">
                                <p className="text-xxsmall fw-medium">
                                    <span className="text-small text-bolder">{run.no}</span>.Koşu
                                </p>
                            </div>
                            <div className="col-12 col-md-10 d-flex align-items-center p-0">
                                <div className="race-start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"
                                         fill="none" className="me-1">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M0.416664 5C0.416664 2.4687 2.46869 0.416668 5 0.416668C7.5313 0.416668 9.58333 2.4687 9.58333 5C9.58333 7.53131 7.5313 9.58333 5 9.58333C2.46869 9.58333 0.416664 7.53131 0.416664 5ZM5.41666 2.5C5.41666 2.26988 5.23012 2.08333 5 2.08333C4.76988 2.08333 4.58333 2.26988 4.58333 2.5V5C4.58333 5.15782 4.6725 5.3021 4.81366 5.37268L6.48033 6.20601C6.68615 6.30893 6.93643 6.2255 7.03934 6.01967C7.14225 5.81385 7.05883 5.56357 6.853 5.46066L5.41666 4.74249V2.5Z"
                                              fill="white"></path>
                                    </svg>
                                    <span className="text-bold text-white text-xxsmall">{run.time}</span>
                                </div>
                                <span className="text-xxsmall text-white text-bold course"
                                      style={{backgroundColor: courseColors[run.course]}}>{run.course}</span>
                                <span
                                    className="text-xxsmall text-bold me-1">{run.distance}m | {run.group} | {run.type_detail}</span>
                            </div>
                        </div>
                        <div className={"divider"}></div>
                        <div className="races-bets">

                            <div className="d-flex">

                                {
                                    homeHippodrome.wager_details.map((el: any, index: any) => {
                                        if (Object.keys(bettingTypeShortNames).includes(el.type.toString())) {
                                            if(el.runs.includes(Number(run.no))) {
                                                return <div key={"bet-" + index} className="p-0">
                                                    <div className="bet-wrapper">
                                                        <a className={"text-xxsmall text-white"}
                                                           onClick={() => {
                                                               let isExist = availableBettingTypes.find((b: any) => b.id.toString() === el.type.toString())
                                                               if (isExist) {
                                                                   bulletinProvider.selectBettingType(homeHippodrome, isExist.id);
                                                               }
                                                               setTimeout(() => {
                                                                   navigate(`${paths.betting.url}/${homeHippodrome.keyword.toLowerCase()}${homeHippodrome._id}`);
                                                               }, 1000);

                                                           }}>

                                                            <p className="text-xxsmall text-white text-bold">{bettingTypeShortNames[el.type.toString()]}</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>)
            })}
        </div>}
    </div>
}
