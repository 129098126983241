import React, {useEffect, useState} from "react";
import {Subject, Subscription} from "rxjs";

import iconInfo from "../assets/feather/info.svg";
import {isMobile} from "../App";

export const modalServiceSubject = new Subject();

let afterLoad: boolean = false;
const ModalService = (props: any) => {
    const [showModal, setShowModal] = useState<any>(false);

    useEffect(() => {
        const modalServiceSubject_subscription: Subscription = modalServiceSubject.subscribe((obj: any) => {
            if (obj) {
                setShowModal(obj);

                if (obj.timeout && obj.timeout > 0) {
                    setTimeout(() => {
                        setShowModal(null);
                    }, obj.timeout);

                }

            } else {
                unSetModal();
            }
        });
        return () => {
            modalServiceSubject_subscription.unsubscribe();
            afterLoad = false;
        };
    }, []);

    const unSetModal = () => {
        if (showModal && showModal.onClose) {
            showModal.onClose();
        }
        afterLoad = false;
        if (showModal.backdropCloseDisabled !== true) {
            setShowModal(false);
        }
    };

    if (showModal) {

        if (showModal.afterLoad && afterLoad === false) {
            afterLoad = true;
            setTimeout(() => {
                showModal.afterLoad();
            }, 5);
        }

        return (
            <>
                {props.children}
                <div className={`modal show ${showModal.case || ""} ${showModal.class || ""}`}
                     style={{
                         width: showModal.width ? showModal.width : (isMobile ? '98%' : "472px"),
                         maxWidth: showModal.width ? "calc( 100vw - 40px)" : "",
                     }}>
                    <div className={`modal-header ${showModal.greenBg ? '1' : 'bg-white'}`}>
                        <h3 className="text-lg mb-0 text-white text-bolder text-center w-100">{showModal.title ?? ''}</h3>
                        {showModal.greenBg}
                        <svg onClick={() => unSetModal()} width="24" height="24" viewBox="0 0 24 24"
                             fill={showModal.greenBg ? "#FFFFFF" : "#353535"}
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke={showModal.greenBg ? "#FFFFFF" : "#353535"}
                                  strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"></path>
                        </svg>
                    </div>


                    {typeof(showModal.content) !== 'object' && <div
                        className="modal-body "
                        style={{
                            maxHeight: "calc(100vh - 150px)",
                            height: showModal.height ? showModal.height : "auto"
                        }}>
                        {showModal.case === 'info' ? <>
                            <div style={{display: "flex", justifyContent: "center", rotate: "180deg"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"
                                     fill="none">
                                    <path
                                        d="M39.9993 53.3333V40M39.9993 26.6666H40.0327M73.3327 40C73.3327 58.4095 58.4088 73.3333 39.9993 73.3333C21.5899 73.3333 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66663 39.9993 6.66663C58.4088 6.66663 73.3327 21.5905 73.3327 40Z"
                                        stroke="#FF8700" strokeWidth="4" strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                </svg>
                            </div>
                        </> : <></>}
                        {showModal.case === 'danger' ? <>
                            <p className="text-xsmall text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"
                                     fill="none">
                                    <path
                                        d="M49.9993 30L29.9993 50M29.9993 30L49.9993 50M73.3327 40C73.3327 58.4095 58.4088 73.3334 39.9993 73.3334C21.5899 73.3334 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66669 39.9993 6.66669C58.4088 6.66669 73.3327 21.5905 73.3327 40Z"
                                        stroke="#E04C4C" strokeWidth="4" strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                </svg>
                            </p>
                        </> : <></>}
                        {showModal.case === 'success' ? <>
                            <p className="text-xsmall text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"
                                     fill="none">
                                    <path
                                        d="M25.0013 40.0003L35.0013 50.0003L55.0013 30.0003M73.3346 40.0003C73.3346 58.4098 58.4108 73.3337 40.0013 73.3337C21.5918 73.3337 6.66797 58.4098 6.66797 40.0003C6.66797 21.5908 21.5918 6.66699 40.0013 6.66699C58.4108 6.66699 73.3346 21.5908 73.3346 40.0003Z"
                                        stroke="#47BD29" strokeWidth="4" strokeLinecap="round"
                                        strokeLinejoin="round"></path>
                                </svg>
                            </p>
                        </> : <></>}


                        {showModal.innerHtml && <div
                            dangerouslySetInnerHTML={{
                                __html: (showModal.case ? `<p class="text-lg text-bolder text-center mb-2">${showModal.title}</p>` : "") + showModal.content,
                            }}></div>}
                        {!showModal.innerHtml && <>
                            <p className="text-lg text-bolder text-center mb-2">{showModal.title}</p>
                            <p className={"text-small text-center mb-1"}>{showModal.content}</p>
                        </>}

                    </div> }

                    {typeof(showModal.content) === 'object' && <div
                        className="modal-body"
                    >{showModal.content}</div>}


                    <div className="footer p-2 text-end">
                        {showModal.confirm ? (
                            <>
                                {showModal.confirm.sure ? (
                                    <>
                                        <button
                                            id="id_btnModelServiceSure"
                                            className={"button sm secondary filled mt-3 w-100 text-bolder"}
                                            onClick={() => {
                                                if (showModal.confirm.sure.async) {
                                                    showModal.confirm.sure.action().then((res: any) => {
                                                        if (res) {
                                                            unSetModal();
                                                        }
                                                    });
                                                } else {
                                                    if (showModal.confirm.sure.action != null) {
                                                        showModal.confirm.sure.action();
                                                    }
                                                }
                                                if (showModal.confirm.sure.autoClose !== false) {
                                                    unSetModal();
                                                }
                                            }}
                                        >
                                            {showModal.confirm.sure.label}
                                        </button>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {showModal.confirm.cancel ? (
                                    <>
                                        <button
                                            id="id_btnModelServiceCancel"
                                            className={"button sm tertiary outlined mt-3 w-100 text-bolder"}
                                            onClick={() => {
                                                if (showModal.confirm.cancel && showModal.confirm.cancel.action) {
                                                    showModal.confirm.cancel.action();
                                                    if (showModal.confirm.cancel.autoClose !== false) {
                                                        unSetModal();
                                                    }
                                                } else {
                                                    unSetModal();
                                                }
                                            }}
                                        >
                                            {showModal.confirm.cancel && showModal.confirm.cancel.label
                                                ? showModal.confirm.cancel.label
                                                : "Vazgeç"}
                                        </button>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                <div
                    onClick={() => {
                        unSetModal();
                    }}
                    className="modal-backdrop show"
                ></div>
            </>
        );
    }
    return <>{props.children}</>;
};
export default ModalService;
