import React, {useEffect} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {useNavigate, useParams} from "react-router";
import './support.css'


export function SupportFAQDetail() {

    const [subjects, setSubjects] = React.useState<any[]>([])
    const [issues, setIssues] = React.useState<any[]>([])
    const [hint, setHint] = React.useState<any>(null);
    const navigate = useNavigate();
    const params = useParams();
    const pathname = useNavigate();

    useEffect(() => {
        getIssues(Number(params.id));
    }, [params])


    const getIssues = async (e: any) => {
        if (Number(e) === -1) {
            setIssues([]);
            setHint(null);
            return null;
        }
        let api = new ApiService();
        await api.start('post', env.api + '/misc/api/list-questions/', {'question_type': e}, false)
            .then((res: any) => {
                setIssues(res.data);
            })
    }

    return (<div>
        <p className="text-md text-bold">Üyelik</p>
        <div style={{height: "16px"}}></div>

        {issues && issues.map((item: any, key: number) => {
            return <div className="accordion-list" key={`subjects-faq-${key}`}
                        id={`subjects-faq-${item.question_type}`}>
                <div className="accordion-wrapper">
                    <div className="accordion-question" onClick={() => {
                        if (hint === item.id) {
                            setHint(null);
                        } else {
                            setHint(item.id);
                        }

                    }}>
                        <p className="text-xsmall text-bold">{item.title}</p>
                        <div>

                            {hint === item.id ? <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 10L8 6L4 10" stroke="#000" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                          xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6L8 10L12 6" stroke="#000" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </svg>}
                        </div>
                    </div>
                    {hint === item.id && <div className="accordion-divider"></div>}
                    {hint === item.id &&
                        <p className="text-xsmall mt-2" dangerouslySetInnerHTML={{__html: item.content}}></p>}
                </div>
            </div>
        })}


    </div>)
}
