import './home.css'
import {Slider} from "./slider/slider";

import {Possibles} from "./widgets/possibles";
import {Agf} from "./widgets/agf";
import {Results} from "./widgets/results";
import {Races} from "./widgets/races";
import {NotRunningHorses} from "./widgets/not.running.horses";
import {ChangedJockeys} from "./widgets/changed.jockeys";
import {homeHippodrome, homeHippodromeSubject} from "../../store/misc.store";
import {useEffect} from "react";
import {useForceUpdate} from "../../services/misc.functions";
import {HippodromeWidget} from "../../shared/hippodrome/hippodrome";
import {VerifyEmail} from "./verify.email";

export function Home() {
    const forceUpdate = useForceUpdate();
    useEffect(() => {

        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            forceUpdate();
        })

        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }

    }, []);

    console.log(homeHippodrome);

    return <div>
        <div className={"bg-green"}>
            <div className={"container py-4"} >
                <Slider/>
            </div>
        </div>

        <HippodromeWidget/>

        <div className={"container"}>
            <div className={"home-grid"}>

                <Possibles hippodrome={homeHippodrome}/>

                <Agf hippodrome={homeHippodrome}/>

                <Results/>

                <Races/>

                <NotRunningHorses/>

                <ChangedJockeys/>

            </div>
        </div>
        <VerifyEmail></VerifyEmail>

    </div>
}
