import React, {useEffect, useState} from "react";
import {env, supportTicketStatus} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";
import {
    CurrencyFormat,
    dateTimeFormat,
    exceptionModal,
    loadingAnimation,
    useForceUpdate
} from "../../services/misc.functions";
import {useNavigate, useParams} from "react-router";
import paths from "../../paths";
import iconFile from "../../assets/feather/file.svg";
import {modalServiceSubject} from "../../services/modal.service";
import {SupportTicketForm} from "./support.ticket";
import iconBackArrow from "../../assets/feather/arrow-left.svg";
import {isMobile} from "../../App";
import Modal from "react-modal";
import {SubmitHandler, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {supportTicketWsSubject} from "../../store/misc.store";
import _ from "lodash";
import {auth} from "../../store/auth.store";


let selectedSupportTicket: any = null;
let supportTicketPage: number = 0;
let supportListStatus: any = '0';

export function SupportTicketList() {
    const [subjects, setSubjects] = React.useState<any[]>([])
    const forceUpdate = useForceUpdate();
    const [metaData, setMetaData] = useState<any>([]);
    const [dataList, setDataList] = useState<any[]>([]);
    const [ticketForm, showTicketForm] = React.useState<boolean>(false);
    const [newTicketModal, setNewTicketModal] = React.useState<boolean>(false);
    // const [supportListStatus, setSupportListStatus] = useState<any>(0);

    const params = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        let supportTicketWsSubject_sub = supportTicketWsSubject.subscribe((data: any) => {
            if (dataList.length > 0) {
                let exTicket = dataList.find((el: any) => Number(el.id) === Number(data.id))
                if (exTicket) {
                    for (const [key, Value] of Object.entries(exTicket)) {
                        exTicket[key] = data[key];
                    }
                }
            }

            setDataList(dataList);
            forceUpdate();

        });

        return () => {
            supportTicketWsSubject_sub.unsubscribe();
        }
    }, []);


    useEffect(() => {
        let api = new ApiService();
        api.start('get', env.api + '/misc/api/list-questions/', {'question_type': null}, false)
            .then((res: any) => {
                setSubjects(res.data);

            })
    }, [])


    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<any>();

    const onSubmit: SubmitHandler<any> = (payload: any) => {
        let acceptedFiles: any[] = [];
        for (let f of payload.file) {
            if ((f.type.includes('image') || f.type.includes('pdf')) && f.size < 1024 * 1024 * 5) {
                acceptedFiles.push(f);
            } else {
                toast.error('Lütfen 5MB dan küçük ve resim/pdf formatında dosya yükleyiniz.');
                return null;
            }
        }
        let formData: any = new FormData()
        Object.entries(payload).forEach(([key, value]) => {
                if (key !== 'file') {
                    formData.append(key, value)
                }
            }
        )
        formData.append('file', payload.file[0])
        // formData.append('subject', 1)
        let post = new ApiService();
        post.setHeaders({'Content-Type': 'multipart/form-data'})
        post.start('post', env.api + '/support/api/create-ticket/', formData, true).then((res: any) => {
            reset({message: '', file: null});
            setNewTicketModal(false);
            modalServiceSubject.next({
                case: 'success', title: 'Talebiniz Alındı',
                content: 'Destek talebiniz başarıyla oluşturuldu. En kısa sürede size dönüş yapılacaktır.',
                confirm: {
                    cancel: {
                        class: 'btn btn-success',
                        label: 'Tamam',
                        action: () => {
                            navigate(paths.support.url);
                        }
                    }
                }
            })
        }).catch((err: any) => {
            exceptionModal(err);
        })
    }


    useEffect(() => {
        loadData(supportTicketPage);
    }, [])

    const loadData = async (page: number) => {
        loadingAnimation(true);
        let loadDataList = dataList;
        if (page === 0) {
            loadDataList = [];

        }
        let api = new ApiService();
        await api.start('post', env.api + '/support/api/list-ticket/', {
            status: supportListStatus.toString(),
            metadata: {
                page: page,
            },
        }, true)
            .then((res: any) => {
                loadingAnimation(false);

                if (res && res.metadata) {
                    setMetaData(res.metadata);
                }

                if (res && res.status) {
                    loadDataList.push(...res.data);
                    // return res.data;
                }

            });
        setDataList(loadDataList);
    }


    const loadMore = (e: any) => {
        e.target.disabled = true;
        loadData(supportTicketPage + 1).finally(() => {
            e.target.disabled = false;
        });
    }

    if (params.id) {
        selectedSupportTicket = dataList.find((el: any) => Number(el.id) === Number(params.id));
    } else {
        selectedSupportTicket = null;
    }

    if (metaData) {
        let id_datalist_loadMore: any = document.getElementById('id_datalist_loadMore');
        if (id_datalist_loadMore) {
            if (metaData.page + 1 === metaData.total_page || metaData.page == metaData.total_page) {
                id_datalist_loadMore.style.display = 'none';
            } else {
                id_datalist_loadMore.style.display = 'block';
            }
        }
    }


    return (<>
        <div className="member-page-wrapper">
            <div className="row">

                <div className="col p-0">


                    {selectedSupportTicket && <div>

                        <div
                            className={`d-flex -items-center mb-2 ${isMobile ? 'my-2' : ''}`}>
                            <img alt={"geri"} className={`float-start  ms-1 me-3`} src={iconBackArrow}
                                 onClick={() => {
                                     window.history.back();
                                 }}/>
                            <p className="text-xsmall text-primary profile-title mb-0">Destek Taleplerim</p>

                        </div>

                        <div className="mt-4 withdraw-page-wrapper">
                            <div className="d-flex withdraw-title justify-content-between">
                                <div className={"d-flex"}>
                                    <p className="text-md text-white "> #{selectedSupportTicket.id} &nbsp;</p>
                                    <p className="text-md text-white text-bold mw-1">{selectedSupportTicket.main_subject?.name}</p>
                                </div>
                                <div>
                                    <p className="text-md text-white ">  {dateTimeFormat(selectedSupportTicket.create_date)}</p>
                                </div>
                            </div>
                            <div className="withdraw-add-bank-account-wrapper">
                                <p className={"text-bold text-primary mb-2"}>Mesajınız:</p>
                                <p className="pb-4 text-small fw-medium">
                                    {selectedSupportTicket.message}
                                </p>
                                {selectedSupportTicket.Responses && selectedSupportTicket.Responses.length > 0 &&
                                    <div className="ticket-responses">
                                        <p className={"text-bold text-primary mb-2"}>Müşteri Destek Ekibi Cevabı:</p>

                                        {selectedSupportTicket.Responses.map((response: any, key: number) => {
                                            return (
                                                <div key={`ticket-response-${key}`} className="ticket-response mb-4">
                                                    <span
                                                        className="text-xxsmall text-bold">Tarih: {dateTimeFormat(response.create_date)}</span>
                                                    <p className={"text-small fw-medium mt-2"}>{response.message}</p>
                                                    {response.file &&
                                                        <a className="btn btn-sm btn-warning attach"
                                                           href={env.media + response.file}
                                                           target="_blank">
                                                            <img src={iconFile} alt={"Aç"} width={18}/> Dosya Eki
                                                        </a>}
                                                </div>)
                                        })}
                                    </div>}
                            </div>
                        </div>


                    </div>}
                    {selectedSupportTicket === null &&
                        <>
                            <div
                                className={`d-flex justify-content-between align-items-center mb-2 ${isMobile ? 'my-2' : ''}`}>
                                <p className="text-xsmall text-primary profile-title">Destek Taleplerim</p>
                                <button onClick={() => {
                                    setNewTicketModal(true);
                                }} className="button secondary sm rounded-pill px-4">
                                    <p className={"text-small text-bolder"}>+ Talep Oluştur</p>
                                </button>
                            </div>

                            <div className="transaction-filter-wrapper" style={{paddingBottom: '0'}}>

                                <div className="hipodrome-tab-container" style={{margin: 'unset'}}>
                                    <div className="container">
                                        <div className="tab-items-wrapper">
                                            <div onClick={() => {
                                                supportListStatus = "0";
                                                loadData(0);
                                            }}
                                                 className={`result-tab ${supportListStatus === "0" ? ' result-selected' : ''}`}>
                                                <div className="tab-item selected ">
                                                    <p className={`text-small text-bolder ${supportListStatus === "0" ? 'text-primary' : 'text-white'}`}>Beklemede</p>
                                                    {supportListStatus === "0" &&
                                                        <div className="tab-active-indicator"></div>
                                                    }
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                supportListStatus = "1,2";
                                                loadData(0);
                                            }}
                                                 className={`result-tab ${supportListStatus === "1,2" ? ' result-selected' : ''}`}>
                                                <div className="tab-item  ">
                                                    <p className={`text-small text-bolder ${supportListStatus === "1,2" ? 'text-primary' : 'text-white'}`}>İşleme
                                                        Alınan</p>
                                                    {supportListStatus === "1,2" &&
                                                        <div className="tab-active-indicator"></div>
                                                    }
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                supportListStatus = "9";
                                                loadData(0);
                                            }}
                                                 className={`result-tab ${supportListStatus === "9" ? ' result-selected' : ''}`}>
                                                <div className="tab-item  ">
                                                    <p className={`text-small text-bolder ${supportListStatus === "9" ? 'text-primary' : 'text-white'}`}>Sonuçlanan</p>
                                                    {supportListStatus === "9" &&
                                                        <div className="tab-active-indicator"></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                dataList.length > 0 ?

                                    <div>
                                        <table className="transaction-table">
                                            <thead>
                                            <tr>
                                                <th>Ticket Id</th>
                                                <th className={"text-center"}>Tarih</th>
                                                <th>Durumu</th>
                                                <th className={"text-center"}>Detay</th>
                                            </tr>
                                            </thead>
                                            <tbody>


                                            {dataList.map((item: any, key: number) => {

                                                return (
                                                    <React.Fragment key={`transaction-${key}`}>
                                                        <tr onClick={() => {
                                                            navigate(paths.support.url + '/' + item.id + '/')
                                                        }}>
                                                            <td>
                                                                <p className="text-xsmall text-bold">#{item.id}</p>
                                                                <p className="text-xxsmall">{item.main_subject.name}</p>

                                                            </td>
                                                            <td>
                                                                <p className="text-xsmall ">{dateTimeFormat(item.create_date)}</p>
                                                            </td>
                                                            <td>
                                                                <p className="text-xsmall text-bold">{supportTicketStatus[item.status]}</p>

                                                            </td>
                                                            <td>
                                                                <div
                                                                    className={"d-flex justify-content-center align-items-center"}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6"
                                                                         height="12" viewBox="0 0 6 12" fill="none">
                                                                        <path d="M1 11L5 6L1 1" stroke="#004742"
                                                                              stroke-width="1.5" stroke-linecap="round"
                                                                              stroke-linejoin="round"></path>
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}


                                            </tbody>
                                        </table>

                                    </div>
                                    :
                                    <div style={{marginBottom: "150px", marginTop: "150px"}}>
                                        <div className="d-flex mt-5" style={{justifyContent: "center"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80"
                                                 viewBox="0 0 80 80"
                                                 fill="none">
                                                <path
                                                    d="M39.9993 53.3333V40M39.9993 26.6666H40.0327M73.3327 40C73.3327 58.4095 58.4088 73.3333 39.9993 73.3333C21.5899 73.3333 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66663 39.9993 6.66663C58.4088 6.66663 73.3327 21.5905 73.3327 40Z"
                                                    stroke="#929191" strokeWidth="4" strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                            </svg>
                                        </div>
                                        <p className="text-xl  text-bolder text-center mb-1">Veri Bulunamadı</p>
                                        <p className="text-small text-center mb-5">Bu durumda olan destek talebiniz
                                            bulunmamamktadır.</p>
                                    </div>
                            }
                        </>}

                </div>
            </div>
        </div>

        <Modal
            isOpen={newTicketModal}
            onRequestClose={() => {
                setNewTicketModal(false);
                loadData(0);
            }}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    maxWidth: 'calc(100vw - 80px)',
                    width: '460px',
                    padding: '0',
                    maxHeight: 'calc(100vh - 100px)',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '1rem',
                    border: "0"
                }, overlay: {
                    zIndex: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    backdropFilter: 'blur(10px)',
                }
            }}
            contentLabel="">
            <div className={"modal-header"}>
                <h5 className="w-100 text-center text-bolder align-self-center mb-0 text-white">Destek Talebi
                    Oluştur</h5>
                <div onClick={() => {
                    setNewTicketModal(false);
                    loadData(0);
                }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"></path>
                    </svg>
                </div>

            </div>

            <div className="modal-body py-4 support-ticket-form">

                <form onSubmit={handleSubmit(onSubmit)}>
          <textarea className="text-field-input" id={"id_support_textArea"} {...register<any>("message", {
              required: true,
              minLength: 10,
              maxLength: 600
          })} placeholder={"Mesajınızı giriniz"}></textarea>
                    {errors.message &&
                        <span
                            className="text-danger">Bu alan zorunludur. En az 40, en çok 600 karakter girmelisiniz.</span>}
                    <div className={"my-3"}>

                        <select
                            className={`form-select register-select w-100`}
                            {...register("subject_id", {required: true})}
                            defaultValue={"DEFAULT"}>

                            <option value="DEFAULT" disabled>
                                Konu Seç
                            </option>
                            {subjects.map((x: any) => {
                                return (
                                    <option value={x.question_type} key={x.question_type_display}>
                                        {x.question_type_display}
                                    </option>
                                );
                            })}
                        </select>
                        {errors.subject &&
                            errors.subject.type === "required" && (
                                <span className="text-validation">
                            Lütfen bir konu seçiniz
                          </span>
                            )}
                    </div>


                    <div className="my-3">
                        <label htmlFor="id_supportTicketFile" className="form-label">
                            <p className={"text-xsmall "}>Dosya veya Fotoğraf (max:5mb)</p></label>
                        <input type="file" {...register<any>("file", {required: false})} className="text-field-input"
                               id="id_supportTicketFile"/>
                    </div>
                    <button className="button secondary w-100">
                        <p className={"text-small text-bold"}>Gönder</p>
                    </button>
                </form>
            </div>


        </Modal>
    </>)
}
