import Modal from "react-modal";
import React from "react";
import {useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {ApiService} from "../../../services/http.service";
import {env} from "../../../constants/global.vars";
import {toast} from "react-toastify";
import {userLogout} from "../../../services/auth.service";
import paths from "../../../paths";

export function ChangePassword(props: any) {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({});
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<any> = (payload: any) => {
        const api = new ApiService();

        if (payload.new_password === payload.new_password2) {
            api
                .start("post", env.api + "/member/api/change-password/", payload, true)
                .then((res: any) => {
                    if (res && res.status) {
                        toast.success(res.message);
                        userLogout();
                        // navigate(paths.login.url);
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((e: any) => {
                    toast.error(e.message);
                    // exceptionModal(e);
                });
        } else {
            toast.error("Belirlediğiniz şifreler eşleşmiyor.");
        }
    };

    const isPasswordVisible = (inputId: any) => {
        let password: any = document.getElementById(inputId);

        if (password.type === "password") {
            password.type = "text";
        } else {
            password.type = "password";
        }
    };


    return <Modal
        isOpen={props.showModal}
        onRequestClose={() => {
            props.setShowModal(false);
        }}
        ariaHideApp={false}
        style={{
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '472px',
                maxHeight: 'calc(100% - 40px)',
                marginRight: '-50%',
                maxWidth: ' calc( 100% - 40px )',
                padding: '0px',
                transform: 'translate(-50%, -50%)',
                background: 'var(--bs-white)',
                borderRadius: 'var(--bs-border-radius-xl)',
                border: 0
            },
            overlay: {
                zIndex: 5,
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                backdropFilter: 'blur(10px)',
            }
        }} contentLabel="">


        <div className="modal-header">
            <h3 className="text-lg mb-0 text-white text-bolder text-center w-100">Şifre Güncelle</h3>
            <svg onClick={() => props.setShowModal(false)} width="24" height="24" viewBox="0 0 24 24"
                 fill="white"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round">
                </path>
            </svg>
        </div>
        <div className="modal-body pt-4 pb-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{height: "24px;"}}></div>
                <div className="text-field-wrapper">
                    <p className="text-small text-primary mb-2">Mevcut Şifre</p>
                    <input id="is_current_password" className="text-field-input" type="password"
                           {...register<any>('current_password', {required: true})}/>
                    <div onClick={() => isPasswordVisible("is_current_password")} className="text-filed-addon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="eye-off">
                                <path id="Icon"
                                      d="M7.16196 3.39488C7.4329 3.35482 7.7124 3.33333 8.00028 3.33333C11.4036 3.33333 13.6369 6.33656 14.3871 7.52455C14.4779 7.66833 14.5233 7.74023 14.5488 7.85112C14.5678 7.93439 14.5678 8.06578 14.5487 8.14905C14.5233 8.25993 14.4776 8.3323 14.3861 8.47705C14.1862 8.79343 13.8814 9.23807 13.4777 9.7203M4.48288 4.47669C3.0415 5.45447 2.06297 6.81292 1.61407 7.52352C1.52286 7.66791 1.47725 7.74011 1.45183 7.85099C1.43273 7.93426 1.43272 8.06563 1.45181 8.14891C1.47722 8.25979 1.52262 8.33168 1.61342 8.47545C2.36369 9.66344 4.59694 12.6667 8.00028 12.6667C9.37255 12.6667 10.5546 12.1784 11.5259 11.5177M2.00028 2L14.0003 14M6.58606 6.58579C6.22413 6.94772 6.00028 7.44772 6.00028 8C6.00028 9.10457 6.89571 10 8.00028 10C8.55256 10 9.05256 9.77614 9.41449 9.41421"
                                      stroke="#004742" strokeWidth="1.66" strokeLinecap="round"
                                      strokeLinejoin="round">
                                </path>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="text-field-wrapper">
                    <p className="text-small text-primary mb-2">Yeni
                        Şifre</p>
                    <input id="is_new_password" className="text-field-input"
                           type="password" {...register<any>('new_password', {required: true})}/>
                    <div onClick={() => isPasswordVisible("is_new_password")} className="text-filed-addon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Icon">
                                <g id="Icon_2">
                                    <path
                                        d="M1.61342 8.47513C1.52262 8.33137 1.47723 8.25949 1.45182 8.14862C1.43273 8.06534 1.43273 7.93401 1.45182 7.85073C1.47723 7.73986 1.52262 7.66798 1.61341 7.52422C2.36369 6.33623 4.59693 3.33301 8.00027 3.33301C11.4036 3.33301 13.6369 6.33623 14.3871 7.52422C14.4779 7.66798 14.5233 7.73986 14.5487 7.85073C14.5678 7.93401 14.5678 8.06534 14.5487 8.14862C14.5233 8.25949 14.4779 8.33137 14.3871 8.47513C13.6369 9.66311 11.4036 12.6663 8.00027 12.6663C4.59693 12.6663 2.36369 9.66311 1.61342 8.47513Z"
                                        stroke="#004742" strokeWidth="1.66" strokeLinecap="round"
                                        strokeLinejoin="round">
                                    </path>
                                    <path
                                        d="M8.00027 9.99967C9.10484 9.99967 10.0003 9.10424 10.0003 7.99967C10.0003 6.8951 9.10484 5.99967 8.00027 5.99967C6.8957 5.99967 6.00027 6.8951 6.00027 7.99967C6.00027 9.10424 6.8957 9.99967 8.00027 9.99967Z"
                                        stroke="#004742" strokeWidth="1.66" strokeLinecap="round"
                                        strokeLinejoin="round">
                                    </path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="text-field-wrapper">
                    <p className="text-small text-primary mb-2">Yeni
                        Şifre (Tekrar)</p>
                    <input id="is_new_password2" className="text-field-input"
                           type="password" {...register<any>('new_password2', {required: true})}/>
                    <div onClick={() => isPasswordVisible("is_new_password2")} className="text-filed-addon">
                        <svg id={"is_new_password2_eye_open"} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Icon">
                                <g id="Icon_2">
                                    <path
                                        d="M1.61342 8.47513C1.52262 8.33137 1.47723 8.25949 1.45182 8.14862C1.43273 8.06534 1.43273 7.93401 1.45182 7.85073C1.47723 7.73986 1.52262 7.66798 1.61341 7.52422C2.36369 6.33623 4.59693 3.33301 8.00027 3.33301C11.4036 3.33301 13.6369 6.33623 14.3871 7.52422C14.4779 7.66798 14.5233 7.73986 14.5487 7.85073C14.5678 7.93401 14.5678 8.06534 14.5487 8.14862C14.5233 8.25949 14.4779 8.33137 14.3871 8.47513C13.6369 9.66311 11.4036 12.6663 8.00027 12.6663C4.59693 12.6663 2.36369 9.66311 1.61342 8.47513Z"
                                        stroke="#004742" strokeWidth="1.66" strokeLinecap="round"
                                        strokeLinejoin="round">
                                    </path>
                                    <path
                                        d="M8.00027 9.99967C9.10484 9.99967 10.0003 9.10424 10.0003 7.99967C10.0003 6.8951 9.10484 5.99967 8.00027 5.99967C6.8957 5.99967 6.00027 6.8951 6.00027 7.99967C6.00027 9.10424 6.8957 9.99967 8.00027 9.99967Z"
                                        stroke="#004742" strokeWidth="1.66" strokeLinecap="round"
                                        strokeLinejoin="round">
                                    </path>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="mt-2">
                    <span className="text-xsmall me-2">Şifre kuralları:</span>
                    <span className="text-xsmall password-validator-item">rakam olmalı</span>
                    <span className="text-xsmall password-validator-item">harf olmalı</span>
                    <span className="text-xsmall password-validator-item">en az 8 karakter</span>
                    <span className="text-xsmall password-validator-item">TCKN olmamalı</span>
                    <span className="text-xsmall password-validator-item">doğum yılı olmamalı</span>
                    <span className="text-xsmall password-validator-item">isim-soyisim olmamalı</span>
                    <span className="text-xsmall password-validator-item">3 kez tekrar etmeyen sayı</span>
                </div>
                <button className="button sm secondary filled mt-3 w-100 ">
                    <p className="text-small text-bold text-center"
                    >Güncelle &amp; Doğrula</p>
                </button>
            </form>

        </div>

    </Modal>
}
