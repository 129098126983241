import {useNavigate, useParams} from "react-router";
import {agreements} from "../../constants/global.vars";
import paths from "../../paths";
import React, {useEffect} from "react";
import {agreementSubject} from "../../store/misc.store";
import {useForceUpdate} from "../../services/misc.functions";
import "./agreement.css";
import {isMobile} from "../../App";
import {Link, useLocation} from "react-router-dom";

export function Agreement() {

    const param = useParams();
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    const {pathname} = useLocation();


    useEffect(() => {
        let agreementSubject_subscriber = agreementSubject.subscribe(() => {
            forceUpdate();
        });
        return () => {
            agreementSubject_subscriber.unsubscribe();
        }

    }, [])

    if (!param.slug || !agreements[param.slug]) {
        setTimeout(() => {
            navigate(paths.home.url);
        }, 100)
        return <>
        </>
    }

    const content = agreements[param.slug]

    return (<>


        <div className="page-widget dark p-4">
            <div className="row">
                <div className="col-12">
                    {/*<h1>{content.title}</h1>*/}
                    <div className="page-widget agreement-page">
                        <div className="p-3">
                            <p dangerouslySetInnerHTML={{__html: content.content.replace(/\n\r/g, "<br />").replace(/\r\n/g, "<br />")}}></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>)
}
