import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import "./slider.css";
import {useEffect} from "react";

import {Link, useLocation, useNavigate} from "react-router-dom";
import {useForceUpdate} from "../../../services/misc.functions";
import {homeSliderSubject, homeSlides, path} from "../../../store/misc.store";
import {ApiService} from "../../../services/http.service";
import {env} from "../../../constants/global.vars";
import {isMobile} from "react-device-detect";


export function Slider() {

    const forceUpdate = useForceUpdate();
    const {pathname} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let homeSliderSubject_subscriber = homeSliderSubject.subscribe(() => {
            forceUpdate();
        })
        return () => {
            homeSliderSubject_subscriber.unsubscribe();
        }
    })

    useEffect(() => {
        if (homeSlides.length === 0) {
            let api = new ApiService();
            api.start('get', env.api + '/misc/api/list-slide/', null, false)
                .then((res: any) => {
                    homeSlides.splice(0, homeSlides.length);
                    homeSlides.push(...res.data);
                    forceUpdate();
                })
        }
    }, [])


    // fixme ana sayfa hariç görünmemeli
    useEffect(() => {
        forceUpdate();
    }, [pathname]);


    return (
        <div className="slider-wrapper">
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                loop={true}
                spaceBetween={0}
                autoplay={{delay: 5000}}
                slidesPerView={1}
                pagination={{clickable: true}}
                navigation={isMobile ? false : true}
            >

                {homeSlides.map((slide: any, key: number) => {
                    return (<SwiperSlide className={"me-0"} key={`slider-${key}`}>
                        <div className="slide" onClick={() => {
                            if (slide.web_url) {
                                navigate(`${slide.web_url}`);
                            }
                        }}>
                            <img src={env.media + (isMobile ? slide.photo_mobile : slide.photo_web)} alt={slide.title}/>

                            {slide.slide_type !== 1 && <div className="caption">
                                {slide.title && slide.title !== '-' && <h2>{slide.title}</h2>}
                                {slide.content && <p dangerouslySetInnerHTML={{__html: slide.content}}></p>}
                                {slide.web_url && !isMobile &&
                                    <Link className={"btn btn-warning btn-lg float-end"} to={slide.web_url}>BAHİS
                                        YAP</Link>}
                            </div>}
                        </div>
                    </SwiperSlide>)
                })}

            </Swiper>

        </div>
    );
};
