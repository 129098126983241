import {successLogStyle, warningLogStyle, wsFunctionTypes} from "../constants/global.vars";
import {Storage} from "./localstorege.service";
import {auth, authSubject} from "../store/auth.store";
import {toast} from "react-toastify";
import {ConnectionStatus, homeSliderSubject, homeSlides, supportTicketWsSubject} from "../store/misc.store";
import {getNews} from "./misc.functions";
import {userLogout, userWsLogout} from "./auth.service";

export class WebSocketService {
    public wsHandler: any;
    public wsUrl: any;

    public user(token: any = null) {
        if (token) {
            try {
                const _message: any = {
                    $type: "setUser",
                    data: token,
                };
                this.wsHandler.send(JSON.stringify({type: _message}));
            } catch (e) {
                console.log("web-socket service send Message ", e);
            }
        }
    }

    public checkauth() {
        try {
            const _message: any = {
                $type: "checkAuth",
            };
            this.wsHandler.send(JSON.stringify({type: _message}));
        } catch (e) {
            console.log("web-socket service send Message ", e);
        }
    }

    public connect(url: string) {
        this.wsUrl = url;
        this.wsHandler = new WebSocket(this.wsUrl);
        this.wsHandler.onopen = () => {
            console.log("%c* websocket servis connected", successLogStyle);
            ConnectionStatus.isConnected = true;
            this.user(Storage.get("token"));
        };

        this.wsHandler.onmessage = (e: any) => {
            this.handleMessage(JSON.parse(e.data));
        };

        this.wsHandler.onclose = () => {
            console.log("%c* websocket servis connection down", warningLogStyle);
            this.wsHandler = null;
            setTimeout(() => {
                this.connect(this.wsUrl);
            }, 2000);
        };

        return this.wsHandler;
    }

    private handleMessage(message: any) {
        // console.log(message)
        if (wsFunctionTypes.includes(message.type)) {
            eval(`this.${message.type}`)(message.data || message);
        }
    }

    supportTicketSaved(data: any) {
        supportTicketWsSubject.next(data);
    }

    checkAuth(data: any) {
        if (auth && auth.member && auth.member.id) {
            for (const [k, v] of Object.entries(data)) {
                auth.member[k] = v;
            }
            Storage.set("member", auth.member);
            authSubject.next({action: "checkAuth"});
        }
    }

    newNotification(data: any) {
        toast.info(data.header);
        auth.member.unread_notification_count = data.total;
        authSubject.next({action: "newNotification"});
    }

    updateSlide(data: any) {
        homeSlides.splice(0, homeSlides.length);
        homeSlides.push(...data);
        homeSliderSubject.next();
    }

    updateNews(data: any) {
        getNews();
    }

    accountApproved(data: any) {
        // console.log('accountApproved', data)
    }
    accountDeclined(data: any) {
        userWsLogout();
    }

    updateBalance(data: any) {
        auth.member.balance_point = data.balance_point;
        auth.member.balance_debit = data.balance_debit;
        auth.member.balance_point_available = data.balance_point_available;
        auth.member.balance_debit_available = data.balance_debit_available;

        Storage.set("member", auth.member);
        authSubject.next({action: "checkAuth"});
    }

    memberLogout(data: any) {
        userLogout();
    }
}

export const wssProvider = new WebSocketService();
