import "./member.css";
import {auth, authSubject} from "../../store/auth.store";
import InputMask from "react-input-mask";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {wssProvider} from "../../services/websocket.service";
import React, {useEffect, useState} from "react";
import {sendEmailValidationLink, userValidation} from "../../services/auth.service";
import {useForceUpdate} from "../../services/misc.functions";
import {modalServiceSubject} from "../../services/modal.service";
import {ValidateModal} from "../../shared/smsValidate/validate.modal";
import {ChangePassword} from "./components/change.password";


let s: any;
let resendButton: any;
let validationControl: any;

export function MemberInfo() {
    const [showValidateCodeInput, setShowValidateCodeInput] = useState<any>(false);
    const [showPasswordModal, setShowPasswordModal] = useState<any>(false);

    const forceUpdate = useForceUpdate();
    useEffect(() => {
        let auth_subscriber = authSubject.subscribe((res: any) => {
            forceUpdate();
        })
        return () => {
            auth_subscriber.unsubscribe();
        }
    }, [])

    const updateEmail = () => {
        modalServiceSubject.next({
            title: 'E-Posta Güncelle',
            greenBg: true,
            width: '472px',
            innerHtml: true,
            content: `
                <div>
                    <div style="height: 24px"></div>
                    <div class="text-field-wrapper">
                        <p class="text-small text-primary mb-2">E-Posta</p>
                        <input id="e-mail"
                               class="text-filed-input"
                               type="text" name="e-mail"/>
                    </div>
                </div>
            `,
            confirm: {
                sure: {
                    label: 'Güncelle & Doğrula',
                    action: () => {
                        // @ts-ignore
                        let email = document.getElementById('e-mail').value;
                        const api = new ApiService();
                        api
                            .start("post", env.api + "/member/api/update-account-info/", {email: email}, true)
                            .then((res: any) => {
                                if (res.status) {
                                    wssProvider.checkauth();
                                    setTimeout(() => {
                                        sendEmailValidationLink(email);
                                    }, 2000);

                                } else {
                                    modalServiceSubject.next({
                                        case: 'danger',
                                        title: 'Hata Oluştu.',
                                        width: '472px',
                                        content: `${res.message}`,
                                        confirm: {
                                            sure: {
                                                label: "Tamam",
                                            },
                                        },
                                    });
                                }
                            })
                            .catch((e: any) => {
                                console.log(e);
                            });

                    }
                }
            }
        })
    }

    const updateMobile = () => {
        modalServiceSubject.next({
            title: 'Cep Telefonu Güncelle',
            greenBg: true,
            width: '472px',
            innerHtml: true,
            content: <>
                <div>
                    <div style={{height: "24px"}}></div>
                    <div className="text-field-wrapper">
                        <p className="text-small text-primary mb-2">Cep Telefonu</p>
                        <InputMask
                            mask="999 999 99 99"
                            className="text-filed-input"
                            placeholder={"5XX XXX XX XX"}
                            id="mobile"
                        />
                    </div>
                </div>
            </>,
            confirm: {
                sure: {
                    label: 'Güncelle & Doğrula',
                    action: () => {
                        // @ts-ignore
                        let mobile = document.getElementById('mobile').value;
                        const api = new ApiService();
                        api
                            .start("post", env.api + "/member/api/update-account-info/", {mobile: mobile.replaceAll(' ', '')}, true)
                            .then((res: any) => {
                                userValidation({
                                    mobile: mobile,
                                });
                                validationControl = setInterval(() => {
                                    if (auth.mobile_validation === true) {
                                        clearInterval(validationControl);
                                        wssProvider.checkauth();
                                    }
                                }, 100);
                            })
                            .catch((e: any) => {
                                console.log(e);
                            });

                    }
                }
            }
        })
    }

    return (
        <div className="row ">
            <div className="col p-0">
                <p className="text-xsmall text-primary profile-title">Profil
                    Ayarları</p>
                <p className="text-small">Tüm üyelik bilgilerinizi buradan değiştirebilirsiniz.</p>
                <div className="profile-wrapper">
                    <div className="user-row">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="78" height="78" rx="39" fill="#F0F1EC"></rect>
                            <rect x="1" y="1" width="78" height="78" rx="39" stroke="#004742" strokeWidth="2"></rect>
                            <ellipse cx="39.9997" cy="49.1667" rx="11.6667" ry="5.83333" stroke="#004742"
                                     strokeWidth="3"
                                     strokeLinejoin="round"></ellipse>
                            <ellipse cx="40.0002" cy="31.6667" rx="6.66667" ry="6.66667" stroke="#004742"
                                     strokeWidth="3"
                                     strokeLinejoin="round"></ellipse>
                        </svg>
                        <p className="text-md text-bold">{auth.member.first_name} {auth.member.last_name}</p>
                    </div>
                    <div className="info-row" onClick={() => {
                        updateEmail();
                    }}>
                        <div className="info-row-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"
                                 fill="none">
                                <path
                                    d="M13.5 30L31.5438 38.8517C36.8777 41.4683 43.1223 41.4683 48.4562 38.8517L66.5 30"
                                    stroke="#004742" strokeWidth="4" strokeLinecap="round"></path>
                                <rect x="6.5" y="14" width="67.2" height="53.6" rx="10.8" stroke="#004742"
                                      strokeWidth="4"></rect>
                            </svg>
                            <div style={{margin: "auto 8px"}}>
                                <div className="d-flex mb-1">
                                    <p className="text-xxsmall text-primary mb-1" style={{margin: "auto"}}>E-Posta
                                        Adresi</p>
                                    <div
                                        className={`${auth.member.email_validation ? 'confirmed-text' : 'not-confirmed-text'}`}>
                                        {auth.member.email_validation ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                                 viewBox="0 0 10 10"
                                                 fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5.00008 0.416687C2.46878 0.416687 0.416748 2.46871 0.416748 5.00002C0.416748 7.53132 2.46878 9.58335 5.00008 9.58335C7.53139 9.58335 9.58341 7.53132 9.58341 5.00002C9.58341 2.46871 7.53139 0.416687 5.00008 0.416687ZM7.16971 4.04465C7.33243 3.88193 7.33243 3.61811 7.16971 3.45539C7.00699 3.29267 6.74317 3.29267 6.58045 3.45539L4.37508 5.66076L3.41971 4.70539C3.25699 4.54267 2.99317 4.54267 2.83045 4.70539C2.66773 4.86811 2.66773 5.13193 2.83045 5.29465L4.08045 6.54465C4.24317 6.70737 4.50699 6.70737 4.66971 6.54465L7.16971 4.04465Z"
                                                      fill="#336C68"></path>
                                            </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                                 viewBox="0 0 10 10"
                                                 fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5.00008 0.416687C2.46878 0.416687 0.416748 2.46872 0.416748 5.00002C0.416748 7.53133 2.46878 9.58335 5.00008 9.58335C7.53139 9.58335 9.58342 7.53133 9.58342 5.00002C9.58342 2.46872 7.53139 0.416687 5.00008 0.416687ZM6.54471 3.45539C6.70743 3.61811 6.70743 3.88193 6.54471 4.04465L5.58934 5.00002L6.54471 5.95539C6.70743 6.11811 6.70743 6.38193 6.54471 6.54465C6.38199 6.70737 6.11817 6.70737 5.95545 6.54465L5.00008 5.58928L4.04471 6.54465C3.88199 6.70737 3.61817 6.70737 3.45545 6.54465C3.29274 6.38193 3.29274 6.11811 3.45545 5.95539L4.41083 5.00002L3.45545 4.04465C3.29274 3.88193 3.29274 3.61811 3.45545 3.45539C3.61817 3.29267 3.88199 3.29267 4.04471 3.45539L5.00008 4.41076L5.95545 3.45539C6.11817 3.29267 6.38199 3.29267 6.54471 3.45539Z"
                                                      fill="#E04C4C"></path>
                                            </svg>}
                                        <p className={`text-xsmall  ms-1 ${auth.member.email_validation ? '' : 'text-danger'}`}>{auth.member.email_validation ? 'Doğrulandı' : 'Doğrulanmadı'}</p>
                                    </div>
                                </div>
                                <p className="text-small">{auth.member.email}</p>
                            </div>
                        </div>
                        <div style={{margin: "auto 10px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12"
                                 fill="none">
                                <path d="M1 11L5 6L1 1" stroke="#353535" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="info-row" onClick={() => {
                        updateMobile();
                    }}>
                        <div className="info-row-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M21 19V17.3541C21 16.5363 20.5021 15.8008 19.7428 15.4971L17.7086 14.6835C16.7429 14.2971 15.6422 14.7156 15.177 15.646L15 16C15 16 12.5 15.5 10.5 13.5C8.5 11.5 8 9 8 9L8.35402 8.82299C9.28438 8.35781 9.70285 7.25714 9.31654 6.29136L8.50289 4.25722C8.19916 3.4979 7.46374 3 6.64593 3H5C3.89543 3 3 3.89543 3 5C3 13.8366 10.1634 21 19 21C20.1046 21 21 20.1046 21 19Z"
                                    stroke="#004742" strokeWidth="1.5" strokeLinejoin="round"></path>
                            </svg>
                            <div style={{margin: "auto 8px"}}>
                                <div className="d-flex mb-1">
                                    <p className="text-xxsmall text-primary mb-1 me-1" style={{margin: "auto"}}>Cep
                                        Telefonu</p>
                                    <div
                                        className={`${auth.member.mobile_validation ? 'confirmed-text' : 'not-confirmed-text'}`}>
                                        {auth.member.mobile_validation ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                                 viewBox="0 0 10 10"
                                                 fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5.00008 0.416687C2.46878 0.416687 0.416748 2.46871 0.416748 5.00002C0.416748 7.53132 2.46878 9.58335 5.00008 9.58335C7.53139 9.58335 9.58341 7.53132 9.58341 5.00002C9.58341 2.46871 7.53139 0.416687 5.00008 0.416687ZM7.16971 4.04465C7.33243 3.88193 7.33243 3.61811 7.16971 3.45539C7.00699 3.29267 6.74317 3.29267 6.58045 3.45539L4.37508 5.66076L3.41971 4.70539C3.25699 4.54267 2.99317 4.54267 2.83045 4.70539C2.66773 4.86811 2.66773 5.13193 2.83045 5.29465L4.08045 6.54465C4.24317 6.70737 4.50699 6.70737 4.66971 6.54465L7.16971 4.04465Z"
                                                      fill="#336C68"></path>
                                            </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                                 viewBox="0 0 10 10"
                                                 fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5.00008 0.416687C2.46878 0.416687 0.416748 2.46872 0.416748 5.00002C0.416748 7.53133 2.46878 9.58335 5.00008 9.58335C7.53139 9.58335 9.58342 7.53133 9.58342 5.00002C9.58342 2.46872 7.53139 0.416687 5.00008 0.416687ZM6.54471 3.45539C6.70743 3.61811 6.70743 3.88193 6.54471 4.04465L5.58934 5.00002L6.54471 5.95539C6.70743 6.11811 6.70743 6.38193 6.54471 6.54465C6.38199 6.70737 6.11817 6.70737 5.95545 6.54465L5.00008 5.58928L4.04471 6.54465C3.88199 6.70737 3.61817 6.70737 3.45545 6.54465C3.29274 6.38193 3.29274 6.11811 3.45545 5.95539L4.41083 5.00002L3.45545 4.04465C3.29274 3.88193 3.29274 3.61811 3.45545 3.45539C3.61817 3.29267 3.88199 3.29267 4.04471 3.45539L5.00008 4.41076L5.95545 3.45539C6.11817 3.29267 6.38199 3.29267 6.54471 3.45539Z"
                                                      fill="#E04C4C"></path>
                                            </svg>}
                                        <p className={`text-xsmall  ms-1 ${auth.member.mobile_validation ? '' : 'text-danger'}`}>{auth.member.mobile_validation ? 'Doğrulandı' : 'Doğrulanmadı'}</p>
                                    </div>
                                </div>
                                <p className="text-small">{auth.member.mobile}</p>
                            </div>
                        </div>
                        <div style={{margin: "auto 10px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12"
                                 fill="none">
                                <path d="M1 11L5 6L1 1" stroke="#353535" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="info-row" onClick={() => {
                        setShowPasswordModal(true);
                    }}>
                        <div className="info-row-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M16 8H8M16 8C18.2091 8 20 9.79086 20 12V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V12C4 9.79086 5.79086 8 8 8M16 8V6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6V8M12 16V14"
                                    stroke="#004742" strokeWidth="1.5" strokeLinecap="round"></path>
                            </svg>
                            <div style={{margin: "auto 8px"}}>
                                <p className="text-xxsmall text-primary mb-1">Şifre</p>
                                <p className="text-small">•••••••••</p>
                            </div>
                        </div>
                        <div style={{margin: "auto 10px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12"
                                 fill="none">
                                <path d="M1 11L5 6L1 1" stroke="#353535" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    {/*<div className="address-row">
                        <div className="info-row-icon w-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <circle cx="12" cy="11" r="3" stroke="#004742" strokeWidth="1.5"></circle>
                                <path
                                    d="M21 10.8889C21 15.7981 15.375 22 12 22C8.625 22 3 15.7981 3 10.8889C3 5.97969 7.02944 2 12 2C16.9706 2 21 5.97969 21 10.8889Z"
                                    stroke="#004742" strokeWidth="1.5"></path>
                            </svg>
                            <div className="w-100">
                                <div className="d-flex pb-3"
                                     style={{margin: "auto 8px", borderBottom: "1px solid rgb(212, 214, 206)"}}>
                                    <div className="d-flex me-3">
                                        <div className="profile-city">
                                            <p className="text-xxsmall text-primary mb-1">İl</p>
                                            <p className="text-xsmall">Adana</p>
                                        </div>
                                        <div style={{margin: "auto"}}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 6L8 10L12 6" stroke="#000" strokeWidth="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="profile-city">
                                            <p className="text-xxsmall text-primary mb-1">İlçe</p>
                                            <p className="text-xsmall">Ceyhan</p>
                                        </div>
                                        <div style={{margin: "auto"}}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4 6L8 10L12 6" stroke="#000" strokeWidth="1.5"
                                                      stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex" style={{margin: "auto 8px"}}>
                                    <div className="mt-3">
                                        <p className="text-xxsmall text-primary mb-1">Adres</p>
                                        <p className="text-small info-long-text">test</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
            <ValidateModal showValidateModal={showValidateCodeInput} setShowValidateModal={setShowValidateCodeInput}/>
            <ChangePassword showModal={showPasswordModal} setShowModal={setShowPasswordModal}/>
        </div>);
}






