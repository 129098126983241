import React, {useEffect, useState} from "react";
import { useParams} from "react-router-dom";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import _ from "lodash";
import {modalServiceSubject} from "../../services/modal.service";
import {dateTimeStringFormat} from "../../services/misc.functions";

const {innerWidth: width} = window;
export let isMobile = width < 986 ? true : false;

export default function CampaignDetail() {
    const api = new ApiService();
    const {id} = useParams();
    const [dataList, setDataList] = useState<any[]>([]);
    const [selectedCampaign, setSelectedCampaign] = useState<any>(null);


    const getCampaignList = () => {
        api.start("get", env.api + "/misc/api/campaign/list/", null, true).then((res: any) => {
            if (res && res.status) {
                setDataList(_.orderBy(res.data, ["is_valid", "create_date"], ["desc"]));

            }
        }).then(() => {

        });
    }


    useEffect(() => {
        if (dataList.length === 0) {
            getCampaignList();
        }
        if (id) {
            api.start("get", env.api + `/misc/api/campaign/detail/${id}/`, null, true).then((res: any) => {
                if (res && res.status) {
                    setSelectedCampaign(res.data);
                }
            });
        }
    }, [id]);

    const subscribeCampaign = (campaign: any) => {
        api.start("post", env.api + "/misc/api/member-campaign/subscribe/", {"campaign_id": campaign.id}, true).then((res: any) => {
            if (res && res.status) {
                getCampaignList();
                modalServiceSubject.next({
                    case: "success",
                    title: "Başarılı",
                    width: 380,
                    content: `Kampanya'ya katılımızın gerçekleşmiştir.`,
                    confirm: {
                        cancel: {
                            label: "Tamam",
                            class: "btn-success",
                        },

                    },
                });
            } else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Hata",
                    width: 380,
                    content: `${res.message}`,
                    confirm: {
                        cancel: {
                            label: "Tamam",
                            class: "btn-danger",
                        },

                    },
                });
            }
        }).then(() => {
        });
    }

    return (
        <>
            <div className="campaignsTypeWrapper">
                <div className="container campaignsTypeContainer">
                    <h1 className="text-xl text-white text-bolder" style={{fontSize: "20px"}}>KAMPANYALAR</h1>
                </div>
            </div>
            <div className="container">

                <div className="row p-1 mx-0">


                    {selectedCampaign && <div className="col-lg-12 position-relative p-3">
                        {!selectedCampaign?.is_valid &&
                            <div className="ribbon ribbon-old">
                                <span>Geçmiş</span>
                            </div>
                        }
                        <img
                            className="picture mb-3 w-100"
                            src={env.api +'/' + selectedCampaign?.photo}
                            alt={selectedCampaign?.name}
                        />

                        <div className="card-title">
                            <h1>{selectedCampaign?.name}</h1>
                        </div>

                        <div className="card-body">
                            <div className={"row"}>
                                <div className={"col-12 col-lg-8"}>
                                    <h2>Kampanya Detayları</h2>
                                    <div>
                                        <p
                                            className="info mx-3"
                                            dangerouslySetInnerHTML={{__html: selectedCampaign?.description.replaceAll("\n", "<br/>")}}
                                        ></p>

                                        <h2>Katılım Şartları</h2>
                                        <p
                                            className="info mx-3"
                                            dangerouslySetInnerHTML={{
                                                __html: selectedCampaign?.participation_requirement.replaceAll("\n", "<br/>"),
                                            }}
                                        ></p>
                                    </div>
                                </div>
                                {selectedCampaign.participation_required && <div className={"col-12 col-lg-4"}>
                                    {!selectedCampaign.member_subscribed &&
                                        <button className="button xs secondary filled text-bold text-xxsmall w-100 mt-3"
                                                onClick={() => {
                                                    subscribeCampaign(selectedCampaign);
                                                }}
                                        >Kampanya'ya Katıl
                                        </button>
                                    }
                                    {selectedCampaign.member_subscribed &&
                                        <div className={"campaign-saved"}>

                                        <h2 className={"text-center text-small text-bolder p-1"}>Kampanyaya Katılımınız Gerçekleşmiştir.</h2>
                                        <p className={"text-center text-xsmall fw-medium"}>Kampanyaya {dateTimeStringFormat(selectedCampaign?.member_subscribed?.create_date)} tarihinde
                                            katıldınız.</p>
                                    </div>
                                    }
                                </div>}

                            </div>

                        </div>
                    </div>}
                </div>
            </div>
        </>
    );
}
