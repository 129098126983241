const description = "ekuri.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.";
const keywords = "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri";


const paths: any = {
    home: {

        url: "/",
        title: "TJK At Yarışı Oyna | Yeni Nesil Ganyan | Eküri.com",
        description: "TJK at yarışı, günlük at yarışı programı, güçlü istatistikler, kazandıran ganyan tahminleri, at yarışı sonuçları Eküri.com'da kupon yap kazan!",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Anasayfa"
    },
    login: {
        url: "/giris-yap",
        title: "Giriş Yap | Eküri.com",
        description: "Giriş Yap | Eküri.com TJK sonuçları, at yarışı programı, uzman yazar yorumları ve daha fazlası için hemen ziyaret edin.",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Giriş Yap"
    },
    logout: {
        url: "/cikis-yap",
        title: "Çıkış Yap | Eküri.com",
        description: "Çıkış Yap | Eküri.com",
        keywords,
        breadcrumbTitle: "Çıkış Yap"
    },
    memberInfo: {
        url: "/profil/uyelik-bilgilerim",
        title: "Üyelik Bilgilerim | Eküri.com",
        description: "Üyelik Bilgilerim | Eküri.com",
        keywords,
        breadcrumbTitle: "Üyelik Bilgilerim"

    },
    notificationInfo: {
        url: "/profil/ayarlar",
        title: "İletişim Ayarları | Eküri.com ",
        description: "İletişim Ayarları | Eküri.com ",
        keywords,
        breadcrumbTitle: "İletişim Ayarları"
    },
    memberTickets: {
        url: "/kuponlarim",
        title: "Kuponlarım | Eküri.com ",
        description: "Kuponlarım | Eküri.com ",
        keywords,
        breadcrumbTitle: "Kuponlarım"
    },
    memberSavedTickets: {
        url: "/kuponlarim/kayitli",
        title: "Kayıtlı Kuponlarım | Eküri.com",
        description: "Kayıtlı Kuponlarım | Eküri.com",
        keywords,
        breadcrumbTitle: "Kuponlarım"
    },
    memberBankAccounts: {
        url: "/profil/banka-hesaplarim",
        title: "Banka Hesaplarım | Eküri.com",
        description: "Banka Hesaplarım | Eküri.com",
        keywords,
        breadcrumbTitle: "Banka Hesaplarım"
    },
    memberTransactions: {
        url: "/profil/hesap-hareketlerim",
        title: "Hesap Hareketlerim | Eküri.com ",
        description: "Hesap Hareketlerim | Eküri.com ",
        keywords,
        breadcrumbTitle: "Hesap Hareketlerim"
    },
    cookieSettings: {
        url: "/cerez-ayarlari",
        title: "Çerez Ayarları | Eküri.com",
        description: "Çerez Ayarları | Eküri.com",
        keywords,
        breadcrumbTitle: "Çerez Ayarları"
    },

    withdraw: {
        url: "/profil/para-cek",
        title: "SSS - Para Çekme İşlemleri | Eküri.com",
        description:
            "Para çekme işlemleri Eküri.com'da sorunsuz ve güvenli. Hızlı işlem süreleri ve 7/24 müşteri desteğiyle güvenle bahis yapın!",
        keywords,
        breadcrumbTitle: "SSS - Para Çekme İşlemleri "
    },
    deposit: {
        url: "/profil/para-yatir",
        title:
            "SSS - Para Yatırma İşlemleri | Eküri.com",
        description: "Hızlı ve güvenli para yatırma işlemleri Eküri.com'da! Çeşitli ödeme yöntemleriyle kolayca yatırım yapın, bahis heyecanına ortak olun!",
        keywords, breadcrumbTitle: "SSS - Para Yatırma İşlemleri  "
    },

    support: {
        url: "/destek-taleplerim",
        title: "YARDIM, OYUN KURALLARI, NASIL TJK KUPON YAPARIM - ekuri.com, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
        description, keywords,
        breadcrumbTitle: "Yardım"
    },
    supportFAQ: {
        url: "/sss",
        title:
            "SSS - Üyelik İşlemleri |Eküri.com",
        description:
            " TJK At Yarışı heyecanını Eküri.com'la keşfedin! Hızlı, güvenli ve kolayca üye olun, üyelikle alakalı sıkça sorulan sorular ve cevapları için tıkla!",
        breadcrumbTitle: "Tümü"
    },

    announcement: {
        url: "/tjk-duyurulari-haberler",
        title: " Güncel TJK At Yarışı Duyurular & Haberler | Eküri.com ",
        description: " Güncel TJK At Yarışı Duyurular & Haberler, Cezalı Jokeyler, Koşmayan Atlar, At Yarışı Programı Eküri.com'da, Hemen Üye Ol!",
        keywords,
        breadcrumbTitle: " TJK Duyurular & Haberler"
    },
    agreement: {
        url: "/sozlesmeler",
        title: "SÖZLEŞMELER - ekuri.com, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
        description, keywords,
        breadcrumbTitle: "Sözleşmeler"
    },

    notifications: {
        url: "/bildirimlerim",
        title:
            "Bildirimlerim | Eküri.com",
        description:
            "Bildirimlerim | Eküri.com",
        keywords,
        breadcrumbTitle:
            "Bildirimlerim"
    },

    flatpage: {
        url: "/fp",
        title: " ekuri.com, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
        description, keywords,
        breadcrumbTitle: ""
    },

    stream: {
        url: "/tjk-tv-canli-izle",
        title:
            "TJK TV Canlı İzle - Canlı At Yarışı İzle | Eküri.com",
        description:
            "Eküri.com'da TJK TV'yi canlı izle! Yurt içi -yurt dışı yarışlarını canlı takip et.At yarışı sonuçlarını ve özetlerini izle, kuponunu yap!",
        keywords,
        breadcrumbTitle: "TJK TV Canlı İzle - Canlı At Yarışı İzle "
    },

    tipsterCoupons: {
        url: "/tjk-at-yarisi/hazir-kuponlar",
        title: " TJK At Yarışı Hazır Kuponlar | Eküri.com ",
        description: " Kazandıran kuponlar Eküri.com'da! Uzman yorumcularımızın hazır altılı ganyan, sıralı ikili, ganyan kuponlarıyla hemen oyna kazanmaya başla!",
        keywords: "6lı, altılı kuponlar, hazır kuponlar, at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "At Yarışı Hazır Kuponlar"
    },
    tipsterComments: {
        url: "/yazar-yorumlari",
        title: "At Yarışı Yorumları | Eküri.com",
        description: "Detaylı at yarışı analizleri Eküri.com'da! Altılı ganyan, sıralı ikili, çifte sıralı ikili, yarış analizleri ve uzman yorumlarıyla kazanmak için hemen üye ol.",
        keywords,
        breadcrumbTitle: " At Yarışı Uzman Yorumları"
    },
    register: {
        url: "/uye-ol",
        title: "Hemen Üye Ol | Eküri.com",
        description: "TJK at yarışı Eküri.com'da! Hemen üye ol, kazandıran kupon ve kampanyalarla at yarışı heyecanını doyasıya yaşa!",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Üye Ol"
    },
    resetPassword: {
        url: "/sifre-sifirla",
        title: "ŞİFREMİ UNUTTUM | Eküri.com ",
        description: "Şifre İşlemleri",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Şifre Sıfırla"

    },
    changePassword: {
        url: "/sifre-degistir",
        title: "ŞİFRE DEĞİŞTİR | Eküri.com ",
        description: "Üyelik Şifre İşlemleri.",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Şifre Değiştir"

    },
    verifyEmail: {
        url: "/eposta-dogrula",
        title: "E-POSTA DOĞRULA | Eküri.com ",
        description: "E-Posta Doğrulama İşlemleri ",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "E-posta Doğrula"

    },
    betting: {
        url: "/tjk-bahis-yap",
        title: "TJK At Yarışı Bülten - TJK At Yarışı Oyna | Eküri.com",
        description: "Günlük yurt içi, yurt dışı at yarışı bülteni, detaylı istatistikler ve uzman yorumcu kadrosuyla Eküri.com'da hemen kupon yap..",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "TJK At Yarışı Bülten - TJK At Yarışı Oyna"
    },
    program: {
        url: "/yaris-programi",
        title: "ekuri.com, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
        description: "ekuri.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Program"
    },
    possibles: {
        url: "/muhtemeller",
        title: "At Yarışı Muhtemeller - TJK Muhtemeller | Eküri.com",
        description: "Yurt içi - Yurt dışı TJK muhtemel bahis oranları Eküri.com'da! Muhtemel bahis oranlarını anlık takip et, kuponunu oyna.",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: " At Yarışı Muhtemeller - TJK Muhtemeller "
    },
    agf: {
        url: "/agf",
        title: "At Yarışı AGF - TJK AGF | Eküri.com",
        description: "Yeni Nesi Ganyan Eküri.com'da AGF tablosunu incele, kuponunu yap!",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "At Yarışı AGF - TJK AGF"
    },
    results: {
        url: "/yaris-sonuclari",
        title: "AT YARIŞI SONUÇLARI, AT YARIŞI, BAHİS, TJK SONUÇLARI, HİPODROM, AT YARIŞI PROGRAMI",
        description: "ekuri.com , eşsiz at yarışı deneyimi sunar. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Sonuçlar"
    },
    campaigns: {
        url: "/kampanyalar",
        title: "Kampanyalar | Eküri.com",
        description: "Kazandıran kampanyalar Eküri.com'da. Hemen üye ol, güçlü istatistikler ve uzman yorumlarıyla kupon yap!",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Kampanyalar"
    },
    reportedJokeys: {
        url: "/tjk-raporlu-cezalı-jokeyler",
        title: "TJK Raporlu & Cezalı Jokeyler | Eküri.com ",
        description: "Yeni Nesil Ganyan Eküri.com. TJK sonuçları, at yarışı programı, hipodromlar ve daha fazlası için hemen ziyaret edin.",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Raporlu Jokeyler"
    },
    horseStats: {
        url: "/tjk-kosmayan-atlar-jokey-degisikligi",
        title: "Jokey Değişikliği & Koşmayan Atlar | Eküri.com",
        description: " TJK at yarışı programı, jokey değişikliği & koşmayan atlar, TJK sonuçları, istatistikler ve daha fazlası Eküri.com'da! ",
        keywords: "at yarışı, tjk, tjk sonuçları, at yarışı programı, hipodrom, türkiye jokey kulübü, jokey, at, yarış, ekuri",
        breadcrumbTitle: "Jokey Değişikliği"
    }
}

export default paths;
