import React, {useEffect, useState} from "react";
import {
    homeHippodrome,
    homeHippodromeSubject,
} from "../../store/misc.store";

import {ApiService} from "../../services/http.service";
import {possiblesUrlPrefix} from "../../constants/global.vars";
import _ from "lodash";
import iconFather from "../../assets/feather/father-at-yarisi.svg";
import {HippodromeWidget} from "../../shared/hippodrome/hippodrome";
import {useParams} from "react-router-dom";
import {availableHippodromes} from "../../store/bulletin.store";
import {Hippodrome} from "../../models/bulletin.models";
import {tjkBetSlipOptions} from "../../services/betslip.service";
import {isMobile} from "../../App";
import iconClock from "../../assets/feather/clock.svg";
import loadingGif from "../../assets/img/loading.gif";

export function PossiblesPage() {

    const params: any = useParams();
    const [possiblesData, setPossiblesData] = useState<any>(null)
    const [shownRun, setShownRun] = useState<any>(null);
    const [hippodrome, setHippodrome] = useState<any>(homeHippodrome)


    useEffect(() => {

        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            setHippodrome(homeHippodrome);
            if (homeHippodrome.nextRun) {
                setShownRun(homeHippodrome?.nextRun);
            }else{
                setShownRun(homeHippodrome?.runs[0]);
            }
        })

        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }

    }, []);


    useEffect(() => {
        if (params.hippodrome){
            const _hippodrome = availableHippodromes.find((hippodrome: Hippodrome) => hippodrome.keyword.toLowerCase() === params.hippodrome.toLowerCase());
            setHippodrome(_hippodrome);
        }

        let api = new ApiService()
        api.start("get", possiblesUrlPrefix, null, false).then((res: any) => {
            if (res.status) {
                setPossiblesData(res.data);

                if (res.data && res.data.length > 0 && res.data[0].runs) {
                    if (hippodrome && params.run){

                        try {
                            let rn = hippodrome.runs.find((r: any) => Number(r.no) === Number(params.run))
                            if (rn) {
                                console.log('setShownRun1');
                                setShownRun(rn)
                            } else {
                                console.log('setShownRun2');
                                setShownRun(tjkBetSlipOptions.hippodrome.runs[0]);
                            }
                        }catch (e:any){
                            const _hippodrome = availableHippodromes.find((hippodrome: Hippodrome) => hippodrome.keyword.toLowerCase() === res.data[0].keyword.toLowerCase() && hippodrome._date === res.data[0]._date);
                            if (_hippodrome){
                                setHippodrome(_hippodrome);
                            }
                            console.log('setShownRun3');
                            setShownRun(res.data[0].runs[0]);
                        }

                    } else {
                        const _hippodrome = availableHippodromes.find((hippodrome: Hippodrome) => hippodrome.keyword.toLowerCase() === res.data[0].keyword.toLowerCase() && hippodrome._date === res.data[0]._date);
                        if (_hippodrome){
                            setHippodrome(_hippodrome);
                        }
                        if (_hippodrome && _hippodrome.nextRun){
                            setShownRun(res.data[0].runs[_hippodrome.nextRun.no]);
                        }else{
                            setShownRun(res.data[0].runs[0]);
                        }

                    }
                } else {
                    console.log('setShownRun5');
                    setShownRun(null);
                }
            }

        });

    }, []);


    const colorHorses = (h1: any, h2: any) => {
        let allHorses: any = document.getElementsByClassName(`line`)
        if (allHorses) {
            for (let e of allHorses) {
                e.classList.remove('high')
                e.classList.remove('low')
            }
        }
        if (h2 === null) {
            let firstHorses: any = document.getElementsByClassName(`first-horse-${h1}`)
            if (firstHorses) {
                for (let e of firstHorses) {
                    // console.log(e)
                    e.classList.add('high')
                    // e.style.backgroundColor = '#CC000088';
                }
            }

            let secondHorses: any = document.getElementsByClassName(`second-horse-${h1}`)
            if (secondHorses) {
                for (let e of secondHorses) {
                    // console.log(e)
                    e.classList.add('low')

                    // e.style.backgroundColor = '#CC000044';
                }
            }

        } else {

            let firstHorses: any = document.getElementsByClassName(`first-horse-${h1} second-horse-null`)
            if (firstHorses) {
                for (let e of firstHorses) {
                    // console.log(e)
                    // e.style.backgroundColor = '#CC000088';
                    e.classList.add('high')
                }
            }

            let secondHorses: any = document.getElementsByClassName(`first-horse-${h2} second-horse-null`)
            if (secondHorses) {
                for (let e of secondHorses) {
                    // console.log(e)
                    // e.style.backgroundColor = '#CC000044';
                    e.classList.add('low')
                }
            }

            let overHorses: any = document.getElementsByClassName(`first-horse-${h1} second-horse-${h2}`)
            if (overHorses) {
                for (let e of overHorses) {
                    // e.style.backgroundColor = '#CC000088';
                    e.classList.add('high')
                }
            }
        }
    }

    let eqStyles: any = {
        "1": {transform: 'scaleX(-1)', marginLeft: 5, filter: 'brightness(0)'},
        "2": {
            transform: 'scaleX(-1)',
            marginLeft: 5,
            filter: 'invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)'
        },
        "3": {transform: 'scaleX(-1)', marginLeft: 5},
    }

    if (!hippodrome || !possiblesData) {
        return <>
            <HippodromeWidget/>
            <div className="container ">
                <div className={"d-flex justify-content-center align-items-center w-100"}>
                    <img src={loadingGif}/>
                </div>
            </div>
        </>
    }

    if (possiblesData.length === 0) {
        return <>
            <HippodromeWidget/>
            <div className="container ">
                <p className="p-4 text-center"><img alt={"clock"} src={iconClock} width={64}/> <br/><br/>
                    Henüz Muhtemeller verileri açıklanmamış olabilir lütfen kısa bir süre sonra tekrar deneyiniz.</p>
            </div>
        </>
    }

    // console.log("shownRun.no", shownRun.no)
    // console.log("Data", hippodrome.keyword, possiblesData.find((el:any)=>el.keyword===hippodrome.keyword))

    let possibles: any = possiblesData.find((el: any) => el.keyword === hippodrome.keyword)?.runs?.find((p: any) => p.no === shownRun.no)


    return <>

        <HippodromeWidget/>


        <div className={`container ${isMobile ? 'p-0' : ''}`}>
            <div className={"table-wrapper mt-2"} style={{marginBottom: '150px'}}>
                {hippodrome.runs && <div className={"run-tabs possibles-run-wrapper"}>
                    {hippodrome.runs.map((run: any, keyRun: number) => {
                        return (
                            <div key={`runItem-${keyRun}`}
                                 className={`run-tab-item ${shownRun === run ? 'active' : ''}`}
                                 onClick={() => {
                                     setShownRun(run);

                                 }}>
                                <p className={"text-small text-white text-bolder"}>{run.no}.</p>
                                <p className={"text-xsmall text-white"}>Koşu</p>

                            </div>)
                    })}
                </div>}
                {possibles && possibles.wagers && possibles.wagers.length > 0 &&
                    <div className={"w-100 d-inline-flex flex-nowrap possibles-table"}>
                        {possibles.wagers?.map((r: any, key: number) => {
                            return (
                                <div className="col runs" key={`agf-runs-r-${key}`}>
                                    <div className="caption">
                                        {r.wager}
                                    </div>
                                    {r.possibilities.map((p: any, pkey: number) => {
                                        let minGanyan = _.orderBy(r.possibilities, [(a: any) => Number(a.ganyan)], ['asc'])[0]
                                        return (<div className={`
                                        ${key === 0 ? 'line' : 'line'} 
                                        ${key === 0 && Number(minGanyan.ganyan) === Number(p.ganyan) ? 'fw-bold text-success' : ''} 
                                        first-horse-${p.result1} 
                                        second-horse-${p.result2} 
                                        d-flex justify-content-start`} style={{padding: isMobile ? "12px 5px" : "12px"}} onMouseOver={() => {
                                            colorHorses(p.result1, p.result2)
                                        }} key={`possibilities-${pkey}`}>
                                            <div className="w-100">
                                                <div className="text-xxsmall possible-horse-name">
                                                    <div className="d-flex">
                                                        <span
                                                            className={`possible-horse-name ${key === 0 && Number(minGanyan.ganyan) === Number(p.ganyan) ? 'text-success' : ''} `}
                                                            style={{margin: "auto"}}> {p.result1}{key !== 0 && <> - {p.result2}</>} {!isMobile && p.horse_name}  </span>
                                                        {key === 0 && ["1", "2", "3"].includes(p.stablemate) ?
                                                            <img alt={"iconFather"} width={"11"} height={"11"}
                                                                 src={iconFather}
                                                                 style={eqStyles[p.stablemate]}/> : null}
                                                        {p.ganyan === '-' &&
                                                            <span className=" ms-1 items-items-center d-flex"
                                                                  style={{margin: "auto"}}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16" fill="none">
                                                                <g clipPath="url(#clip0_7388_54912)">
                                                                    <path
                                                                        d="M5.21362 10.7067C3.97829 8.84351 4.85022 7.38868 4.85022 7.38868C4.64738 7.32743 4 7.76084 4 7.76084C4.36397 6.39584 6.36725 5.23676 6.36725 5.23676C6.14499 5.15334 5.5176 5.31843 5.5176 5.31843C7.15691 3.70668 9.16019 4.30634 9.16019 4.30634C9.22076 3.95401 10.2127 3.33334 10.2127 3.33334C10.111 3.43601 10.0698 3.99543 10.0698 3.99543C10.1721 3.80876 10.4967 3.62268 10.4967 3.62268C10.3144 4.05668 10.4967 4.98768 10.4967 4.98768C11.0223 5.52609 11.6092 7.38868 11.6092 7.38868L11.832 7.71943C12.116 8.13359 12.0114 8.78518 11.7309 8.93976C10.6789 9.52076 10.5767 8.46434 10.5767 8.46434C9.70701 8.36109 9.05906 7.36768 9.05906 7.36768C7.886 7.98776 8.53909 9.73893 9.04591 10.4109C9.69672 11.2725 9.21962 12.6667 9.21962 12.6667C9.21962 12.6667 6.43296 12.5488 5.21362 10.7067Z"
                                                                        fill="#353535">
                                                                    </path>
                                                                    <path
                                                                        d="M3.28634 3.28668L12.713 12.7133M14.6663 8.00001C14.6663 11.6819 11.6816 14.6667 7.99967 14.6667C4.31778 14.6667 1.33301 11.6819 1.33301 8.00001C1.33301 4.31811 4.31778 1.33334 7.99967 1.33334C11.6816 1.33334 14.6663 4.31811 14.6663 8.00001Z"
                                                                        stroke="#E04C4C" strokeWidth="1.33333"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                        </span>}
                                                    </div>
                                                    <span
                                                        className={`text-xxsmall text-bold  possible-horse-name ${p.ganyan == '-' ? 'text-danger' : ''}  ${key === 0 && Number(minGanyan.ganyan) === Number(p.ganyan) ? 'text-success' : ''} `}
                                                        style={{margin: "auto 0px"}}>{p.ganyan == '-' ? 'Koşmaz' : p.ganyan}</span>
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            )
                        })}
                    </div>}
            </div>
        </div>


    </>
}
