import Modal from "react-modal";
import React from "react";

export function ValidateModal(props: any) {
    return <Modal
        isOpen={props.showValidateModal}
        onRequestClose={() => {
            props.setShowValidateModal(false);
            // reset({iban: "", name: ""});
        }}
        ariaHideApp={false}
        style={{
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                width: '472px',
                maxHeight: 'calc(100% - 40px)',
                marginRight: '-50%',
                maxWidth: ' calc( 100% - 40px )',
                padding: '0px',
                transform: 'translate(-50%, -50%)',
                background: 'var(--bs-white)',
                borderRadius: 'var(--bs-border-radius-xl)',
                border: 0
            },
            overlay: {
                zIndex: 5,
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                backdropFilter: 'blur(10px)',
            }
        }} contentLabel="">


        <div className="modal-header">
            <h3 className="text-lg mb-0 text-white text-bolder text-center w-100">Doğrulama Kodu</h3>
            <svg onClick={() => props.setShowValidateModal(false)} width="24" height="24" viewBox="0 0 24 24"
                 fill="white"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"></path>
            </svg>
        </div>

        <div className="modal-body ">
            <div className="pt-4 mb-4 pb-1">
                <p className="text-md  text-center">
                    <span className="text-md text-primary text-bold">5075980944 </span>numaralı
                    telefona gelen 6 haneli <br/> doğrulama kodunu gir.</p>
            </div>
            <div className=" mb-4 pb-1">
                <div className="otp-input-controller" style={{display: "flex", alignItems: "center"}}>
                    <input
                        autoComplete="off" aria-label="Please enter OTP character 1" className="otp-input"
                        inputMode="numeric" type="tel" value="" style={{width: "1em", textAlign: "center"}}/>
                    <span className="otp-input-separator"></span>
                    <input autoComplete="off"
                           className="otp-input"
                           inputMode="numeric" type="tel"
                           style={{width: "1em", textAlign: "center"}}/>
                    <span className="otp-input-separator"></span>
                    <input autoComplete="off"
                           className="otp-input"
                           inputMode="numeric" type="tel"
                           style={{width: "1em", textAlign: "center"}}/>
                    <span className="otp-input-separator"></span>
                    <input autoComplete="off"

                           className="otp-input"
                           inputMode="numeric" type="tel"
                           style={{width: "1em", textAlign: "center"}}/>
                    <span className="otp-input-separator"></span>
                    <input autoComplete="off"

                           className="otp-input"
                           inputMode="numeric" type="tel"
                           style={{width: "1em", textAlign: "center"}}/>
                    <span className="otp-input-separator"></span>
                    <input autoComplete="off"

                           className="otp-input"
                           inputMode="numeric" type="tel"
                           style={{width: "1em", textAlign: "center"}}/>
                </div>
            </div>
            <div className="row mb-4">
                <div className=" col-12  d-flex justify-content-center align-items-center mb-1">
                    <p className="text-small">Kalan Süre</p>
                </div>
                <div className=" col-12  d-flex justify-content-center align-items-center">
                    <p className="text-xl text-primary text-bold">02:54</p>
                </div>
            </div>
            <div className="row mt-6">
                <button className="button xs secondary filled w-100" data-testid="custom-button">
                    <p className="text-small text-white text-bold text-center">Devam</p>
                </button>
            </div>
        </div>


    </Modal>
}
