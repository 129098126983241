import {homeHippodrome, homeHippodromeSubject, widgetStatus, widgetStatusSubject} from "../../../store/misc.store";
import {useForceUpdate} from "../../../services/misc.functions";
import React, {useEffect, useState} from "react";
import {ApiService} from "../../../services/http.service";
import {changedJokeyUrlPrefix} from "../../../constants/global.vars";
import infoIcon from "../../../assets/icons/info.svg"
import {isMobile} from "../../../App";

let hippodromeList: any = [];

export function ChangedJockeys() {
    const forceUpdate = useForceUpdate();
    // const [data, setData] = useState<any>([]);
    const [jokeyChanges, setJockeyChanges] = useState<any>([]);
    const [showDetail, setShowDetail] = useState(!isMobile);
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            if (hippodromeList.length === 0) {
                getData();
            } else {
                getHippodromejokeys();
            }

        })
        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }
    }, []);


    const getData = () => {

        // let cachedData = Storage.get(`changedJokeys`);
        // if (!cachedData) {
        let api = new ApiService()
        api.start('get', changedJokeyUrlPrefix, null, false)
            .then((res: any) => {
                if (res.status) {
                    hippodromeList = res.data;
                    setTimeout(() => {
                        getHippodromejokeys();
                    }, 1000);

                } else {
                    hippodromeList = [];
                }
            })
        // } else {
        //     setData(cachedData);
        // }
    }

    const getHippodromejokeys = () => {
        var selectedHippodromeJokey;
        for (const hippodromeListElement of hippodromeList) {
            if (hippodromeListElement.keyword === homeHippodrome.keyword) {
                selectedHippodromeJokey = hippodromeListElement;
            }
        }
        if (selectedHippodromeJokey) {
            setJockeyChanges(selectedHippodromeJokey.jockey_changes);
        }
    }

    const getHorseName = (runNo: any, horseNo: any) => {
        try {
            var selectedRun = homeHippodrome.runs.filter((el: any) => Number(el.no) === Number(runNo));
            var selectedHorse = selectedRun[0].horseMap[horseNo];
            return selectedHorse.name;
        } catch (e: any) {
            return horseNo;
        }
    }

    return <div className={"home-widget"}>
        <div onClick={() => {
            if (!isMobile) {
                widgetStatus.isOpen = !widgetStatus.isOpen;
                widgetStatusSubject.next(true);
            } else {
                setShowDetail((prevShowDetail) => !prevShowDetail);
            }

        }} className={`header ${showDetail ? 'isOpen' : ''}`}>
            <div className={"d-flex align-items-center"}>

                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                        d="M13.4617 8.28617C13.1029 8.30563 12.7388 8.32852 12.3752 8.3568C11.246 8.44412 10.2755 8.56382 9.45953 8.71687C8.12174 8.9368 6.8249 9.24405 5.73233 9.67377C3.86617 10.4073 2.43016 11.1981 1.40318 11.8571C0.841059 10.0764 -0.164326 2.52496 6.70732 1.69053C6.82938 1.2909 7.1781 1 7.59449 1C7.99041 1 8.32714 1.26212 8.46439 1.63134C11.5097 1.81137 13.0855 3.61677 13.8632 6.72458L17 8.18462C16.129 8.18201 14.8739 8.2 13.4617 8.28617Z"
                        fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                    <path
                        d="M9.57178 12.903C8.73692 12.7243 6.73878 12.1415 6.79589 10.6646C6.81413 10.195 7.48502 9.8256 8.54176 9.5437L9.57178 12.903Z"
                        fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                    <path
                        d="M9.12723 9.41551C10.5477 9.16108 12.0477 9.01195 13.4111 8.92627L13.4415 8.95718L14.3279 11.1342C14.35 11.1846 14.3604 11.2427 14.3604 11.3023C14.3604 11.5409 14.1719 11.7348 13.9374 11.7348H13.2328V13.2363C13.2328 14.4074 12.3062 15.3559 11.1617 15.3559H10.9489L9.12723 9.41551Z"
                        fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                    <path
                        d="M7.85522 12.9649L2.04815 12.1939C3.02202 11.5933 4.32158 10.9011 5.95211 10.2601C6.08584 10.2077 6.2231 10.1568 6.36339 10.1079C6.26101 10.2744 6.2063 10.4518 6.19894 10.6405C6.15367 11.8111 6.97797 12.5309 7.85522 12.9649Z"
                        fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                    <path d="M9.85238 13.8206L10.3369 15.4V17H4.56711V14.2369L4.56823 13.1284L9.85238 13.8206Z"
                          fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                </svg>
                <span className={"text-sm text-white ms-2"}>Jokey Değişikliği</span>
            </div>
            <div></div>
            <div>
                {isMobile &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                         className={` ${showDetail ? 'rotate-svg' : ''}`}>
                        <path d="M4 6L8 10L12 6" stroke={isMobile && !showDetail ? '#004742' : "white"} strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"></path>
                    </svg>}
            </div>

        </div>
        {showDetail && <div className={"body"}>
            {jokeyChanges.length > 0 &&
                <table className={"table w-100 widget-table changed-jockey-table"}>
                    <thead>
                    <tr>
                        <th>Koşu</th>
                        <th>At</th>
                        <th>Jokey</th>

                    </tr>
                    </thead>
                    <tbody>
                    {jokeyChanges?.map((jockey: any, runsIndex: number) => {
                        return (<React.Fragment key={`notRuningHorse-i-${runsIndex}`}>
                            <tr>
                                <td className="d-flex justify-content-center align-items-center align-content-center">{jockey.run_no}</td>
                                <td className="d-flex justify-content-center align-items-center align-content-center">

                                    ({jockey.horse_no}) <span className={"ms-1 text-truncate"}>{getHorseName(jockey.run_no, jockey.horse_no)}</span>
                                </td>
                                <td>
                                    <p className="text-xxsmall fw-medium d-flex align-items-center">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" className="me-2">
                                            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white"></rect>
                                            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5"
                                                  stroke="#E8E6D0"></rect>
                                            <path
                                                d="M12.9744 9.5241C12.7352 9.53708 12.4925 9.55233 12.2501 9.57119C11.4973 9.62941 10.8503 9.7092 10.3063 9.81124C9.41445 9.95786 8.54989 10.1627 7.82151 10.4492C6.5774 10.9382 5.62006 11.4654 4.93541 11.9047C4.56066 10.7176 3.89041 5.6833 8.47151 5.12701C8.55288 4.86059 8.78536 4.66666 9.06295 4.66666C9.3269 4.66666 9.55138 4.8414 9.64288 5.08755C11.6731 5.20757 12.7236 6.41117 13.2421 8.48304L15.3333 9.45641C14.7526 9.45466 13.9159 9.46665 12.9744 9.5241Z"
                                                fill="#353535"></path>
                                            <path
                                                d="M10.3811 12.602C9.8245 12.4828 8.49241 12.0943 8.53048 11.1097C8.54264 10.7967 8.9899 10.5504 9.69439 10.3625L10.3811 12.602Z"
                                                fill="#353535"></path>
                                            <path
                                                d="M10.0844 10.277C11.0314 10.1073 12.0314 10.0079 12.9403 9.95081L12.9606 9.97141L13.5515 11.4228C13.5662 11.4564 13.5732 11.4951 13.5732 11.5349C13.5732 11.6939 13.4475 11.8232 13.2912 11.8232H12.8214V12.8241C12.8214 13.6049 12.2038 14.2372 11.4407 14.2372H11.2989L10.0844 10.277Z"
                                                fill="#353535"></path>
                                            <path
                                                d="M9.23668 12.6433L5.36529 12.1293C6.01454 11.7289 6.88092 11.2674 7.96794 10.8401C8.05709 10.8051 8.14859 10.7712 8.24212 10.7386C8.17387 10.8496 8.13739 10.9679 8.13249 11.0937C8.10231 11.8741 8.65184 12.3539 9.23668 12.6433Z"
                                                fill="#353535"></path>
                                            <path
                                                d="M10.5681 13.2137L10.8911 14.2666V15.3333H7.04458V13.4912L7.04533 12.7523L10.5681 13.2137Z"
                                                fill="#353535"></path>
                                        </svg>
                                        {jockey.jockey_old}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14" fill="none" className="me-1 ms-1">
                                            <path
                                                d="M2.91675 6.99999H11.0834M11.0834 6.99999L7.00008 2.91666M11.0834 6.99999L7.00008 11.0833"
                                                stroke="#004742" strokeWidth="1.66" strokeLinecap="round"
                                                strokeLinejoin="round"></path>
                                        </svg>
                                        {jockey.jockey_new}
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg" className="ms-1">
                                            <rect width="20" height="20" rx="10" fill="#004742"></rect>
                                            <path
                                                d="M12.9744 9.5241C12.7352 9.53708 12.4925 9.55233 12.2501 9.57119C11.4973 9.62941 10.8503 9.7092 10.3063 9.81124C9.41445 9.95786 8.54989 10.1627 7.82151 10.4492C6.5774 10.9382 5.62006 11.4654 4.93541 11.9047C4.56066 10.7176 3.8904 5.6833 8.4715 5.12701C8.55287 4.86059 8.78536 4.66666 9.06295 4.66666C9.32689 4.66666 9.55138 4.8414 9.64288 5.08755C11.6731 5.20757 12.7236 6.41117 13.2421 8.48304L15.3333 9.45641C14.7526 9.45466 13.9159 9.46665 12.9744 9.5241Z"
                                                fill="#FFE700"></path>
                                            <path
                                                d="M10.3811 12.602C9.8245 12.4828 8.49241 12.0943 8.53048 11.1097C8.54264 10.7967 8.9899 10.5504 9.69439 10.3625L10.3811 12.602Z"
                                                fill="#FFE700"></path>
                                            <path
                                                d="M10.0844 10.277C11.0314 10.1073 12.0314 10.0079 12.9403 9.95081L12.9606 9.97141L13.5515 11.4228C13.5662 11.4564 13.5732 11.4951 13.5732 11.5349C13.5732 11.6939 13.4475 11.8232 13.2912 11.8232H12.8214V12.8241C12.8214 13.6049 12.2038 14.2372 11.4407 14.2372H11.2989L10.0844 10.277Z"
                                                fill="#FFE700"></path>
                                            <path
                                                d="M9.23668 12.6433L5.36529 12.1293C6.01454 11.7289 6.88091 11.2674 7.96793 10.8401C8.05709 10.8051 8.14859 10.7712 8.24212 10.7386C8.17386 10.8496 8.13739 10.9679 8.13249 11.0937C8.10231 11.8741 8.65184 12.3539 9.23668 12.6433Z"
                                                fill="#FFE700"></path>
                                            <path
                                                d="M10.5681 13.2137L10.8911 14.2666V15.3333H7.04458V13.4912L7.04533 12.7523L10.5681 13.2137Z"
                                                fill="#FFE700"></path>
                                        </svg>
                                    </p>
                                </td>
                            </tr>
                        </React.Fragment>)
                    })}
                    </tbody>
                </table>}

            {
                jokeyChanges.length === 0 &&
                <div className={"d-flex flex-column justify-content-center align-content-center align-items-center"}
                     style={{minHeight: '380px'}}>
                    <img  alt={"info"} className={"mb-3"} src={infoIcon}/>
                    <p className={"text-green text-xsmall text-bold text-center"}>Jokey Değişikliği Yok</p>
                </div>
            }
        </div>}
    </div>
}
