import React from "react";

export default function FormError(props: any) {
    return <div className="d-flex align-items-center mt-1">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g id="alert-circle" clipPath="url(#clip0_253_216)">
                <path id="Icon"
                      d="M6.99984 4.66699V7.00033M6.99984 9.33366H7.00567M12.8332 7.00033C12.8332 10.222 10.2215 12.8337 6.99984 12.8337C3.77818 12.8337 1.1665 10.222 1.1665 7.00033C1.1665 3.77866 3.77818 1.16699 6.99984 1.16699C10.2215 1.16699 12.8332 3.77866 12.8332 7.00033Z"
                      stroke="#E04C4C" strokeWidth="1.33333" strokeLinecap="round"
                      strokeLinejoin="round"></path>
            </g>
            <defs>
                <clipPath id="clip0_253_216">
                    <rect width="14" height="14" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
        <span
            className="text-xsmall text-danger ms-2">{props.error}</span>
    </div>
}
