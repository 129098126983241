import {MemberMenu, MemberMobileMenu} from "./components/member.menu";
import {isMobile} from "../../App";
import {auth, authSubject} from "../../store/auth.store";
import {useNavigate} from "react-router";
import paths from "../../paths";
import {useForceUpdate} from "../../services/misc.functions";
import {useEffect} from "react";

export function MemberWrapper({children}: any) {

    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        let auth_subscriber = authSubject.subscribe((res: any) => {
            forceUpdate();
        })
        return () => {
            auth_subscriber.unsubscribe();
        }
    }, [])

    if (!auth.member || !auth.member.id) {
        navigate(paths.login.url);
        return <></>
    }


        return (
            <div className={`container`}>
                <div className="member-wrapper">
                    {isMobile? <MemberMobileMenu/> : <MemberMenu/>}
                    <div className="member-page-wrapper">
                        {children}
                    </div>
                </div>
                <div className="member-wrapper-overlay"></div>
            </div>
        );



}



