import React, {useEffect, useState} from "react";
import {
    widgetStatus,
    widgetStatusSubject
} from "../../../store/misc.store";
import {Storage} from "../../../services/localstorege.service";
import {ApiService} from "../../../services/http.service";
import {possiblesUrlPrefix} from "../../../constants/global.vars";
import {isMobile} from "../../../App";
import _ from "lodash";
import iconFather from "../../../assets/feather/father-at-yarisi.svg";
import {useForceUpdate} from "../../../services/misc.functions";


export function Possibles(prop: any) {


    const [possiblesData, setPossiblesData] = useState<any>(Storage.get("possiblesData"))
    const [hippodrome, setHippodrome] = useState<any>(prop.hippodrome);
    const [shownRun, setShownRun] = useState<any>(null);
    const [showDetail, setShowDetail] = useState(false);
    const forceUpdate = useForceUpdate();
    useEffect(() => {

        let widgetStatusSubject_subscription = widgetStatusSubject.subscribe((res: any) => {
            setShowDetail(widgetStatus.isOpen);
        })

        return () => {
            widgetStatusSubject_subscription.unsubscribe();
        }

    }, []);

    useEffect(() => {
        forceUpdate();
        setHippodrome(prop.hippodrome);
        console.log(prop.hippodrome)
        if (prop.hippodrome?.runs){
            setShownRun(prop.hippodrome?.runs[0]);
        }

    }, [prop]);

    useEffect(() => {

        if (!possiblesData) {
            let api = new ApiService()
            api.start("get", possiblesUrlPrefix, null, false).then((res: any) => {
                if (res.status) {
                    setPossiblesData(res.data);
                    // fixme bu süre daha sonra değiştirilebilir, muhtemeller datasını çok sık çekmemek için cache atıyorum
                    Storage.set("possiblesData", res.data, 1 * 60 * 1000);
                }
            })
        }
    }, []);



    const colorHorses = (h1: any, h2: any) => {
        let allHorses: any = document.getElementsByClassName(`line`)
        if (allHorses) {
            for (let e of allHorses) {
                e.classList.remove('high')
                e.classList.remove('low')
            }
        }
        if (h2 === null) {
            let firstHorses: any = document.getElementsByClassName(`first-horse-${h1}`)
            if (firstHorses) {
                for (let e of firstHorses) {
                    // console.log(e)
                    e.classList.add('high')
                    // e.style.backgroundColor = '#CC000088';
                }
            }

            let secondHorses: any = document.getElementsByClassName(`second-horse-${h1}`)
            if (secondHorses) {
                for (let e of secondHorses) {
                    // console.log(e)
                    e.classList.add('low')

                    // e.style.backgroundColor = '#CC000044';
                }
            }

        } else {

            let firstHorses: any = document.getElementsByClassName(`first-horse-${h1} second-horse-null`)
            if (firstHorses) {
                for (let e of firstHorses) {
                    // console.log(e)
                    // e.style.backgroundColor = '#CC000088';
                    e.classList.add('high')
                }
            }

            let secondHorses: any = document.getElementsByClassName(`first-horse-${h2} second-horse-null`)
            if (secondHorses) {
                for (let e of secondHorses) {
                    // console.log(e)
                    // e.style.backgroundColor = '#CC000044';
                    e.classList.add('low')
                }
            }

            let overHorses: any = document.getElementsByClassName(`first-horse-${h1} second-horse-${h2}`)
            if (overHorses) {
                for (let e of overHorses) {
                    // e.style.backgroundColor = '#CC000088';
                    e.classList.add('high')
                }
            }
        }
    }

    let eqStyles: any = {
        "1": {transform: 'scaleX(-1)', marginLeft: 5, filter: 'brightness(0)'},
        "2": {
            transform: 'scaleX(-1)',
            marginLeft: 5,
            filter: 'invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)'
        },
        "3": {transform: 'scaleX(-1)', marginLeft: 5},
    }


    if (!hippodrome || !possiblesData || !shownRun) {
        return <div className="home-widget">
            <div onClick={() => {
                if (!isMobile) {
                    widgetStatus.isOpen = !widgetStatus.isOpen;
                    widgetStatusSubject.next(true);
                } else {
                    setShowDetail((prevShowDetail) => !prevShowDetail);
                }
            }} className={`header ${showDetail ? 'isOpen' : ''}`}>
                <div className={"d-flex align-items-center"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path
                            d="M4.125 1.5C4.125 1.08579 3.78921 0.75 3.375 0.75C2.96079 0.75 2.625 1.08579 2.625 1.5V2.625H1.5C1.08579 2.625 0.75 2.96079 0.75 3.375C0.75 3.78921 1.08579 4.125 1.5 4.125H2.625V5.25C2.625 5.66421 2.96079 6 3.375 6C3.78921 6 4.125 5.66421 4.125 5.25V4.125H5.25C5.66421 4.125 6 3.78921 6 3.375C6 2.96079 5.66421 2.625 5.25 2.625H4.125V1.5Z"
                            fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                        <path
                            d="M4.125 12.75C4.125 12.3358 3.78921 12 3.375 12C2.96079 12 2.625 12.3358 2.625 12.75V13.875H1.5C1.08579 13.875 0.75 14.2108 0.75 14.625C0.75 15.0392 1.08579 15.375 1.5 15.375H2.625V16.5C2.625 16.9142 2.96079 17.25 3.375 17.25C3.78921 17.25 4.125 16.9142 4.125 16.5V15.375H5.25C5.66421 15.375 6 15.0392 6 14.625C6 14.2108 5.66421 13.875 5.25 13.875H4.125V12.75Z"
                            fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                        <path
                            d="M10.45 1.98077C10.3386 1.69112 10.0603 1.5 9.75 1.5C9.43967 1.5 9.16139 1.69112 9.04999 1.98077L7.74936 5.36241C7.52406 5.9482 7.45326 6.117 7.35642 6.25319C7.25924 6.38985 7.13985 6.50925 7.00319 6.60642C6.867 6.70326 6.6982 6.77406 6.11241 6.99936L2.73077 8.29999C2.44112 8.41139 2.25 8.68967 2.25 9C2.25 9.31033 2.44112 9.58861 2.73077 9.70001L6.11241 11.0006C6.6982 11.2259 6.867 11.2967 7.00319 11.3936C7.13985 11.4908 7.25924 11.6102 7.35642 11.7468C7.45326 11.883 7.52406 12.0518 7.74936 12.6376L9.04999 16.0192C9.16139 16.3089 9.43967 16.5 9.75 16.5C10.0603 16.5 10.3386 16.3089 10.45 16.0192L11.7506 12.6376C11.9759 12.0518 12.0467 11.883 12.1436 11.7468C12.2408 11.6102 12.3602 11.4908 12.4968 11.3936C12.633 11.2967 12.8018 11.2259 13.3876 11.0006L16.7692 9.70001C17.0589 9.58861 17.25 9.31033 17.25 9C17.25 8.68967 17.0589 8.41139 16.7692 8.29999L13.3876 6.99936C12.8018 6.77406 12.633 6.70326 12.4968 6.60642C12.3602 6.50925 12.2408 6.38985 12.1436 6.25319C12.0467 6.117 11.9759 5.9482 11.7506 5.36241L10.45 1.98077Z"
                            fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                    </svg>

                    <span className={"text-sm text-white ms-2"}>Muhtemeller</span>
                </div>
                <div></div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                         className={` ${showDetail ? 'rotate-svg' : ''}`}>
                        <path d="M4 6L8 10L12 6" stroke={isMobile && !showDetail ? '#004742' : "white"}
                              strokeWidth="3" strokeLinecap="round"
                              strokeLinejoin="round"></path>
                    </svg>
                </div>

            </div>
        </div>
    }

    if (possiblesData.length === 0) {
        return <>
            <div className="home-widget">
                <div onClick={() => {
                    if (!isMobile) {
                        widgetStatus.isOpen = !widgetStatus.isOpen;
                        widgetStatusSubject.next(true);
                    } else {
                        setShowDetail((prevShowDetail) => !prevShowDetail);
                    }
                }} className={`header ${showDetail ? 'isOpen' : ''}`}>
                    <div className={"d-flex align-items-center"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path
                                d="M4.125 1.5C4.125 1.08579 3.78921 0.75 3.375 0.75C2.96079 0.75 2.625 1.08579 2.625 1.5V2.625H1.5C1.08579 2.625 0.75 2.96079 0.75 3.375C0.75 3.78921 1.08579 4.125 1.5 4.125H2.625V5.25C2.625 5.66421 2.96079 6 3.375 6C3.78921 6 4.125 5.66421 4.125 5.25V4.125H5.25C5.66421 4.125 6 3.78921 6 3.375C6 2.96079 5.66421 2.625 5.25 2.625H4.125V1.5Z"
                                fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                            <path
                                d="M4.125 12.75C4.125 12.3358 3.78921 12 3.375 12C2.96079 12 2.625 12.3358 2.625 12.75V13.875H1.5C1.08579 13.875 0.75 14.2108 0.75 14.625C0.75 15.0392 1.08579 15.375 1.5 15.375H2.625V16.5C2.625 16.9142 2.96079 17.25 3.375 17.25C3.78921 17.25 4.125 16.9142 4.125 16.5V15.375H5.25C5.66421 15.375 6 15.0392 6 14.625C6 14.2108 5.66421 13.875 5.25 13.875H4.125V12.75Z"
                                fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                            <path
                                d="M10.45 1.98077C10.3386 1.69112 10.0603 1.5 9.75 1.5C9.43967 1.5 9.16139 1.69112 9.04999 1.98077L7.74936 5.36241C7.52406 5.9482 7.45326 6.117 7.35642 6.25319C7.25924 6.38985 7.13985 6.50925 7.00319 6.60642C6.867 6.70326 6.6982 6.77406 6.11241 6.99936L2.73077 8.29999C2.44112 8.41139 2.25 8.68967 2.25 9C2.25 9.31033 2.44112 9.58861 2.73077 9.70001L6.11241 11.0006C6.6982 11.2259 6.867 11.2967 7.00319 11.3936C7.13985 11.4908 7.25924 11.6102 7.35642 11.7468C7.45326 11.883 7.52406 12.0518 7.74936 12.6376L9.04999 16.0192C9.16139 16.3089 9.43967 16.5 9.75 16.5C10.0603 16.5 10.3386 16.3089 10.45 16.0192L11.7506 12.6376C11.9759 12.0518 12.0467 11.883 12.1436 11.7468C12.2408 11.6102 12.3602 11.4908 12.4968 11.3936C12.633 11.2967 12.8018 11.2259 13.3876 11.0006L16.7692 9.70001C17.0589 9.58861 17.25 9.31033 17.25 9C17.25 8.68967 17.0589 8.41139 16.7692 8.29999L13.3876 6.99936C12.8018 6.77406 12.633 6.70326 12.4968 6.60642C12.3602 6.50925 12.2408 6.38985 12.1436 6.25319C12.0467 6.117 11.9759 5.9482 11.7506 5.36241L10.45 1.98077Z"
                                fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                        </svg>

                        <span className={"text-sm text-white ms-2"}>Muhtemeller</span>
                    </div>
                    <div></div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                             className={` ${showDetail ? 'rotate-svg' : ''}`}>
                            <path d="M4 6L8 10L12 6" stroke={isMobile && !showDetail ? '#004742' : "white"}
                                  strokeWidth="3" strokeLinecap="round"
                                  strokeLinejoin="round"></path>
                        </svg>
                    </div>

                </div>
                {showDetail && <div className={"body"}>
                    <p className="p-4 text-center"><br/><br/>
                        Henüz muhtemel verileri açıklanmamış olabilir lütfen kısa bir süre sonra tekrar deneyiniz.</p>
                </div>}
            </div>

        </>
    }


    let possibles: any = possiblesData.find((el: any) => el.keyword === hippodrome.keyword)?.runs?.find((p: any) => p.no === shownRun.no)


    return <div className={"home-widget"}>
        <div onClick={() => {
            if (!isMobile) {
                widgetStatus.isOpen = !widgetStatus.isOpen;
                widgetStatusSubject.next(true);
            } else {
                setShowDetail((prevShowDetail) => !prevShowDetail);
            }

        }} className={`header ${showDetail ? 'isOpen' : ''}`}>
            <div className={"d-flex align-items-center"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                        d="M4.125 1.5C4.125 1.08579 3.78921 0.75 3.375 0.75C2.96079 0.75 2.625 1.08579 2.625 1.5V2.625H1.5C1.08579 2.625 0.75 2.96079 0.75 3.375C0.75 3.78921 1.08579 4.125 1.5 4.125H2.625V5.25C2.625 5.66421 2.96079 6 3.375 6C3.78921 6 4.125 5.66421 4.125 5.25V4.125H5.25C5.66421 4.125 6 3.78921 6 3.375C6 2.96079 5.66421 2.625 5.25 2.625H4.125V1.5Z"
                        fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                    <path
                        d="M4.125 12.75C4.125 12.3358 3.78921 12 3.375 12C2.96079 12 2.625 12.3358 2.625 12.75V13.875H1.5C1.08579 13.875 0.75 14.2108 0.75 14.625C0.75 15.0392 1.08579 15.375 1.5 15.375H2.625V16.5C2.625 16.9142 2.96079 17.25 3.375 17.25C3.78921 17.25 4.125 16.9142 4.125 16.5V15.375H5.25C5.66421 15.375 6 15.0392 6 14.625C6 14.2108 5.66421 13.875 5.25 13.875H4.125V12.75Z"
                        fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                    <path
                        d="M10.45 1.98077C10.3386 1.69112 10.0603 1.5 9.75 1.5C9.43967 1.5 9.16139 1.69112 9.04999 1.98077L7.74936 5.36241C7.52406 5.9482 7.45326 6.117 7.35642 6.25319C7.25924 6.38985 7.13985 6.50925 7.00319 6.60642C6.867 6.70326 6.6982 6.77406 6.11241 6.99936L2.73077 8.29999C2.44112 8.41139 2.25 8.68967 2.25 9C2.25 9.31033 2.44112 9.58861 2.73077 9.70001L6.11241 11.0006C6.6982 11.2259 6.867 11.2967 7.00319 11.3936C7.13985 11.4908 7.25924 11.6102 7.35642 11.7468C7.45326 11.883 7.52406 12.0518 7.74936 12.6376L9.04999 16.0192C9.16139 16.3089 9.43967 16.5 9.75 16.5C10.0603 16.5 10.3386 16.3089 10.45 16.0192L11.7506 12.6376C11.9759 12.0518 12.0467 11.883 12.1436 11.7468C12.2408 11.6102 12.3602 11.4908 12.4968 11.3936C12.633 11.2967 12.8018 11.2259 13.3876 11.0006L16.7692 9.70001C17.0589 9.58861 17.25 9.31033 17.25 9C17.25 8.68967 17.0589 8.41139 16.7692 8.29999L13.3876 6.99936C12.8018 6.77406 12.633 6.70326 12.4968 6.60642C12.3602 6.50925 12.2408 6.38985 12.1436 6.25319C12.0467 6.117 11.9759 5.9482 11.7506 5.36241L10.45 1.98077Z"
                        fill={isMobile && !showDetail ? '#004742' : "#ffe700"}></path>
                </svg>
                <span className={"text-sm text-white ms-2"}>Muhtemeller</span>
            </div>
            <div></div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                     className={` ${showDetail ? 'rotate-svg' : ''}`}>
                    <path d="M4 6L8 10L12 6" stroke={isMobile && !showDetail ? '#004742' : "white"} strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"></path>
                </svg>
            </div>

        </div>
        {showDetail && <div className={"body"}>
            <div className={"run-tabs possible-widget"}>
                {hippodrome.runs.map((run: any, keyRun: number) => {
                    return (
                        <div key={`runItem-${keyRun}`} className={`run-tab-item ${shownRun === run ? 'active' : ''}`}
                             onClick={() => {
                                 setShownRun(run);


                             }}>
                            <p className={"text-small text-white text-bolder"}>{run.no}.</p>
                            <p className={"text-xsmall text-white"}>Koşu</p>

                        </div>)
                })}
            </div>

            {possibles && possibles.wagers && possibles.wagers.length > 0 &&
                <div className={"w-100 d-inline-flex flex-nowrap possibles-table"}>
                    {possibles.wagers?.map((r: any, key: number) => {
                        return (
                            <div className="col runs" key={`agf-runs-r-${key}`}>
                                <div className="caption">
                                    {r.wager}
                                </div>
                                {r.possibilities.map((p: any, pkey: number) => {
                                    let minGanyan = _.orderBy(r.possibilities, [(a: any) => Number(a.ganyan)], ['asc'])[0]
                                    return (<div className={`
                                        ${key === 0 ? 'line' : 'line'} 
                                        ${key === 0 && Number(minGanyan.ganyan) === Number(p.ganyan) ? 'fw-bold text-success' : ''} 
                                        first-horse-${p.result1} 
                                        second-horse-${p.result2} 
                                        p-1 p-lg-2 d-flex justify-content-start`} onMouseOver={() => {
                                        colorHorses(p.result1, p.result2)
                                    }} key={`possibilities-${pkey}`}>

                                        <span
                                            className={"text-xxsmall"}>{p.result1}{key !== 0 && <> - {p.result2}</>} </span>
                                        <span
                                            className={"text-xxsmall  text-bold"}> &nbsp;/ {p.ganyan === '-' ? (isMobile ? 'K' : 'KOŞMAZ') : p.ganyan}</span>
                                        {key === 0 && ["1", "2", "3"].includes(p.stablemate) ?
                                            <img alt={"iconFather"} src={iconFather}
                                                 style={eqStyles[p.stablemate]}/> : null}
                                    </div>)
                                })}
                            </div>
                        )
                    })}
                </div>}
        </div>}
    </div>
}
