import {useNavigate} from "react-router-dom";
import paths from "../../../paths";
import {userLogout} from "../../../services/auth.service";
import {tr} from "date-fns/locale/tr";
import {useEffect, useState} from "react";

export function MemberMenu() {
    const navigate = useNavigate();
    const pathname = window.location.pathname;

    return (
        <div className="member-menu-wrapper">
            <p
                className="text-md text-bold mb-2 ps-2">Banka İşlemleri</p>
            <button
                onClick={() => {
                    navigate(paths.deposit.url);
                }}
                className={`button xs secondary filled member-menu-item ${paths.deposit.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Para Yatır</p>
            </button>
            <button
                onClick={() => {
                    navigate(paths.withdraw.url);
                }}
                className={`button xs secondary filled member-menu-item ${paths.withdraw.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Para Çek</p>
            </button>

            <button onClick={() => {
                navigate(paths.memberBankAccounts.url);
            }}
                    className={`button xs secondary filled member-menu-item ${paths.memberBankAccounts.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Banka Hesaplarım</p>
            </button>

            <button onClick={() => {
                navigate(paths.memberTransactions.url);
            }}
                    className={`button xs secondary filled member-menu-item ${paths.memberTransactions.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Hesap Hareketlerim</p>
            </button>
            <div style={{height: "32px"}}>
            </div>
            <p className="text-md text-bold mb-2 ps-2">Ayarlar</p>
            <button onClick={() => {
                navigate(paths.memberInfo.url);
            }}
                    className={`button xs secondary filled member-menu-item ${paths.memberInfo.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Profil Ayarları</p>
            </button>
            <button onClick={() => {
                navigate(paths.notificationInfo.url);
            }}
                    className={`button xs secondary filled member-menu-item ${paths.notificationInfo.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">İletişim Ayarları</p>
            </button>
            <button onClick={() => {
                navigate(paths.support.url);
            }}
                    className={`button xs secondary filled member-menu-item ${paths.support.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Destek Taleplerim</p>
            </button>
            <div style={{height: "32px"}}>
            </div>
            <p className="text-md text-bold mb-2 ps-2">Sıkça Sorulan Sorular</p>
            <button onClick={() => {
                navigate(paths.supportFAQ.url);
            }}
                    className={`button xs secondary filled member-menu-item ${paths.supportFAQ.url === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Tümü</p>
            </button>
            <button onClick={() => {
                navigate(paths.supportFAQ.url + '/1/');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.supportFAQ.url + '/1/' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Üyelik</p>
            </button>
            <button onClick={() => {
                navigate(paths.supportFAQ.url + '/2/');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.supportFAQ.url + '/2/' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Para Yatır</p>
            </button>
            <button onClick={() => {
                navigate(paths.supportFAQ.url + '/3/');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.supportFAQ.url + '/3/' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Para Çek</p>
            </button>
            <button onClick={() => {
                navigate(paths.supportFAQ.url + '/4/');
            }}
                    className={`button xs secondary filled member-menu-item ${paths.supportFAQ.url + '/4/' === pathname ? 'active' : ''}`}>
                <p className="text-xsmall">Nasıl Oynarım?</p>
            </button>
            <div style={{height: "32px"}}>
            </div>
            <button onClick={() => {
                userLogout();
            }} className="button xs secondary filled  ">Çıkış Yap
            </button>
        </div>
    );
}

export function MemberMobileMenu() {
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const [selectedKey, setSelectedKey] = useState<any>(null);
    const mobileMenuGroup: any = {
        'Banka İşlemleri': [paths.deposit, paths.withdraw, paths.memberBankAccounts, paths.memberTransactions],
        'Ayarlar': [paths.memberInfo, paths.notificationInfo],
        'Sıkça Sorulan Sorular': [paths.supportFAQ]
    };


    useEffect(() => {

        for (const key of Object.keys(mobileMenuGroup)) {
            var index = mobileMenuGroup[key].findIndex((el: any) => el.url.includes(pathname))
            if (index !== -1) {
                setSelectedKey(key);
            }

        }
    }, []);


    return <div className="member-mobile-menu-wrapper">
        <div className="container">
            <p className="text-lg text-white text-bold  pt-3 pb-3">{selectedKey}</p>

        </div>
        {selectedKey && <div className="member-mobile-menu-inner-wrapper">
            {
                mobileMenuGroup[selectedKey].map((item: any, index: any) => {
                    return <div key={"mobile-user-menu" + index}
                                className={`member-mobile-menu-options ${item.url === pathname ? 'member-mobile-menu-active' : ''}`}
                                onClick={() => {
                                    navigate(item.url);
                                }}>
                        {item.breadcrumbTitle}
                    </div>
                })
            }


            {/*  <div onClick={() => {
                navigate(paths.withdraw.url);
            }}
                 className={`member-mobile-menu-options ${paths.withdraw.url === pathname ? 'member-mobile-menu-active' : ''}`}>
                Para Çek
            </div>
            <div onClick={() => {
                navigate(paths.memberBankAccounts.url);
            }}
                 className={`member-mobile-menu-options ${paths.memberBankAccounts.url === pathname ? 'member-mobile-menu-active' : ''}`}>
                Banka Hesaplarım
            </div>
            <div onClick={() => {
                navigate(paths.memberTransactions.url);
            }}
                 className={`member-mobile-menu-options ${paths.memberTransactions.url === pathname ? 'member-mobile-menu-active' : ''}`}>
                Hesap Hareketlerim
            </div>

            <div onClick={() => {
                navigate(paths.memberInfo.url);
            }}
                 className={`member-mobile-menu-options ${paths.memberInfo.url === pathname ? 'member-mobile-menu-active' : ''}`}>
                Profil Ayarları
            </div>
            <div onClick={() => {
                navigate(paths.notificationInfo.url);
            }}
                 className={`member-mobile-menu-options ${paths.notificationInfo.url === pathname ? 'member-mobile-menu-active' : ''}`}>
                İletişim Ayarları
            </div>

            <div onClick={() => {
                navigate(paths.supportFAQ.url);
            }}
                 className={`member-mobile-menu-options ${paths.supportFAQ.url === pathname ? 'member-mobile-menu-active' : ''}`}>
                Tümü
            </div>*/}

            <div onClick={() => {
                userLogout();
            }} className={`member-mobile-menu-options`}>
                Çıkış Yap
            </div>
        </div>}
    </div>
}

