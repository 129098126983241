import {useForceUpdate} from "../../../services/misc.functions";
import React, {useEffect} from "react";
import {betSlipActionSubject, getLegsSelections, tjkBetSlipOptions} from "../../../services/betslip.service";
import {isMobile} from "../../../App";
import {homeHippodrome} from "../../../store/misc.store";

export function BettingDataTabs(prop: any) {

    const selectBettingColumn = prop.selectBettingColumn;
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
            forceUpdate();
        });
        return () => {
            betSlipActionSubject_subscription.unsubscribe();
        }
    }, []);


    // console.log("tjkBetSlipOptions", tjkBetSlipOptions)


    if (!tjkBetSlipOptions.bettingSubType) {
        return (<></>)
    }

    // console.log("tjkBetSlipOptions.bettingSubType.id)", tjkBetSlipOptions.bettingSubType.id)

    let tabs: any = [];
    for (let i = Number(tjkBetSlipOptions.bettingSubType.id); i < Number(tjkBetSlipOptions.bettingSubType.id) + tjkBetSlipOptions.bettingType.column; i += 1) {
        tabs.push(i)
    }

    let columnType = tjkBetSlipOptions.bettingType.columnLabel === 'Koşu' ? 'run' : 'horse';
    let betSlipLegs = getLegsSelections();

    return (<>
        <div className="betting-run-tabs badge-tabs">
            {!isMobile && <div className={"weather-info"}>
                <p className={"text-xsmall text-bolder"}>{tjkBetSlipOptions.hippodrome.location}</p>
                <>
                    <span className="text-xsmall text-bolder me-2">Hava: <span
                        className="text-xsmall">{tjkBetSlipOptions.hippodrome.weather?.temperature} °C | {tjkBetSlipOptions.hippodrome.weather?.forecast}</span></span>
                    <span className="text-xsmall text-bolder me-2">Nem: <span
                        className="text-xsmall">%{tjkBetSlipOptions.hippodrome.weather?.humidity}</span></span>
                    {/*<span className="text-xsmall text-bolder me-2">: <span className="text-xsmall">{homeHippodrome.weather?.temperature} °C | {homeHippodrome.weather?.forecast}</span></span>*/}
                </>
            </div>}
            { tabs.length > 1 && <div className={"run-betting-tabs"}>

                {tabs.map((run: any, keyRun: number) => {
                    let columnNo = keyRun + 1;
                    return (
                        <div key={`runItem-${keyRun}`}
                             className={`run-betting-item ${columnNo === tjkBetSlipOptions.column ? 'active' : ''}`}
                             onClick={() => {
                                 selectBettingColumn(columnNo);
                             }}>
                            {isMobile ?
                                <div className="mobile-run">
                                    <span
                                        className="text-small text-bolder">{columnType === 'run' ? tjkBetSlipOptions.bettingSubType.label.split('-')[columnNo - 1] : columnNo}.</span>
                                    <span
                                        className="text-xxsmall"> {`${tjkBetSlipOptions.bettingType.columnLabel}`}</span>
                                </div>
                                : <div className="run">
                                <span
                                    className="text-xsmall text-bolder">{columnType === 'run' ? tjkBetSlipOptions.bettingSubType.label.split('-')[columnNo - 1] : columnNo}</span>.
                                    <span
                                        className="text-xxsmall"> {tjkBetSlipOptions.bettingType.columnLabel === 'Ayak' && isMobile ? columnNo === tjkBetSlipOptions.column && `${tjkBetSlipOptions.bettingType.columnLabel}` : `${tjkBetSlipOptions.bettingType.columnLabel}`}</span>
                                </div>}
                            <span
                                className="badge">{betSlipLegs[keyRun].length > 0 ? betSlipLegs[keyRun].length : 0}</span>

                        </div>)
                })}

            </div>}

        </div>
    </>)
}
