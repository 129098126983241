import React, {useEffect, useState} from "react";
import {Storage} from "../../services/localstorege.service";
import {ApiService} from "../../services/http.service";
import {agfUrlPrefix} from "../../constants/global.vars";
import {dateTimeStringFormat, ServerDate, useForceUpdate} from "../../services/misc.functions";

import iconClock from "../../assets/feather/clock.svg";
import {availableHippodromes} from "../../store/bulletin.store";
import {Hippodrome} from "../../models/bulletin.models";
import {isMobile} from "../../App";
import {useParams} from "react-router-dom";
import loading from "../../assets/img/loading.gif"
import jokeyIcon from "../../assets/img/jokey.png";
import moment from "moment/moment";

let agfInterval: any = null;
let hippodrome: any = null;

export function AgfPage() {
    const forceUpdate = useForceUpdate();
    const params: any = useParams();
    const [selectedTab, setSelectedTab] = useState<any>(0);
    const [agfData, setAgfData] = useState<any>(Storage.get("agfData"))
    const [shownWager, setShownWager] = useState<any>(0);
    const [shownMobileRun, setShownMobileRun] = useState<any>(0);


    const getAgfData = () => {
        if (window.location.pathname.includes('agf')) {
            let api = new ApiService()
            api.start("get", agfUrlPrefix, null, false).then((res: any) => {
                if (res.status) {
                    setAgfData(res.data);
                    if (agfInterval === null) {
                        agfInterval = setInterval(getAgfData, 3000);
                    }
                    if (!hippodrome) {
                        if (params.hippodrome && hippodrome == null) {
                            hippodrome = availableHippodromes.find((hippodrome: Hippodrome) => hippodrome.keyword.toLowerCase() === params.hippodrome.toLowerCase());
                            forceUpdate();

                        } else if (hippodrome == null) {
                            hippodrome = availableHippodromes.filter((el: any) => new Date(el.date).getDate() === new Date().getDate())[0];
                            forceUpdate();
                        }
                    }
                }
            })
        } else {
            clearInterval(agfInterval);
        }
    }

    useEffect(() => {
        if (params.hippodrome && hippodrome == null) {
            hippodrome = availableHippodromes.find((hippodrome: Hippodrome) => hippodrome.keyword.toLowerCase() === params.hippodrome.toLowerCase());
            forceUpdate();

        } else if (hippodrome == null) {
            hippodrome = availableHippodromes.filter((el: any) => new Date(el.date).getDate() === new Date().getDate())[0];
            forceUpdate();
        }
        if (!agfData) {
            getAgfData();
        }

    }, []);

    if (!hippodrome || !agfData) {
        return <>
            <div className="hippodrome-container">
                <div className="hippodrome-gap">
                    <div className="container ">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                     fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V2.25122C13.1066 2.25367 13.4183 2.26104 13.6889 2.28315C14.1104 2.31759 14.498 2.39151 14.862 2.57698C15.4265 2.8646 15.8854 3.32354 16.173 3.88803C16.3585 4.25203 16.4324 4.63956 16.4669 5.06113C16.5 5.46702 16.5 5.96529 16.5 6.56901V12.931C16.5 13.5347 16.5 14.033 16.4669 14.4389C16.4324 14.8604 16.3585 15.248 16.173 15.612C15.8854 16.1765 15.4265 16.6354 14.862 16.923C14.498 17.1085 14.1104 17.1824 13.6889 17.2169C13.283 17.25 12.7847 17.25 12.181 17.25H5.81901C5.21529 17.25 4.71702 17.25 4.31113 17.2169C3.88956 17.1824 3.50203 17.1085 3.13803 16.923C2.57354 16.6354 2.1146 16.1765 1.82698 15.612C1.64151 15.248 1.56759 14.8604 1.53315 14.4389C1.49998 14.033 1.49999 13.5347 1.5 12.931V6.56903C1.49999 5.9653 1.49998 5.46702 1.53315 5.06113C1.56759 4.63956 1.64151 4.25203 1.82698 3.88803C2.1146 3.32354 2.57354 2.8646 3.13803 2.57698C3.50203 2.39151 3.88956 2.31759 4.31113 2.28315C4.58174 2.26104 4.8934 2.25367 5.25 2.25122V1.5C5.25 1.08579 5.58579 0.75 6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V2.25H11.25V1.5C11.25 1.08579 11.5858 0.75 12 0.75ZM5.25 3.75132V4.5C5.25 4.91421 5.58579 5.25 6 5.25C6.41421 5.25 6.75 4.91421 6.75 4.5V3.75H11.25V4.5C11.25 4.91421 11.5858 5.25 12 5.25C12.4142 5.25 12.75 4.91421 12.75 4.5V3.75132C13.0841 3.75367 13.3465 3.76017 13.5667 3.77816C13.8955 3.80503 14.0637 3.85372 14.181 3.91349C14.4632 4.0573 14.6927 4.28677 14.8365 4.56902C14.8963 4.68631 14.945 4.85447 14.9718 5.18328C14.9994 5.52085 15 5.95757 15 6.6V6.75H3V6.6C3 5.95757 3.00058 5.52085 3.02816 5.18328C3.05503 4.85447 3.10372 4.68631 3.16349 4.56902C3.3073 4.28677 3.53677 4.0573 3.81902 3.91349C3.93632 3.85372 4.10447 3.80503 4.43328 3.77816C4.65347 3.76017 4.91585 3.75367 5.25 3.75132Z"
                                          fill="#FFE700"></path>
                                </svg>
                                <p className="text-white text-bolder ms-2"
                                   style={{fontSize: "20px"}}>{dateTimeStringFormat(moment(ServerDate()).toISOString(), false)} Yarışları</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"container "}>
                    <div className="hippodrome-widgets">

                        {availableHippodromes.filter((el: any) => new Date(el.date).getDate() === new Date().getDate()).map((availableHippodrome: Hippodrome, keyHippodrome: number) => {

                            return <div key={"agf-" + keyHippodrome} onClick={() => {
                                changeSelectedHippodrome(availableHippodrome);
                            }}
                                        className={`hippodrome-item  ${hippodrome?.id === availableHippodrome?.id ? 'selected-hippodrome' : ''}`}>
                                <p className="text-small text-bolder text-start">{availableHippodrome.keyword}</p>
                                <p className="text-xxsmall text-start">
                                    {availableHippodrome.nextRun ? <div>
                                    <span
                                        className="text-xxsmall">{availableHippodrome.nextRun.no}.</span> Koşu: {availableHippodrome.nextRun.time}
                                    </div> : <div>
                                        Koşular Tamamlanmıştır
                                    </div>}
                                </p>
                                <p className="text-xxsmall text-start">
                                        <span
                                            className="text-xxsmall">Hava:</span> {availableHippodrome.weather && availableHippodrome.weather?.temperature} °C
                                    | {availableHippodrome.weather?.forecast}</p>
                                {hippodrome?.id === availableHippodrome?.id &&
                                    <div className="selected-indicator"></div>
                                }
                            </div>
                        })}


                    </div>
                </div>
            </div>
            <div className="container ">
                <div className={"d-flex justify-content-center align-items-center w-100"}>
                    <img src={loading}/>
                </div>
            </div>
        </>
    }


    let agfWagers: any = agfData.find((el: any) => el._date === hippodrome._date && el.keyword === hippodrome.keyword)

    const changeSelectedHippodrome = (selectedHippodrome: any) => {
        hippodrome = selectedHippodrome;
        setSelectedTab(0);
        setShownWager(0)
        setShownMobileRun(0)
        forceUpdate();
    }

    if (!agfData || !agfWagers || (agfData.length > 0 && agfWagers && agfWagers.wagers.length === 0)) {
        return <>
            <div className="hippodrome-container">
                <div className="hippodrome-gap">
                    <div className="container ">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                     fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V2.25122C13.1066 2.25367 13.4183 2.26104 13.6889 2.28315C14.1104 2.31759 14.498 2.39151 14.862 2.57698C15.4265 2.8646 15.8854 3.32354 16.173 3.88803C16.3585 4.25203 16.4324 4.63956 16.4669 5.06113C16.5 5.46702 16.5 5.96529 16.5 6.56901V12.931C16.5 13.5347 16.5 14.033 16.4669 14.4389C16.4324 14.8604 16.3585 15.248 16.173 15.612C15.8854 16.1765 15.4265 16.6354 14.862 16.923C14.498 17.1085 14.1104 17.1824 13.6889 17.2169C13.283 17.25 12.7847 17.25 12.181 17.25H5.81901C5.21529 17.25 4.71702 17.25 4.31113 17.2169C3.88956 17.1824 3.50203 17.1085 3.13803 16.923C2.57354 16.6354 2.1146 16.1765 1.82698 15.612C1.64151 15.248 1.56759 14.8604 1.53315 14.4389C1.49998 14.033 1.49999 13.5347 1.5 12.931V6.56903C1.49999 5.9653 1.49998 5.46702 1.53315 5.06113C1.56759 4.63956 1.64151 4.25203 1.82698 3.88803C2.1146 3.32354 2.57354 2.8646 3.13803 2.57698C3.50203 2.39151 3.88956 2.31759 4.31113 2.28315C4.58174 2.26104 4.8934 2.25367 5.25 2.25122V1.5C5.25 1.08579 5.58579 0.75 6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V2.25H11.25V1.5C11.25 1.08579 11.5858 0.75 12 0.75ZM5.25 3.75132V4.5C5.25 4.91421 5.58579 5.25 6 5.25C6.41421 5.25 6.75 4.91421 6.75 4.5V3.75H11.25V4.5C11.25 4.91421 11.5858 5.25 12 5.25C12.4142 5.25 12.75 4.91421 12.75 4.5V3.75132C13.0841 3.75367 13.3465 3.76017 13.5667 3.77816C13.8955 3.80503 14.0637 3.85372 14.181 3.91349C14.4632 4.0573 14.6927 4.28677 14.8365 4.56902C14.8963 4.68631 14.945 4.85447 14.9718 5.18328C14.9994 5.52085 15 5.95757 15 6.6V6.75H3V6.6C3 5.95757 3.00058 5.52085 3.02816 5.18328C3.05503 4.85447 3.10372 4.68631 3.16349 4.56902C3.3073 4.28677 3.53677 4.0573 3.81902 3.91349C3.93632 3.85372 4.10447 3.80503 4.43328 3.77816C4.65347 3.76017 4.91585 3.75367 5.25 3.75132Z"
                                          fill="#FFE700"></path>
                                </svg>
                                <p className="text-white text-bolder ms-2"
                                   style={{fontSize: "20px"}}>{dateTimeStringFormat(hippodrome?.date, false)} Yarışları</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"container "}>
                    <div className="hippodrome-widgets">

                        {availableHippodromes.filter((el: any) => new Date(el.date).getDate() === new Date().getDate()).map((availableHippodrome: Hippodrome, keyHippodrome: number) => {

                            return <div key={"agf-" + keyHippodrome} onClick={() => {
                                changeSelectedHippodrome(availableHippodrome);
                            }}
                                        className={`hippodrome-item  ${hippodrome.id === availableHippodrome.id ? 'selected-hippodrome' : ''}`}>
                                <p className="text-small text-bolder text-start">{availableHippodrome.keyword}</p>
                                <p className="text-xxsmall text-start">
                                    {availableHippodrome.nextRun ? <div>
                                    <span
                                        className="text-xxsmall">{availableHippodrome.nextRun.no}.</span> Koşu: {availableHippodrome.nextRun.time}
                                    </div> : <div>
                                        Koşular Tamamlanmıştır
                                    </div>}
                                </p>
                                <p className="text-xxsmall text-start">
                                        <span
                                            className="text-xxsmall">Hava:</span> {availableHippodrome.weather && availableHippodrome.weather?.temperature} °C
                                    | {availableHippodrome.weather?.forecast}</p>
                                {hippodrome.id === availableHippodrome.id &&
                                    <div className="selected-indicator"></div>
                                }
                            </div>
                        })}


                    </div>
                </div>
            </div>
            <div className="container ">
                <p className="p-4 text-center"><img alt={"clock"} src={iconClock} width={64}/> <br/><br/>
                    Henüz AGF verileri açıklanmamış olabilir lütfen kısa bir süre sonra tekrar deneyiniz.</p>
            </div>
        </>
    }


    return <>
        <div className="hippodrome-container">
            <div className="hippodrome-gap">
                <div className="container ">
                    <div
                        className={`d-flex align-items-center justify-content-between ${isMobile ? 'flex-column' : ''}`}>
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                 fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V2.25122C13.1066 2.25367 13.4183 2.26104 13.6889 2.28315C14.1104 2.31759 14.498 2.39151 14.862 2.57698C15.4265 2.8646 15.8854 3.32354 16.173 3.88803C16.3585 4.25203 16.4324 4.63956 16.4669 5.06113C16.5 5.46702 16.5 5.96529 16.5 6.56901V12.931C16.5 13.5347 16.5 14.033 16.4669 14.4389C16.4324 14.8604 16.3585 15.248 16.173 15.612C15.8854 16.1765 15.4265 16.6354 14.862 16.923C14.498 17.1085 14.1104 17.1824 13.6889 17.2169C13.283 17.25 12.7847 17.25 12.181 17.25H5.81901C5.21529 17.25 4.71702 17.25 4.31113 17.2169C3.88956 17.1824 3.50203 17.1085 3.13803 16.923C2.57354 16.6354 2.1146 16.1765 1.82698 15.612C1.64151 15.248 1.56759 14.8604 1.53315 14.4389C1.49998 14.033 1.49999 13.5347 1.5 12.931V6.56903C1.49999 5.9653 1.49998 5.46702 1.53315 5.06113C1.56759 4.63956 1.64151 4.25203 1.82698 3.88803C2.1146 3.32354 2.57354 2.8646 3.13803 2.57698C3.50203 2.39151 3.88956 2.31759 4.31113 2.28315C4.58174 2.26104 4.8934 2.25367 5.25 2.25122V1.5C5.25 1.08579 5.58579 0.75 6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V2.25H11.25V1.5C11.25 1.08579 11.5858 0.75 12 0.75ZM5.25 3.75132V4.5C5.25 4.91421 5.58579 5.25 6 5.25C6.41421 5.25 6.75 4.91421 6.75 4.5V3.75H11.25V4.5C11.25 4.91421 11.5858 5.25 12 5.25C12.4142 5.25 12.75 4.91421 12.75 4.5V3.75132C13.0841 3.75367 13.3465 3.76017 13.5667 3.77816C13.8955 3.80503 14.0637 3.85372 14.181 3.91349C14.4632 4.0573 14.6927 4.28677 14.8365 4.56902C14.8963 4.68631 14.945 4.85447 14.9718 5.18328C14.9994 5.52085 15 5.95757 15 6.6V6.75H3V6.6C3 5.95757 3.00058 5.52085 3.02816 5.18328C3.05503 4.85447 3.10372 4.68631 3.16349 4.56902C3.3073 4.28677 3.53677 4.0573 3.81902 3.91349C3.93632 3.85372 4.10447 3.80503 4.43328 3.77816C4.65347 3.76017 4.91585 3.75367 5.25 3.75132Z"
                                      fill="#FFE700"></path>
                            </svg>
                            <p className="text-white text-bolder ms-2"
                               style={{fontSize: "20px"}}>{dateTimeStringFormat(hippodrome?.date, false)} Yarışları</p>
                        </div>
                        {agfWagers && agfWagers.wagers && <div className={"ganyan-six"}>
                            {agfWagers.wagers.length === 1 ?
                                <div style={{marginLeft: "auto", marginRight: "10px"}}>
                                    <div>
                                        <span onClick={() => {
                                            setSelectedTab(0);

                                        }} className={`pill-tab all`}>{agfWagers.wagers[0].wager} Ganyan</span>

                                    </div>
                                </div> :
                                <div style={{marginLeft: "auto", marginRight: "10px"}}>
                                    <div>
                                        {agfWagers.wagers.map((wager: any, key: any) => {
                                            return <span key={`agfpage-wagers-${key}`} onClick={() => {
                                                setSelectedTab(key);
                                                setShownWager(key)
                                                setShownMobileRun((key * 5))
                                            }}
                                                         className={`pill-tab ${key === 0 ? 'left' : 'right'} ${selectedTab === key ? 'active' : ''}`}>{wager.wager} Ganyan
                                                    </span>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>}
                    </div>
                </div>
            </div>
            <div className={"container "}>
                <div className="hippodrome-widgets">

                    {availableHippodromes.filter((el: any) => new Date(el.date).getDate() === new Date().getDate()).map((availableHippodrome: Hippodrome, keyHippodrome: number) => {

                        return <div key={"hippodrome-agf-" + keyHippodrome} onClick={() => {
                            changeSelectedHippodrome(availableHippodrome);
                        }}
                                    className={`hippodrome-item  ${hippodrome.id === availableHippodrome.id ? 'selected-hippodrome' : ''}`}>
                            <p className="text-small text-bolder text-start">{availableHippodrome.keyword}</p>
                            <p className="text-xxsmall text-start">
                                {availableHippodrome.nextRun ? <>
                                    <span
                                        className="text-xxsmall">{availableHippodrome.nextRun.no}.</span> Koşu: {availableHippodrome.nextRun.time}
                                </> : <>
                                    Koşular Tamamlanmıştır
                                </>}
                            </p>
                            <p className="text-xxsmall text-start">
                                        <span
                                            className="text-xxsmall">Hava:</span> {availableHippodrome.weather && availableHippodrome.weather?.temperature} °C
                                | {availableHippodrome.weather?.forecast}</p>
                            {hippodrome.id === availableHippodrome.id &&
                                <div className="selected-indicator"></div>
                            }
                        </div>
                    })}


                </div>
            </div>


        </div>
        <div className={`container mt-3 mb-5 ${isMobile ? 'p-0' : ''}`}>
            <div className={"agf-tab-wrapper"}>
                <div className={"run-tabs"}>
                    {agfWagers.wagers[shownWager].runs.map((run: any, keyRun: number) => {
                        return (
                            <div key={`runItem-${keyRun}`}
                                 className={`run-tab-item ${shownMobileRun === keyRun + (shownWager * 5) ? 'active' : ''}`}
                                 onClick={() => {
                                     setShownMobileRun(keyRun + (shownWager * 5))
                                     forceUpdate();
                                 }}>
                                <p className={"text-small text-white text-bolder"}>{keyRun + 1}.</p>
                                <p className={"text-xsmall text-white"}>Ayak</p>

                            </div>)
                    })}
                </div>
            </div>


            <table className={"w-100 agf-page-table"}>
                <thead>
                <tr>
                    <th style={{width: '30px'}}>
                        <p className="text-xxsmall text-bold text-center">#</p>
                    </th>
                    <th>
                        <p className="text-xxsmall text-bold">At Adı</p>
                    </th>
                    <th>
                        <p className="text-xxsmall text-bold">Jokey</p>
                    </th>
                    <th>
                        <p className="text-xxsmall text-bold text-end">AGF</p>
                    </th>
                </tr>
                </thead>
                <tbody>
                {agfWagers.wagers[shownWager].runs.map((run: any, key: number) => {

                    if (shownMobileRun === (key + (shownWager * 5))) {
                        return run.horses.map((h: any, pkey: number) => {
                            return (<React.Fragment key={`agfpage-i-${pkey}`}>
                                <tr>
                                    <td className="agf-table-sort" rowSpan={h.stablemate != null ? h.stablemate.length +1 : 1}>
                                        <p className="text-small text-bold text-center">{pkey + 1}</p>
                                    </td>
                                    <td className="">

                                        <div className="d-flex align-items-center"><span
                                            className="text-xxsmall text-bold">{h.no} - {h.name}</span>
                                        </div>

                                    </td>
                                    <td>

                                        <div className="d-flex align-items-center">
                                                <span
                                                    className="jockey-image-border">
                                                        <img
                                                            src={`https://medya-cdn.tjk.org/formaftp/${h.owner_code}.jpg`}
                                                            onError={({currentTarget}) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = jokeyIcon;
                                                            }} width={16} height={18} alt={h.jockey}
                                                            className="jockey-uniform float-start "/>
                                                </span>
                                            <span className="text-xxsmall text-bold">{h.jockey}</span>
                                        </div>


                                    </td>
                                    <td className="text-end"
                                        style={h.stablemate !== null ? {
                                            padding: "0px",
                                            overflow: "visible",
                                            position: "relative"
                                        } : {}}>
                                    {h.stablemate !== null && <>
                                            <div className="d-flex justify-content-center align-items-center"
                                                 style={{
                                                     writingMode: "vertical-lr",
                                                     textOrientation: "mixed",
                                                     backgroundColor: "rgb(232, 230, 208)",
                                                     padding: "5px",
                                                     position: "absolute",
                                                     height: `${(h.stablemate.length +1)*50}px`,
                                                     top: "0px",
                                                     width: '25px',
                                                     zIndex: "2"
                                                 }}>
                                                <p className="text-xxsmall text-bold">% {h.stablemate_agf_ratio}</p>
                                            </div>

                                            <div style={{width: "100%", height: "100%", gap: "0px"}}>
                                                <div
                                                    className=" d-flex gap-x-4 align-items-center justify-content-between"
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        padding: "6.6%",
                                                        marginLeft: "auto"
                                                    }}>
                                                    <div style={{marginLeft: "auto"}}>
                                                    <span
                                                        className="text-xxsmall text-bold  pl-4 text-truncate d-flex">% 47.2</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {h.stablemate === null &&
                                            <p className="text-xxsmall text-bold text-end">% {h.agf_ratio}</p>}
                                    </td>

                                </tr>

                                {h.stablemate != null && h.stablemate.map((s: any, k: number) => {
                                    return <tr key={"stablemate" + k}>
                                        <td className="">

                                            <div className="d-flex align-items-center">
                                                <p className="text-xxsmall text-bold">  {s.no} - {s.name}</p>
                                                {!s.run_status && <span className="mx-2 text-xsmall">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                        height="16" viewBox="0 0 16 16" fill="none"><g
                                                        clipPath="url(#clip0_4878_930)"><path
                                                        d="M5.21362 10.7066C3.97829 8.84342 4.85022 7.38859 4.85022 7.38859C4.64738 7.32734 4 7.76075 4 7.76075C4.36397 6.39575 6.36725 5.23667 6.36725 5.23667C6.14499 5.15325 5.5176 5.31834 5.5176 5.31834C7.15691 3.70659 9.16019 4.30625 9.16019 4.30625C9.22076 3.95392 10.2127 3.33325 10.2127 3.33325C10.111 3.43592 10.0698 3.99534 10.0698 3.99534C10.1721 3.80867 10.4967 3.62259 10.4967 3.62259C10.3144 4.05659 10.4967 4.98759 10.4967 4.98759C11.0223 5.526 11.6092 7.38859 11.6092 7.38859L11.832 7.71934C12.116 8.1335 12.0114 8.78509 11.7309 8.93967C10.6789 9.52067 10.5767 8.46425 10.5767 8.46425C9.70701 8.361 9.05906 7.36759 9.05906 7.36759C7.886 7.98767 8.53909 9.73884 9.04591 10.4108C9.69672 11.2724 9.21962 12.6666 9.21962 12.6666C9.21962 12.6666 6.43296 12.5488 5.21362 10.7066Z"
                                                        fill="#353535"></path><path
                                                        d="M3.28634 3.28659L12.713 12.7133M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                                                        stroke="#E04C4C" strokeWidth="1.33333" strokeLinecap="round"
                                                        strokeLinejoin="round"></path></g><defs><clipPath id="clip0_4878_930"><rect
                                                        width="16" height="16" fill="white"></rect></clipPath></defs></svg>
                                                </span>}
                                            </div>
                                        </td>
                                        <td>

                                            <div className="d-flex align-items-center">
                                                <span
                                                    className="jockey-image-border">
                                                     <img
                                                         src={`https://medya-cdn.tjk.org/formaftp/${s.owner_code}.jpg`}
                                                         onError={({currentTarget}) => {
                                                             currentTarget.onerror = null;
                                                             currentTarget.src = jokeyIcon;
                                                         }} width={16} height={18} alt={s.jockey}
                                                         className="jockey-uniform float-start "/>
                                                </span>
                                                <span className="text-xxsmall text-bold">{s.jockey}</span>
                                            </div>


                                        </td>
                                        <td style={h.stablemate !== null ? {
                                            padding: "5px",
                                            overflow: "visible",
                                            position: "relative"
                                        } : {}}>
                                            <p className="text-xxsmall  text-bold text-end">% {s.agf_ratio}</p>

                                        </td>
                                    </tr>

                                })}
                            </React.Fragment>)
                        })

                    }
                })}
                </tbody>
            </table>
        </div>


    </>
}
