import {dateFormat, useForceUpdate} from "../../../services/misc.functions";
import {Hippodrome} from "../../../models/bulletin.models";
import {bulletinProvider} from "../../../services/bulletin.service";
import {
    betSlipActionSubject,
    clearTjkBetSlip, clearTjkBetSlipOptions,
    mobileBetSlipActions,
    mobileBetSlipSubject,
    tjkBetSlipOptions
} from "../../../services/betslip.service";
import {availableBettingSubTypes, availableHippodromes} from "../../../store/bulletin.store";
import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import paths from "../../../paths";
import iconList from "../../../assets/icons/list-tjk.svg";

import {isMobile} from "../../../App";

export function BettingTypeOptions(props: any) {

    const params = props.params;
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();
    const hippodrome: Hippodrome = props.hippodrome;
    const availableBettingTypes: any = bulletinProvider.getAvailableBettingTypes(hippodrome);
    const selectBettingType = props.selectBettingType;
    const selectBettingSubType = props.selectBettingSubType;
    useEffect(() => {
        let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
            forceUpdate();
        });
        return () => {
            betSlipActionSubject_subscription.unsubscribe();

        }
    }, []);


    return (<div className="">
        {isMobile ? <>
                <div className="filter-mobile-buttons-wrapper">
                    <div className="mobile-race-info-wrapper">
                        {/*<div className="mobile-race-info">*/}
                        {/*    <span className="text-xsmall text-primary text-bold">{tjkBetSlipOptions?.selectedRun?.no}</span>*/}
                        {/*    <span className="text-xsmall text-primary">Koşu: {tjkBetSlipOptions?.selectedRun?.time}</span>*/}
                        {/*</div>*/}
                        <div className="mobile-race-info">
                            <span
                                className="text-xsmall text-primary text-bold">Hava:</span>
                            <span className="text-xsmall text-primary">{tjkBetSlipOptions?.hippodrome?.weather?.temperature} °C | {tjkBetSlipOptions?.hippodrome?.weather?.forecast}</span>
                        </div>
                    </div>
                    <div className="mobile-route-button-wrapper">
                        <a className="mobile-route-href"
                           title="Muhtemeller" href={paths.possibles.url}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15 14" fill="none">
                                <path
                                    d="M3.58325 1.16659C3.58325 0.844419 3.32208 0.583252 2.99992 0.583252C2.67775 0.583252 2.41659 0.844419 2.41659 1.16659V2.04159H1.54159C1.21942 2.04159 0.958252 2.30275 0.958252 2.62492C0.958252 2.94708 1.21942 3.20825 1.54159 3.20825H2.41659V4.08325C2.41659 4.40542 2.67775 4.66659 2.99992 4.66659C3.32208 4.66659 3.58325 4.40542 3.58325 4.08325V3.20825H4.45825C4.78042 3.20825 5.04159 2.94708 5.04159 2.62492C5.04159 2.30275 4.78042 2.04159 4.45825 2.04159H3.58325V1.16659Z"
                                    fill="#004742">
                                </path>
                                <path
                                    d="M3.58325 9.91659C3.58325 9.59442 3.32208 9.33325 2.99992 9.33325C2.67775 9.33325 2.41659 9.59442 2.41659 9.91659V10.7916H1.54159C1.21942 10.7916 0.958252 11.0528 0.958252 11.3749C0.958252 11.6971 1.21942 11.9583 1.54159 11.9583H2.41659V12.8333C2.41659 13.1554 2.67775 13.4166 2.99992 13.4166C3.32208 13.4166 3.58325 13.1554 3.58325 12.8333V11.9583H4.45825C4.78042 11.9583 5.04159 11.6971 5.04159 11.3749C5.04159 11.0528 4.78042 10.7916 4.45825 10.7916H3.58325V9.91659Z"
                                    fill="#004742">
                                </path>
                                <path
                                    d="M8.5027 1.54051C8.41606 1.31524 8.19962 1.16659 7.95825 1.16659C7.71689 1.16659 7.50045 1.31524 7.4138 1.54051L6.4022 4.17068C6.22696 4.62629 6.1719 4.75759 6.09658 4.86351C6.021 4.9698 5.92813 5.06267 5.82185 5.13824C5.71592 5.21356 5.58463 5.26863 5.12902 5.44386L2.49885 6.45547C2.27357 6.54211 2.12492 6.75855 2.12492 6.99992C2.12492 7.24129 2.27357 7.45773 2.49885 7.54437L5.12902 8.55597C5.58463 8.73121 5.71592 8.78627 5.82185 8.8616C5.92814 8.93717 6.021 9.03004 6.09658 9.13632C6.1719 9.24225 6.22696 9.37354 6.4022 9.82915L7.4138 12.4593C7.50045 12.6846 7.71689 12.8333 7.95825 12.8333C8.19962 12.8333 8.41606 12.6846 8.5027 12.4593L9.51431 9.82915C9.68954 9.37354 9.74461 9.24225 9.81993 9.13632C9.89551 9.03004 9.98837 8.93717 10.0947 8.8616C10.2006 8.78627 10.3319 8.73121 10.7875 8.55597L13.4177 7.54437C13.6429 7.45773 13.7916 7.24129 13.7916 6.99992C13.7916 6.75855 13.6429 6.54211 13.4177 6.45547L10.7875 5.44386C10.3319 5.26863 10.2006 5.21356 10.0947 5.13824C9.98837 5.06267 9.89551 4.9698 9.81993 4.86351C9.74461 4.75759 9.68954 4.62629 9.51431 4.17068L8.5027 1.54051Z"
                                    fill="#004742">
                                </path>
                            </svg>
                            <span className="text-xsmall">Muhtemeller</span>
                        </a>
                        <a className="mobile-route-href"
                           title="AGF" href={paths.agf.url}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM12.5303 6.53033C12.8232 6.23744 12.8232 5.76256 12.5303 5.46967C12.2374 5.17678 11.7626 5.17678 11.4697 5.46967L5.46967 11.4697C5.17678 11.7626 5.17678 12.2374 5.46967 12.5303C5.76256 12.8232 6.23744 12.8232 6.53033 12.5303L12.5303 6.53033ZM5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625C7.37132 5.625 7.875 6.12868 7.875 6.75C7.875 7.37132 7.37132 7.875 6.75 7.875C6.12868 7.875 5.625 7.37132 5.625 6.75ZM10.125 11.25C10.125 10.6287 10.6287 10.125 11.25 10.125C11.8713 10.125 12.375 10.6287 12.375 11.25C12.375 11.8713 11.8713 12.375 11.25 12.375C10.6287 12.375 10.125 11.8713 10.125 11.25Z"
                                      fill="#004742">
                                </path>
                            </svg>
                            <span className="text-xsmall">AGF</span>
                        </a>
                    </div>
                </div>
                <div className="mobile-filter-wrapper">
                    {tjkBetSlipOptions.bettingType && <>

                        <select onChange={(e: any) => {
                            selectBettingType(e.target.value);
                            tjkBetSlipOptions.complete = false;
                            betSlipActionSubject.next(true);
                        }} value={tjkBetSlipOptions.bettingType.id} className={"betting-select w-100"}>
                            {availableBettingTypes.map((bettingType: any, keyBettingType: number) => {
                                return (
                                    <option key={`keyBettingType-${keyBettingType}`}
                                            value={bettingType.id}>{bettingType.name}</option>)
                            })}
                        </select>
                    </>}
                    {tjkBetSlipOptions.bettingSubType &&
                        <>

                            <select className={"betting-select w-100"} onChange={(e: any) => {
                                selectBettingSubType(e.target.value);
                            }} value={tjkBetSlipOptions.bettingSubType.id}>
                                {availableBettingSubTypes.map((bettingSubType: any, keyBettingSubType: number) => {
                                    return (<option key={`keyBettingType-${keyBettingSubType}`}
                                                    value={bettingSubType.id}>{bettingSubType.label}</option>)
                                })}
                            </select>
                        </>}
                </div>
            </>
            : <div className="row pt-2">

                <div className="col-6 col-lg-3 p-0 pe-1">

                    {tjkBetSlipOptions.bettingType && <>

                        <select onChange={(e: any) => {
                            selectBettingType(e.target.value);
                            tjkBetSlipOptions.complete = false;
                            betSlipActionSubject.next(true);
                        }} value={tjkBetSlipOptions.bettingType.id} className={"betting-select w-100"}>
                            {availableBettingTypes.map((bettingType: any, keyBettingType: number) => {
                                return (
                                    <option key={`keyBettingType-${keyBettingType}`}
                                            value={bettingType.id}>{bettingType.name}</option>)
                            })}
                        </select>
                    </>}
                </div>
                <div className="col-6 col-lg-3 p-0 pe-1">

                    {tjkBetSlipOptions.bettingSubType &&
                        <>

                            <select className={"betting-select w-100"} onChange={(e: any) => {
                                selectBettingSubType(e.target.value);
                            }} value={tjkBetSlipOptions.bettingSubType.id}>
                                {availableBettingSubTypes.map((bettingSubType: any, keyBettingSubType: number) => {
                                    return (<option key={`keyBettingType-${keyBettingSubType}`}
                                                    value={bettingSubType.id}>{bettingSubType.label}</option>)
                                })}
                            </select>
                        </>}
                </div>

                <div className="col-4 col-lg-3 p-0 pe-1">
                    <Link
                        to={`${paths.possibles.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}/${tjkBetSlipOptions.column}`}
                        className={`btn-link`} style={{minHeight: '44px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 15 14" fill="none">
                            <path
                                d="M3.58325 1.16659C3.58325 0.844419 3.32208 0.583252 2.99992 0.583252C2.67775 0.583252 2.41659 0.844419 2.41659 1.16659V2.04159H1.54159C1.21942 2.04159 0.958252 2.30275 0.958252 2.62492C0.958252 2.94708 1.21942 3.20825 1.54159 3.20825H2.41659V4.08325C2.41659 4.40542 2.67775 4.66659 2.99992 4.66659C3.32208 4.66659 3.58325 4.40542 3.58325 4.08325V3.20825H4.45825C4.78042 3.20825 5.04159 2.94708 5.04159 2.62492C5.04159 2.30275 4.78042 2.04159 4.45825 2.04159H3.58325V1.16659Z"
                                fill="#004742">
                            </path>
                            <path
                                d="M3.58325 9.91659C3.58325 9.59442 3.32208 9.33325 2.99992 9.33325C2.67775 9.33325 2.41659 9.59442 2.41659 9.91659V10.7916H1.54159C1.21942 10.7916 0.958252 11.0528 0.958252 11.3749C0.958252 11.6971 1.21942 11.9583 1.54159 11.9583H2.41659V12.8333C2.41659 13.1554 2.67775 13.4166 2.99992 13.4166C3.32208 13.4166 3.58325 13.1554 3.58325 12.8333V11.9583H4.45825C4.78042 11.9583 5.04159 11.6971 5.04159 11.3749C5.04159 11.0528 4.78042 10.7916 4.45825 10.7916H3.58325V9.91659Z"
                                fill="#004742">
                            </path>
                            <path
                                d="M8.5027 1.54051C8.41606 1.31524 8.19962 1.16659 7.95825 1.16659C7.71689 1.16659 7.50045 1.31524 7.4138 1.54051L6.4022 4.17068C6.22696 4.62629 6.1719 4.75759 6.09658 4.86351C6.021 4.9698 5.92813 5.06267 5.82185 5.13824C5.71592 5.21356 5.58463 5.26863 5.12902 5.44386L2.49885 6.45547C2.27357 6.54211 2.12492 6.75855 2.12492 6.99992C2.12492 7.24129 2.27357 7.45773 2.49885 7.54437L5.12902 8.55597C5.58463 8.73121 5.71592 8.78627 5.82185 8.8616C5.92814 8.93717 6.021 9.03004 6.09658 9.13632C6.1719 9.24225 6.22696 9.37354 6.4022 9.82915L7.4138 12.4593C7.50045 12.6846 7.71689 12.8333 7.95825 12.8333C8.19962 12.8333 8.41606 12.6846 8.5027 12.4593L9.51431 9.82915C9.68954 9.37354 9.74461 9.24225 9.81993 9.13632C9.89551 9.03004 9.98837 8.93717 10.0947 8.8616C10.2006 8.78627 10.3319 8.73121 10.7875 8.55597L13.4177 7.54437C13.6429 7.45773 13.7916 7.24129 13.7916 6.99992C13.7916 6.75855 13.6429 6.54211 13.4177 6.45547L10.7875 5.44386C10.3319 5.26863 10.2006 5.21356 10.0947 5.13824C9.98837 5.06267 9.89551 4.9698 9.81993 4.86351C9.74461 4.75759 9.68954 4.62629 9.51431 4.17068L8.5027 1.54051Z"
                                fill="#004742">
                            </path>
                        </svg>
                        <span className={"text-xsmall fw-medium text-green"}>MUHTEMELLER</span>
                    </Link>
                </div>
                <div className="col-4 col-lg-3 p-0 ">
                    <Link
                        to={`${paths.agf.url}/${tjkBetSlipOptions.hippodrome.keyword.toLowerCase()}/agf`}
                        className={`btn-link`} style={{minHeight: '44px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM12.5303 6.53033C12.8232 6.23744 12.8232 5.76256 12.5303 5.46967C12.2374 5.17678 11.7626 5.17678 11.4697 5.46967L5.46967 11.4697C5.17678 11.7626 5.17678 12.2374 5.46967 12.5303C5.76256 12.8232 6.23744 12.8232 6.53033 12.5303L12.5303 6.53033ZM5.625 6.75C5.625 6.12868 6.12868 5.625 6.75 5.625C7.37132 5.625 7.875 6.12868 7.875 6.75C7.875 7.37132 7.37132 7.875 6.75 7.875C6.12868 7.875 5.625 7.37132 5.625 6.75ZM10.125 11.25C10.125 10.6287 10.6287 10.125 11.25 10.125C11.8713 10.125 12.375 10.6287 12.375 11.25C12.375 11.8713 11.8713 12.375 11.25 12.375C10.6287 12.375 10.125 11.8713 10.125 11.25Z"
                                  fill="#004742">
                            </path>
                        </svg>
                        <span className={"text-xsmall fw-medium text-green"}>AGF</span>
                    </Link>
                </div>

            </div>}
    </div>)
}
