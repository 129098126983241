import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    bulletinProvider,
} from "../../services/bulletin.service";
import paths from "../../paths";
import {
    availableBettingSubTypes, availableBettingTypes,
    availableHippodromes,
    racesActionSubject
} from "../../store/bulletin.store";
import React, {useEffect, useState} from "react";
import {useForceUpdate} from "../../services/misc.functions";
import {Hippodrome, Run} from "../../models/bulletin.models";
import {BetSlip, EkuriBetSlip} from "../../shared/betslip/betslip";
import {
    betSlipActionSubject,
    mobileBetSlipActions,
    mobileBetSlipSubject,
    tjkBetSlipOptions,
    updateBetCount
} from "../../services/betslip.service";
import {BettingTypeOptions} from "./components/betting-options";
import {BettingDataTable} from "./components/betting-datatable";
import {BettingDataTabs} from "./components/betting-tabs";
import {Possibles} from "./components/betting-possibles";
import {AGF} from "./components/betting-agf";
import {isMobile} from "../../App";
import {HippodromeWidget} from "../../shared/hippodrome/hippodrome";
import {homeHippodromeSubject} from "../../store/misc.store";


export function BettingRoot() {

    const navigator = useNavigate();
    const forceUpdate = useForceUpdate();
    bulletinProvider.getAvailableHippodromes();

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {
                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });


    useEffect(() => {
        let activeAvailableHippodromes = availableHippodromes.filter((el: any) => el.wager_details.length > 0);
        if (activeAvailableHippodromes.length > 0) {
            navigator(`${paths.betting.url}/${activeAvailableHippodromes[0].keyword.toLowerCase()}${activeAvailableHippodromes[0]._id}`);
        } else {
            navigator(`${paths.home.url}`);
        }

    }, [availableHippodromes.length]);


    return (<></>)
}

let rediretor: any = null;
let restored: any = null;

export function Betting() {

    const params: any = useParams();
    const forceUpdate = useForceUpdate();
    const navigator = useNavigate();
    const hippodrome = availableHippodromes.find((hippodrome: Hippodrome) => hippodrome.keyword.toLowerCase() + hippodrome._id === params.hippodrome.toLowerCase());
    useEffect(() => {

        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            forceUpdate();
        })

        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        }

    }, []);

    // const [mobileBetslip, setMobileBetslip] = React.useState<boolean>(false);

    //fixme gereksizleri sondür ya da tek sefer çalışsın
    bulletinProvider.getAvailableHippodromes();

    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {

                if (tjkBetSlipOptions.bettingType) {
                    bulletinProvider.getAvailableBettingTypes(hippodrome)
                    if (!availableBettingTypes.find((b: any) => b.id === tjkBetSlipOptions.bettingType.id)) {
                        if (availableBettingTypes.length > 0) {
                            bulletinProvider.selectBettingType(hippodrome, availableBettingTypes[0].id);
                            bulletinProvider.selectBettingColumn(1);
                        }
                    }
                    bulletinProvider.getAvailableBettingSubTypes(tjkBetSlipOptions.bettingType);
                    if (!availableBettingSubTypes.find((b: any) => b.id === tjkBetSlipOptions.bettingSubType.id)) {
                        if (availableBettingSubTypes.length > 0) {
                            bulletinProvider.selectBettingSubType(availableBettingSubTypes[0]);
                            bulletinProvider.selectBettingColumn(1);
                        }
                    }
                }

                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });
    useEffect(() => {
        let mobileBetSlipSubject_subscription = mobileBetSlipSubject.subscribe((res: any) => {
            if (res) {
                forceUpdate();
            }
        })
        return () => {
            mobileBetSlipSubject_subscription.unsubscribe();
        }
    });

    const selectBettingColumn = (no: any) => {
        bulletinProvider.selectBettingColumn(no);
        updateBetCount.status = true;
        forceUpdate();
    }

    const selectBettingSubType = (id: any) => {
        tjkBetSlipOptions.betSlipMultiply = 1;
        tjkBetSlipOptions.complete = false;
        betSlipActionSubject.next({action: 'complete'});
        bulletinProvider.selectBettingSubType(availableBettingSubTypes.find((el: any) => Number(el.id) === Number(id)));
        let tabs: any = [];
        for (let i = Number(tjkBetSlipOptions.bettingSubType.id); i < Number(tjkBetSlipOptions.bettingSubType.id) + tjkBetSlipOptions.bettingType.column; i += 1) {
            tabs.push(i)
        }
        selectBettingColumn(1);
    }

    const selectBettingType = (id: any) => {
        bulletinProvider.selectBettingType(hippodrome, id);
        selectBettingSubType(availableBettingSubTypes[0].id);
    }

    if (rediretor) {
        clearTimeout(rediretor);
    }

    if (!hippodrome) {
        rediretor = setTimeout(() => {
            window.location.href = paths.home.url;
        }, 3000);
        return (<></>)
    }

    if (!tjkBetSlipOptions.bettingType) {
        bulletinProvider.getAvailableBettingTypes(hippodrome)
        let isExist6li = availableBettingTypes.find((b: any) => b.id === 18)
        if (isExist6li) {
            bulletinProvider.selectBettingType(hippodrome, isExist6li.id);
        } else {
            console.log(availableBettingTypes);
            if (availableBettingTypes.length > 0) {
                bulletinProvider.selectBettingType(hippodrome, availableBettingTypes[0].id);
            }
        }
        bulletinProvider.selectBettingColumn(1);
    }

    if (hippodrome && tjkBetSlipOptions.hippodrome?._id !== hippodrome._id) {
        tjkBetSlipOptions.betSlipMultiply = 1;
        tjkBetSlipOptions.usePointBalance = false;
        bulletinProvider.getAvailableBettingTypes(hippodrome);

        let isExist6li = availableBettingTypes.find((b: any) => b.id === 18)
        if (isExist6li) {
            bulletinProvider.selectBettingType(hippodrome, isExist6li.id);
        } else {
            if (availableBettingTypes.length > 0) {
                bulletinProvider.selectBettingType(hippodrome, availableBettingTypes[0].id);
            }
        }
        bulletinProvider.selectBettingColumn(1);
    }


    tjkBetSlipOptions.hippodrome = hippodrome;

    // console.log("mobileBetslip::.",mobileBetslip)

    return (<>
        <HippodromeWidget/>


        <div className={"container betting-container pb-5"}>
            <BettingTypeOptions params={params} hippodrome={hippodrome}
                                selectBettingType={selectBettingType} selectBettingSubType={selectBettingSubType}/>
            <div className="row">

                {!params.subpage && <>

                    {isMobile && mobileBetSlipActions.isOpen === true ? <></> :
                        <div className={`${hippodrome.wager_details.length > 0 ? "p-0" : 'col-12'}`}>

                            {hippodrome.wager_details.length > 0 && <div className="page-widget">

                                <BettingDataTabs selectBettingColumn={selectBettingColumn}/>

                                <BettingDataTable selectBettingColumn={selectBettingColumn}/>
                            </div>}


                            {hippodrome.wager_details.length === 0 &&
                                <div className={"page-widget"}>
                                    <div className="hint-text text-center p-3" style={{minHeight: "50vh"}}>
                                        <p className={"fs-6 mb-0"}>Bu hipodromda açık koşu bulunmamaktadır.</p>
                                    </div>
                                </div>}


                        </div>}


                </>}
            </div>

            { !isMobile && tjkBetSlipOptions.bettingSubType && hippodrome.wager_details.length > 0 && <EkuriBetSlip/>}

        </div>


    </>)
}
