import "./deposit.css";
import React, {useEffect, useState} from "react";
import {env} from "../../constants/global.vars";
import {ApiService} from "../../services/http.service";


let depositQuestions = [
    {
        id: 0,
        title: "EFT ile nasıl para yatırabilirim?",
        content: '<p class="text-xsmall mt-2">Kendi adınıza\n' +
            '                                        kayıtlı bir banka hesabından, Ekuri.com’a aşağıda belirtilen banka hesap\n' +
            '                                        bilgilerimiz ile EFT/FAST yapabilirsiniz. Kendi adınıza kayıtlı olmayan farklı\n' +
            '                                        bir kişiye ait banka hesabından yapılan EFT/FAST işlemleri için göndermiş\n' +
            '                                        olduğunuz tutar, banka tarafından alınan masraflar düşüldükten sonra iade\n' +
            '                                        edilir.<br/>\n' +
            '                                        <br/>Banka Hesap Bilgilerimiz</p>\n' +
            '                                    <p class="text-xsmall mt-2">T ŞANS OYUNLARI\n' +
            '                                        A.Ş.</p>\n' +
            '                                    <p class="text-xsmall mt-2">ZİRAAT BANKASI</p>\n' +
            '                                    <p class="text-xsmall mt-2">TR30 0001 0022 5097\n' +
            '                                        7884 0450 01</p>',
    },
    {
        id: 1,
        title: "Para yatırma işlemini ne zaman gerçekleştirebilirim?",
        content: '<p class="text-xsmall mt-2">Ekuri.com’a 7 gün 24 saat para yatırabilirsiniz.</p>'
    },
    {
        id: 2,
        title: "Yatırdığım para ne kadar sürede Ekuri.com hesabıma aktarılır?",
        content: '<p class="text-xsmall mt-2">EFT/FAST ile yapılan para yatırma işlemleri banka işlem süresine göre Ekuri.com hesabına yansımaktadır.</p>'
    },
    {
        id: 3,
        title: "En düşük para yatırma limiti ne kadardır?",
        content: '<p class="text-xsmall mt-2">Minimum 1 TL yatırabilirsiniz.</p>'
    },
    {
        id: 4,
        title: "Kredi kartıyla para aktarmam mümkün mü?",
        content: '<p class="text-xsmall mt-2">Hayır, Ekuri.com\'a kredi kartı ile para aktarmak yasal zorunluluklar nedeniyle mümkün değildir.</p>'
    },
    {
        id: 5,
        title: "Yaptığım EFT Ekuri.com hesabımda gözükmüyor. Ne yapmalıyım?",
        content: '<p class="text-xsmall mt-2">Banka işlem süresine göre maksimum iki saat içerisinde\n' +
            '                                    hesabınıza yansıyacaktır. Ama işlem süresinin uzadığını düşünüyorsanız dekontunuzu\n' +
            '                                    destek bölümümüz üzerinden bize iletebilir veya 0850 242 66 66 nolu Müşteri\n' +
            '                                    Hizmetleri numaramızı arayarak destek alabilirsiniz.</p>'
    },
    {
        id: 6,
        title: "EFT yapmak ücretli midir?",
        content: '                       <p class="text-xsmall mt-2">Bankaların EFT uygulamaları farklılık gösterebilir. Bu\n' +
            '                                    yüzden, minimum transfer edilecek miktar ve işlem ücretleri ilgili bankalar\n' +
            '                                    tarafından belirlenmektedir.</p>'
    },
    {
        id: 7,
        title: "Ortak bir banka hesabından para gönderimi yapabilir miyim?",
        content: ' <p class="text-xsmall mt-2">Ortak banka hesabından Ekuri.com’a para gönderimi\n' +
            '                                    yapılamamaktadır. Banka hesabınızın sadece sizin adınıza kayıtlı olması\n' +
            '                                    gerekmektedir.</p>'
    },
    {
        id: 8,
        title: "Yaptığım transferleri nerede görebilirim?",
        content: '             <p class="text-xsmall mt-2">Yapmış olduğunuz tüm transferlere Profil menüsünde yer\n' +
            '                                    alan\n' +
            '                                    Banka İşlemlerim - Hesap Hareketlerim alanından ulaşabilirsiniz.</p>'
    },
    {
        id: 9,
        title: "Kendi adıma kayıtlı olmayan banka hesabından Ekuri.com hesabıma para yatırma işlemi yapabilir miyim?",
        content: '       <p class="text-xsmall mt-2">Hayır, adınıza kayıtlı olmayan farklı bir banka\n' +
            '                                    hesabından\n' +
            '                                    Ekuri.com’a para yatırma işlemi yapılamamaktadır. Sadece adınıza kayıtlı bir banka\n' +
            '                                    hesabından para transferi yapabilirsiniz.</p>'
    }

];

export default function Deposit() {
    const [bankAccounts, setBankAccounts] = useState<any>([]);
    const [selected, setSelected] = useState<any>(null);
    const [defaultBank, setDefaultBank] = useState<any>(null);
    const [selectedQuestionId, setSelectedQuestionId] = useState<any>(0);


    useEffect(() => {
        getBankAccounts();
    }, []);

    const getBankAccounts = async () => {
        const api = new ApiService();
        api
            .start("get", env.api + "/accounting/api/list-bank-account/", null, false)
            .then((res: any) => {
                setBankAccounts(res.data);
                for (const bank of res.data) {
                    if (bank.bank.code === "10") {
                        setDefaultBank(bank);
                    }
                }
            })
            .catch((e: any) => console.log(e));
    };

    return (
        <>
            <div className="row">
                <div className="col p-0">
                    <p className="text-md text-bold mb-3">EFT/FAST</p>
                    <p className="text-xsmall mb-2">Ekuri.com’a ait aşağıda belirtilen
                        banka hesap bilgilerimiz ile EFT/FAST yapabilirsiniz.</p>
                    <p className="text-xsmall mb-2">Kendi
                        adınıza
                        kayıtlı olmayan farklı bir kişiye ait banka hesabından yapılan EFT/FAST işlemleri için göndermiş
                        olduğunuz tutar, banka tarafından alınan masraflar düşüldükten sonra iade edilir.</p>
                    <p className="text-xsmall  mb-3">Açıklama
                        kısmına TCKN veya Ekuri.com Üyelik Numaranızı ekleyerek işleminizi gerçekleştirebilirsiniz.</p>
                    <div className="deposit-wrapper">
                        <p className="text-xsmall text-bold deposit-tittle">Banka Hesap
                            Bilgilerimiz</p>
                        <div className="deposit-section mt-2">
                            <p className="text-xsmall deposit-info none">Hesap/Alıcı Adı:</p>
                            <div className="d-flex">
                                <p className="text-xsmall d-flex deposit-description">T ŞANS OYUNLARI A.Ş.</p>
                                <div style={{position: "relative"}}>
                                    <div onClick={() => {
                                        navigator.clipboard.writeText('T ŞANS OYUNLARI A.Ş.').then(() => {
                                            // @ts-ignore
                                            document.getElementById("copy-bank-account").style.display = "flex";
                                            setTimeout(() => {
                                                // @ts-ignore
                                                document.getElementById("copy-bank-account").style.display = "none";
                                            }, 5000);

                                        });
                                    }} style={{position: "absolute"}}>
                                        <div className="deposit-copy">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect x="4.23779" y="2.08345" width="5.67949" height="5.68011" rx="1.25"
                                                      stroke="#353535" strokeWidth="1.5">
                                                </rect>
                                                <path
                                                    d="M7.84632 9.23151V9.23151C7.84632 10.0246 7.20339 10.6675 6.41029 10.6675H3.3335C2.22893 10.6675 1.3335 9.7721 1.3335 8.66753V5.59004C1.3335 4.79702 1.97637 4.15414 2.76939 4.15414V4.15414"
                                                    stroke="#353535" strokeWidth="1.5">
                                                </path>
                                            </svg>
                                            Kopyala
                                        </div>
                                    </div>
                                    <div className="copy-tooltip" style={{left: "72px", display: "none"}}
                                         id={"copy-bank-account"}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_7529_44094)">
                                                <path
                                                    d="M3 7.5L4 8.5L6.25 6.25M4 4V2.6C4 2.03995 4 1.75992 4.10899 1.54601C4.20487 1.35785 4.35785 1.20487 4.54601 1.10899C4.75992 1 5.03995 1 5.6 1H9.4C9.96005 1 10.2401 1 10.454 1.10899C10.6422 1.20487 10.7951 1.35785 10.891 1.54601C11 1.75992 11 2.03995 11 2.6V6.4C11 6.96005 11 7.24008 10.891 7.45399C10.7951 7.64215 10.6422 7.79513 10.454 7.89101C10.2401 8 9.96005 8 9.4 8H8M2.6 11H6.4C6.96005 11 7.24008 11 7.45399 10.891C7.64215 10.7951 7.79513 10.6422 7.89101 10.454C8 10.2401 8 9.96005 8 9.4V5.6C8 5.03995 8 4.75992 7.89101 4.54601C7.79513 4.35785 7.64215 4.20487 7.45399 4.10899C7.24008 4 6.96005 4 6.4 4H2.6C2.03995 4 1.75992 4 1.54601 4.10899C1.35785 4.20487 1.20487 4.35785 1.10899 4.54601C1 4.75992 1 5.03995 1 5.6V9.4C1 9.96005 1 10.2401 1.10899 10.454C1.20487 10.6422 1.35785 10.7951 1.54601 10.891C1.75992 11 2.03995 11 2.6 11Z"
                                                    stroke="#353535" strokeWidth="1.33" strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_7529_44094">
                                                    <rect width="12" height="12" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p className="text-xxsmall text-bold">Kopyalandı</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="deposit-section">
                            <p className="text-xsmall deposit-info">Banka:</p>
                            <div className="d-flex">
                                <p className="text-xsmall d-flex deposit-description">ZİRAAT BANKASI</p>
                            </div>
                        </div>
                        <div className="deposit-section">
                            <p className="text-xsmall deposit-info">Şube Kodu:</p>
                            <div className="d-flex">
                                <p className="text-xsmall d-flex deposit-description">2250</p>
                            </div>
                        </div>
                        <div className="deposit-section mt-2">
                            <p className="text-xsmall deposit-info">Hesap No:</p>
                            <div className="d-flex">
                                <p className="text-xsmall d-flex deposit-description">97788404-5001</p>
                                <div style={{position: "relative"}}>
                                    <div style={{position: "absolute"}} onClick={() => {
                                        navigator.clipboard.writeText('97788404-5001').then(() => {
                                            // @ts-ignore
                                            document.getElementById("copy-bank-no").style.display = "flex";
                                            setTimeout(() => {
                                                // @ts-ignore
                                                document.getElementById("copy-bank-no").style.display = "none";
                                            }, 5000);

                                        });
                                    }}>
                                        <div className="deposit-copy">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect x="4.23779" y="2.08345" width="5.67949" height="5.68011" rx="1.25"
                                                      stroke="#353535" strokeWidth="1.5">
                                                </rect>
                                                <path
                                                    d="M7.84632 9.23151V9.23151C7.84632 10.0246 7.20339 10.6675 6.41029 10.6675H3.3335C2.22893 10.6675 1.3335 9.7721 1.3335 8.66753V5.59004C1.3335 4.79702 1.97637 4.15414 2.76939 4.15414V4.15414"
                                                    stroke="#353535" strokeWidth="1.5">
                                                </path>
                                            </svg>
                                            Kopyala
                                        </div>
                                    </div>
                                    <div className="copy-tooltip" style={{left: "72px", display: "none"}}
                                         id={"copy-bank-no"}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_7529_44094)">
                                                <path
                                                    d="M3 7.5L4 8.5L6.25 6.25M4 4V2.6C4 2.03995 4 1.75992 4.10899 1.54601C4.20487 1.35785 4.35785 1.20487 4.54601 1.10899C4.75992 1 5.03995 1 5.6 1H9.4C9.96005 1 10.2401 1 10.454 1.10899C10.6422 1.20487 10.7951 1.35785 10.891 1.54601C11 1.75992 11 2.03995 11 2.6V6.4C11 6.96005 11 7.24008 10.891 7.45399C10.7951 7.64215 10.6422 7.79513 10.454 7.89101C10.2401 8 9.96005 8 9.4 8H8M2.6 11H6.4C6.96005 11 7.24008 11 7.45399 10.891C7.64215 10.7951 7.79513 10.6422 7.89101 10.454C8 10.2401 8 9.96005 8 9.4V5.6C8 5.03995 8 4.75992 7.89101 4.54601C7.79513 4.35785 7.64215 4.20487 7.45399 4.10899C7.24008 4 6.96005 4 6.4 4H2.6C2.03995 4 1.75992 4 1.54601 4.10899C1.35785 4.20487 1.20487 4.35785 1.10899 4.54601C1 4.75992 1 5.03995 1 5.6V9.4C1 9.96005 1 10.2401 1.10899 10.454C1.20487 10.6422 1.35785 10.7951 1.54601 10.891C1.75992 11 2.03995 11 2.6 11Z"
                                                    stroke="#353535" strokeWidth="1.33" strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_7529_44094">
                                                    <rect width="12" height="12" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p className="text-xxsmall text-bold">Kopyalandı</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="deposit-section mt-2">
                            <p className="text-xsmall deposit-info">IBAN:</p>
                            <div className="d-flex">
                                <p className="text-xsmall d-flex deposit-description">TR30 0001 0022 5097 7884 0450
                                    01</p>
                                <div style={{position: "relative"}}>
                                    <div style={{position: "absolute"}} onClick={() => {
                                        navigator.clipboard.writeText('TR30 0001 0022 5097 7884 0450 01').then(() => {
                                            // @ts-ignore
                                            document.getElementById("copy-bank-iban").style.display = "flex";
                                            setTimeout(() => {
                                                // @ts-ignore
                                                document.getElementById("copy-bank-iban").style.display = "none";
                                            }, 5000);

                                        });
                                    }}>
                                        <div className="deposit-copy">
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <rect x="4.23779" y="2.08345" width="5.67949" height="5.68011" rx="1.25"
                                                      stroke="#353535" strokeWidth="1.5">
                                                </rect>
                                                <path
                                                    d="M7.84632 9.23151V9.23151C7.84632 10.0246 7.20339 10.6675 6.41029 10.6675H3.3335C2.22893 10.6675 1.3335 9.7721 1.3335 8.66753V5.59004C1.3335 4.79702 1.97637 4.15414 2.76939 4.15414V4.15414"
                                                    stroke="#353535" strokeWidth="1.5">
                                                </path>
                                            </svg>
                                            Kopyala
                                        </div>
                                    </div>
                                    <div className="copy-tooltip" style={{left: "72px", display: "none"}}
                                         id={"copy-bank-iban"}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_7529_44094)">
                                                <path
                                                    d="M3 7.5L4 8.5L6.25 6.25M4 4V2.6C4 2.03995 4 1.75992 4.10899 1.54601C4.20487 1.35785 4.35785 1.20487 4.54601 1.10899C4.75992 1 5.03995 1 5.6 1H9.4C9.96005 1 10.2401 1 10.454 1.10899C10.6422 1.20487 10.7951 1.35785 10.891 1.54601C11 1.75992 11 2.03995 11 2.6V6.4C11 6.96005 11 7.24008 10.891 7.45399C10.7951 7.64215 10.6422 7.79513 10.454 7.89101C10.2401 8 9.96005 8 9.4 8H8M2.6 11H6.4C6.96005 11 7.24008 11 7.45399 10.891C7.64215 10.7951 7.79513 10.6422 7.89101 10.454C8 10.2401 8 9.96005 8 9.4V5.6C8 5.03995 8 4.75992 7.89101 4.54601C7.79513 4.35785 7.64215 4.20487 7.45399 4.10899C7.24008 4 6.96005 4 6.4 4H2.6C2.03995 4 1.75992 4 1.54601 4.10899C1.35785 4.20487 1.20487 4.35785 1.10899 4.54601C1 4.75992 1 5.03995 1 5.6V9.4C1 9.96005 1 10.2401 1.10899 10.454C1.20487 10.6422 1.35785 10.7951 1.54601 10.891C1.75992 11 2.03995 11 2.6 11Z"
                                                    stroke="#353535" strokeWidth="1.33" strokeLinecap="round"
                                                    strokeLinejoin="round"></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_7529_44094">
                                                    <rect width="12" height="12" fill="white"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <p className="text-xxsmall text-bold">Kopyalandı</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <p className="tex-md text-bold left mb-3 mt-3">Sıkça Sorulan Sorular</p>
                        {
                            depositQuestions.map((question: any, index: any) => {
                                return <div className="accordion">
                                    <div className="accordion-wrapper">
                                        <div className="accordion-question" onClick={() => {

                                            setSelectedQuestionId(question.id);
                                        }}>
                                            <p className="text-xsmall text-bold mb-2">{question.title}</p>
                                            <div>
                                                {selectedQuestionId === question.id ?
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 10L8 6L4 10" stroke="#000" strokeWidth="1.5"
                                                              strokeLinecap="round" strokeLinejoin="round">
                                                        </path>
                                                    </svg> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4 6L8 10L12 6" stroke="#000" strokeWidth="1.5"
                                                              strokeLinecap="round" strokeLinejoin="round">
                                                        </path>
                                                    </svg>}
                                            </div>
                                        </div>



                                        {selectedQuestionId === question.id && <div className="accordion-divider">
                                        </div>}
                                        {selectedQuestionId === question.id &&
                                            <div dangerouslySetInnerHTML={{__html: question.content}}></div>}
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </div>
        </>
    );
}
