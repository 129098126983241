import React, {useEffect, useState} from "react";
import {availableHippodromes, racesActionSubject} from "../../store/bulletin.store";
import {bulletinProvider} from "../../services/bulletin.service";
import {ServerDate, useForceUpdate} from "../../services/misc.functions";
import {tipstersCommentData, tipstersData} from "../../store/misc.store";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import moment from "moment";
import {isMobile} from "../../App";

export function Comments() {
    const [tipstersMap, setTipstersMap] = useState<any>({});
    const [tipsters, setTipsters] = useState<any>(null);
    const [selectedTipster, setSelectedTipster] = useState<any>(null);
    const [selectedTip, setSelectedTip] = useState<any>(null);
    const [hippodrome, setHippodrome] = useState<any>(null);
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
            if (res && res.action === 'update') {
                forceUpdate();
            }
        })
        return () => {
            racesActionSubject_subscription.unsubscribe();
        }
    });

    bulletinProvider.getAvailableHippodromes();

    if (!hippodrome && availableHippodromes.length > 0) {
        setHippodrome(availableHippodromes[0]);
    }


    // console.log('hippodrome', hippodrome)


    let today = ServerDate();
    let date = moment(today).format('DDMMYYYY');

    useEffect(() => {
        if (tipstersCommentData[date]) {
            try {
                var tipsterMap: any = {};
                var _tipsters = [];
                for (const tipstersCommentDatumElement of tipstersCommentData[date]) {
                    if (tipsterMap[tipstersCommentDatumElement.tipster.id]) {
                        tipsterMap[tipstersCommentDatumElement.tipster.id].push(tipstersCommentDatumElement)
                    } else {
                        tipsterMap[tipstersCommentDatumElement.tipster.id] = [];
                        tipsterMap[tipstersCommentDatumElement.tipster.id].push(tipstersCommentDatumElement)
                        _tipsters.push(tipstersCommentDatumElement.tipster);
                    }
                }
                setTipsters(_tipsters);
                setSelectedTipster(_tipsters[0]);
                setTipstersMap(tipsterMap);
                setSelectedTip(tipsterMap[_tipsters[0]?.id][0])
                forceUpdate();
            } catch (e: any) {
            }

        } else {
            let api = new ApiService()
            api.start('get', `${env.api}/misc/api/racing-tip/`, null, false)
                .then((res: any) => {
                    tipstersCommentData[date] = res.data;
                    var tipsterMap: any = {};
                    var _tipsters = [];
                    for (const tipstersCommentDatumElement of tipstersCommentData[date]) {
                        if (tipsterMap[tipstersCommentDatumElement.tipster.id]) {
                            tipsterMap[tipstersCommentDatumElement.tipster.id].push(tipstersCommentDatumElement)
                        } else {
                            tipsterMap[tipstersCommentDatumElement.tipster.id] = [];
                            tipsterMap[tipstersCommentDatumElement.tipster.id].push(tipstersCommentDatumElement)
                            _tipsters.push(tipstersCommentDatumElement.tipster);
                        }
                    }
                    try {
                        setTipsters(_tipsters);
                        setSelectedTipster(_tipsters[0]);
                        setTipstersMap(tipsterMap);
                        setSelectedTip(tipsterMap[_tipsters[0].id][0])
                    } catch (e: any) {
                    }

                    forceUpdate();
                })
        }

    }, [date]);


    if (!hippodrome?.id || selectedTipster === null || tipsters == null || tipsters.length == 0) {
        return <div className="tipster-not-found">
            <p className="text-xsmall">
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                    <path
                        d="M39.9993 53.3333V40M39.9993 26.6666H40.0327M73.3327 40C73.3327 58.4095 58.4088 73.3333 39.9993 73.3333C21.5899 73.3333 6.66602 58.4095 6.66602 40C6.66602 21.5905 21.5899 6.66663 39.9993 6.66663C58.4088 6.66663 73.3327 21.5905 73.3327 40Z"
                        stroke="#929191" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            </p>
            <p className="text-xl text*bolder mb-3 mt-3">Günlük yorum bulunamadı.</p>
        </div>;
    }


    return <>
        {isMobile ?
            <div className="pb-5">
                <h1 className="editord-mobile-header">At Yarışı Uzman Yorumları</h1>
                <div className="editord-mobile-container">
                    {
                        tipsters.map((tipster: any, index: any) => {
                            return <div className="editord-mobile-table-wrapper">
                                <div onClick={() => {
                                    tipster.isOpen = tipster.isOpen ? !tipster.isOpen : true;
                                    if (tipster.isOpen) {
                                        tipster.selectedTip = tipstersMap[tipster.id][0];
                                    }
                                    forceUpdate();
                                }}
                                     className={`editord-mobile-list-header ${tipster.isOpen ? '' : 'editord-mobile-list-header-closed'}`}>
                                    <div className="d-flex align-items-center">
                                        <span>
                                            <img alt="editors-profile" loading="lazy"
                                                 width="30" height="30" decoding="async"
                                                 className="EditorReviews_editorIMG__OYjGA"
                                                 src={tipstersData[tipster.id]?.avatar}
                                            />
                                        </span>
                                        <span
                                            className={`text-small text-bold ms-2 ${tipster.isOpen ? 'text-white' : ''}`}> {tipstersData[tipster.id]?.nick_name ?? `${tipstersData[tipster.id]?.first_name} ${tipstersData[tipster.id]?.last_name} `}</span>
                                        <span
                                            className={`comment-count ${tipster.isOpen ? 'selected' : ''}`}>{tipstersMap[tipster.id].map((el: any) => el.run_tips.length).reduce((acc: any, val: any) => acc + val, 0)}</span>
                                    </div>
                                    <div></div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             viewBox="0 0 16 16"
                                             fill="none" className={`${tipster.isOpen ? 'openedIcon' : ''}`}>
                                            <path d="M4 6L8 10L12 6" stroke={tipster.isOpen ? '#FFFFFF' : "#004742"}
                                                  strokeWidth="3"
                                                  strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                    </div>
                                </div>
                                {tipster.isOpen && <div className="editor-table-tab-wrapper">
                                    {tipstersMap[tipster.id].map((hip: any, index: any) => {
                                        return <div
                                            onClick={() => {
                                                // setSelectedTip(hip);
                                                tipster.selectedTip = hip;
                                                forceUpdate()
                                            }}
                                            className={`editor-hipodrome-item ${tipster.selectedTip?.id === hip.id ? 'selected' : ''} `}>
                                            <p className={`text-small ${tipster.selectedTip?.id !== hip.id ? 'text-white text-bold' : 'text-bolder'} `}>{hip.hippodrome}</p>
                                        </div>
                                    })}
                                </div>}
                                {tipster.isOpen && <div className="editor-comment-wrapper">
                                    {tipster.selectedTip?.run_tips.map((tip: any, index: any) => {
                                        return <div className="editor-comment">
                                            <p className="text-xsmall text-bold pb-1">{tip.run}. Koşu</p>
                                            <p className="text-xsmall" dangerouslySetInnerHTML={{__html: tip.text}}></p>
                                        </div>
                                    })}
                                </div>}
                            </div>
                        })
                    }


                </div>
            </div> :
            <div className="container">
                <div className="comments-wrapper">
                    <div>
                        <div className="comments-title">
                            <h1 className="text-small  text-bold">At Yarışı
                                Uzman Yorumları</h1>
                        </div>
                        <div className="ps-2">
                            {
                                tipsters.map((tipster: any, index: any) => {
                                    return <div onClick={() => {
                                        setSelectedTipster(tipster);
                                        setSelectedTip(tipstersMap[tipster.id][0]);
                                    }}
                                                className={`editor-card ${selectedTipster.id === tipster.id ? 'selected' : ' '}`}>
                                        <div>
                                <span>
                                    <img alt="editors-profile" loading="lazy" width="30" height="30" decoding="async"
                                         className="editor-img" src={tipstersData[tipster.id]?.avatar}/>
                                </span>
                                        </div>
                                        <div>
                                            <p className={`text-small ${selectedTipster.id === tipster.id ? 'text-bold' : ' '}`}>
                                                {tipstersData[tipster.id]?.nick_name ?? `${tipstersData[tipster.id]?.first_name} ${tipstersData[tipster.id]?.last_name} `}
                                            </p>
                                        </div>
                                        <div>
                                        <span
                                            className="comment-count">{tipstersMap[tipster.id].map((el: any) => el.run_tips.length).reduce((acc: any, val: any) => acc + val, 0)}</span>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                    {selectedTipster && <div className="comment-wrapper">
                        <div className="editor-info">
                        <span>
                            <img alt="editors-profile" loading="lazy"
                                 width="60" height="60" decoding="async"
                                 data-nimg="1"
                                 className="editor-img"
                                 src={tipstersData[selectedTipster?.id]?.avatar}
                            />
                        </span>
                            <p className="text-lg text-primary text-bold">{tipstersData[selectedTipster.id]?.nick_name ?? `${tipstersData[selectedTipster.id]?.first_name} ${tipstersData[selectedTipster.id]?.last_name} `}</p>
                        </div>
                        <div className="editor-table-wrapper">
                            <div className="editor-table-header"></div>
                            <div className="editor-table-tab-wrapper">
                                {tipstersMap[selectedTipster.id].map((hip: any, index: any) => {
                                    return <div
                                        onClick={() => {
                                            setSelectedTip(hip);
                                        }}
                                        className={`editor-hipodrome-item ${selectedTip.id === hip.id ? 'selected' : ''} `}>
                                        <p className={`text-small ${selectedTip.id !== hip.id ? 'text-white text-bold' : 'text-bolder'} `}>{hip.hippodrome}</p>
                                    </div>
                                })}

                            </div>
                            <div className="editor-comment-wrapper">
                                {selectedTip.run_tips.map((tip: any, index: any) => {
                                    return <div className="editor-comment">
                                        <p className="text-xsmall text-bold pb-1">{tip.run}. Koşu</p>
                                        <p className="text-xsmall" dangerouslySetInnerHTML={{__html: tip.text}}></p>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="editors-bg"></div>
            </div>
        }</>

}
