import "./campaign.css";
import React, {useEffect, useState} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import _, {constant} from "lodash";
import {Link} from "react-router-dom";
import paths from "../../paths";
import {modalServiceSubject} from "../../services/modal.service";
import {useNavigate} from "react-router";

const tabs = [
    {value: "guncel-kampanyalar", label: "Güncel Kampanyalar"},
    {value: "gecmis-kampanyalar", label: "Geçmiş Kampanyalar"},
];

export default function Campaign() {
    const api = new ApiService();
    const [dataList, setDataList] = useState<any[]>([]);
    const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
    const [validCampaigns, setValidCampaigns] = useState<any>([]);
    const [notValidCampaigns, setNotValidCampaigns] = useState<any>([]);
    const navigate = useNavigate();


    const getCampaignList = () => {

        api.start("get", env.api + "/misc/api/campaign/list/", null, true).then((res: any) => {
            if (res && res.status) {
                setDataList(_.orderBy(res.data, ["is_valid", "create_date"], ["desc"]));
                // for (const data of res.data) {
                //         valids.push(data);
                //
                // }
            }
        }).then(() => {

        });
    }


    useEffect(() => {
        getCampaignList();

    }, []);

    const handleSelect = (e: any) => {
        if (e.target.id) {
            setSelectedTab(tabs[e.target.id]);
        }
    };

    const subscribeCampaign = (campaign: any) => {
        api.start("post", env.api + "/misc/api/member-campaign/subscribe/", {"campaign_id": campaign.id}, true).then((res: any) => {
            if (res && res.status) {
                getCampaignList();
                modalServiceSubject.next({
                    case: "success",
                    title: "Başarılı",
                    width: 380,
                    content: `Kampanya'ya katılımızın gerçekleşmiştir.`,
                    confirm: {
                        cancel: {
                            label: "Tamam",
                            class: "btn-success",
                        },

                    },
                });

            } else {
                modalServiceSubject.next({
                    case: "danger",
                    title: "Hata",
                    width: 380,
                    content: `${res.message}`,
                    confirm: {
                        cancel: {
                            label: "Tamam",
                            class: "btn-danger",
                        },

                    },
                });
            }
        }).then(() => {
        });
    }

    return (
        <div>
            <div className="campaignsTypeWrapper">
                <div className="container campaignsTypeContainer">
                    <h1 className="text-xl text-white text-bolder" style={{fontSize: "20px"}}>KAMPANYALAR</h1>
                </div>
            </div>
            <div className="container">

                <div className="p-3">
                    <div className="row">
                        {dataList.map((el: any, key: number) => {

                            return (
                                <div

                                    className="col-12 col-lg-6 campaign-item text-decoration-none"
                                    key={`campaign-${el.id}`}
                                >
                                    <div className="campaign-widget">

                                        {!el.is_valid &&
                                            <div className="ribbon ribbon-old">
                                                <span>Geçmiş</span>
                                            </div>
                                        }

                                        <img src={env.media + el.photo} alt=""/>

                                        <div className="body">
                                            <span className="title">{el.name}</span>
                                            <span className="sub-title">{el.title_abbreviated}</span>
                                        </div>

                                        <div className={"row"}>
                                            <div
                                                className={`col-12 ${el.participation_required ? 'col-lg-6' : 'col-lg-12'} `}>
                                                <button onClick={() => {
                                                    navigate(`${paths.campaigns.url}/${el.id}`)
                                                }} className="button xs primary filled text-bold w-100">Kampanya Detayları
                                                </button>
                                            </div>
                                            {el.participation_required &&
                                                <div className={"col-12 col-lg-6 mt-2 mt-lg-0"}>
                                                    <button className="button xs secondary filled text-bold text-xxsmall w-100" onClick={() => {
                                                        if (!el.member_subscribed != null) {
                                                            subscribeCampaign(el)
                                                        }
                                                    }}>
                                                        {el.member_subscribed != null ? "Kampanya'ya Katıldınız" : "Kampanya'ya Katıl"}
                                                    </button>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            );

                        })}

                        {dataList.length === 0 && <>
                            <p className="text-center p-4">
                                Şu anda aktif bir kampanya bulunmamaktadır.
                            </p>

                        </>}
                    </div>
                </div>


            </div>
        </div>
    );
}
