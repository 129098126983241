import React, {useEffect} from "react";
import {ApiService} from "../../services/http.service";
import {env} from "../../constants/global.vars";
import {useNavigate, useParams} from "react-router";
import paths from "../../paths";
import './support.css'
import {Link} from "react-router-dom";


export function SupportFAQ() {

    const [subjects, setSubjects] = React.useState<any[]>([])
    const [issues, setIssues] = React.useState<any[]>([])
    const [hint, setHint] = React.useState<any>(null);
    const navigate = useNavigate();
    const params = useParams();
    const pathname = useNavigate();

    useEffect(() => {
        let api = new ApiService();
        api.start('get', env.api + '/misc/api/list-questions/', null, false)
            .then((res: any) => {
                if (params.id) {
                    getIssues(Number(params.id)).finally(() => {
                        setSubjects(res.data);
                    })
                } else {
                    setSubjects(res.data);
                }

            })
    }, [])

    useEffect(() => {
        if (!params.id) {
            setIssues([]);
        }
    }, [pathname]);

    const getIssues = async (e: any) => {
        if (Number(e) === -1) {
            setIssues([]);
            setHint(null);
            return null;
        }
        let api = new ApiService();
        await api.start('post', env.api + '/misc/api/list-questions/', {'question_type': e}, false)
            .then((res: any) => {
                setIssues(res.data);
                navigate(`${paths.supportFAQ.url}/${e}/`);
            })
    }

    return (<div>
        <p className="text-md text-bold">Sıkça Sorulan Sorular</p>
        <div style={{height: "16px"}}></div>

        {subjects.map((item: any, key: number) => {
            return (
                <div className="sss-item" key={`subjects-faq-${key}`} id={`subjects-faq-${item.question_type}`}>
                    <a onClick={() => {
                        getIssues(item.question_type)
                    }}>
                        <div className="d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 fill="none"
                                 className="me-2">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M10 0.833252C4.93743 0.833252 0.833374 4.93731 0.833374 9.99992C0.833374 15.0625 4.93743 19.1666 10 19.1666C15.0626 19.1666 19.1667 15.0625 19.1667 9.99992C19.1667 4.93731 15.0626 0.833252 10 0.833252ZM9.0889 6.89261C9.42823 6.69318 9.8272 6.62028 10.2151 6.68682C10.6031 6.75336 10.9549 6.95505 11.2084 7.25616C11.4619 7.55727 11.6006 7.93838 11.6 8.33198L11.6 8.33322C11.6 8.72416 11.2958 9.13173 10.7211 9.51484C10.4591 9.68949 10.1912 9.82404 9.98555 9.91546C9.88388 9.96064 9.80032 9.99401 9.74413 10.0154C9.71919 10.0249 9.69415 10.0341 9.66897 10.0429C9.23291 10.1888 8.99736 10.6604 9.1428 11.0967C9.28834 11.5334 9.76027 11.7693 10.1969 11.6238L10.3375 11.5729C10.418 11.5422 10.5297 11.4975 10.6624 11.4385C10.9255 11.3216 11.2826 11.1436 11.6456 10.9016C12.3208 10.4515 13.2663 9.60927 13.2667 8.33398C13.2678 7.54695 12.9903 6.78493 12.4835 6.18283C11.9765 5.5806 11.2728 5.17722 10.4969 5.04414C9.72103 4.91106 8.9231 5.05686 8.24443 5.45572C7.56576 5.85458 7.05015 6.48076 6.78892 7.22335C6.6362 7.65751 6.86434 8.13327 7.2985 8.286C7.73266 8.43872 8.20842 8.21058 8.36115 7.77642C8.49176 7.40513 8.74957 7.09204 9.0889 6.89261ZM10 13.3332C9.5398 13.3332 9.1667 13.7063 9.1667 14.1666C9.1667 14.6268 9.5398 14.9999 10 14.9999H10.0084C10.4686 14.9999 10.8417 14.6268 10.8417 14.1666C10.8417 13.7063 10.4686 13.3332 10.0084 13.3332H10Z"
                                      fill="#004742"></path>
                            </svg>
                            <p className="text-small fw-medium">{item.question_type_display}</p>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12" fill="none">
                            <path d="M1 11L5 6L1 1" stroke="#353535" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"></path>
                        </svg>
                    </a>
                </div>
            )
        })}


    </div>)
}
